import { Component } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Confirmation_Dialog_Compnent } from "../.././../../../material_ui/components/UI_Dialogs/Confirmation_Dialog/Confirmation_Dialog";

import { affiliate_hold_time } from '../../../objects/Affiliate/affiliate_hold_time';
import { pagination } from "../../../../../system/objects/pagination";

import { Affiliate_Service } from '../../../services/Affiliate/Affiliate';

@Component({
    selector: 'affiliate_hold_time',
    templateUrl: './hold_time.html'
})
export class Component_Affiliate_Hold_Time {

    hold_time_form: UntypedFormGroup;

    search_text: string = "";
    affiliate_hold_time_list: affiliate_hold_time[];
    pagination_hold_time: pagination = new pagination();
    displayedColumns: string[] = ['name', 'days', 'hours', 'minutes', 'seconds', 'action'];

    // Flags
    updateButton: boolean = false;

    constructor(private affiliate_service: Affiliate_Service, private snackBar: MatSnackBar, private dialog: MatDialog) { }

    ngOnInit() {
        this.getBackend_Data_Affiliate_Hold_Time(null, null, false);
        this.setHoldTimeForm(new affiliate_hold_time);
    }

    onClick_Affiliate_Hold_Time_Pagination(e: any) {
        this.getBackend_Data_Affiliate_Hold_Time(e.pageIndex + 1, e.pageSize);
    }
    getBackend_Data_Affiliate_Hold_Time(page?: number, pageSize?: number, all_result?: boolean) {
        this.affiliate_service.getAffiliate_Hold_Time(page, pageSize, all_result).subscribe((res) => {
            this.affiliate_hold_time_list = res.body["affiliate_hold_time"];
            this.pagination_hold_time = res.body['pagination'];
        });
    }
    onAffiliate_Hold_Time_Search(value: string) {
        this.search_text = value;
        this.affiliate_service.getAffiliate_Hold_Time_Search(null, this.search_text).subscribe((res) => {
            this.affiliate_hold_time_list = res.body["affiliate_hold_time"];
            this.pagination_hold_time = res.body['pagination'];
        });
    }
    setHoldTimeForm(holdTimeModel: affiliate_hold_time) {
        this.hold_time_form = new UntypedFormGroup({
            id: new UntypedFormControl(holdTimeModel.id == null ? 0 : holdTimeModel.id),
            name: new UntypedFormControl(holdTimeModel.name, [Validators.required]),
            days: new UntypedFormControl(holdTimeModel.days),
            hours: new UntypedFormControl(holdTimeModel.hours),
            minutes: new UntypedFormControl(holdTimeModel.minutes),
            seconds: new UntypedFormControl(holdTimeModel.seconds)
        });
    }
    onEdit_Affiliate_Hold_Time(item: affiliate_hold_time) {
        this.setHoldTimeForm(item);
        this.updateButton = true;
    }
    onAdd_Update_Affiliate_Hold_Time_Submit(formDirective: FormGroupDirective) {
        // Check Form Is Valid
        if (this.hold_time_form.valid) {
            this.affiliate_service.addUpdate_Affiliate_Hold_Time(this.hold_time_form.getRawValue()).subscribe(
                (response) => {
                    if (this.updateButton) {
                        this.openSnackbarNotification('You have successfully updated the price override', 'Close', 'success');
                    }
                    else {
                        this.openSnackbarNotification('You have successfully added the price override', 'Close', 'success');
                    }
                },
                (error) => { this.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
                () => { this.rest_Affiliate_Hold_Time_Form(formDirective) }
            );
        }
        else {
            const controls = this.hold_time_form.controls;
            Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
        }
    }
    onDelete_Affiliate_Hold_Time(item: affiliate_hold_time) {
        const dialogRef = this.dialog.open(Confirmation_Dialog_Compnent, {
            data: { title: "Delete !", text: "Are you sure you want to delete " + item.name + "?" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
                // call delete service
                this.affiliate_service.delete_Affiliate_Hold_Time(item).subscribe(
                    (response) => { this.openSnackbarNotification(item.name + ' has been deleted successfully', 'Close', 'success'); },
                    (error) => { this.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
                    () => { this.rest_Affiliate_Hold_Time_Form(null) }
                );
            }
        });
    }
    openSnackbarNotification(message: string, action: string, status: string) {
        if (status == "success") {
            message = "✔️ - " + message;
        }
        else if (status == "error") {
            message = "❌  - " + message;
        }
        this.snackBar.open(message, action, {
            duration: 4000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }
    rest_Affiliate_Hold_Time_Form(formDirective: FormGroupDirective) {
        // Rest Form
        if(formDirective != null){
            formDirective.resetForm();
        }
        this.hold_time_form.reset();
        this.updateButton = false;
        this.search_text = "";
        // Refresh Table
        this.getBackend_Data_Affiliate_Hold_Time(null, null, null);
    }
}
