import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mat-sidemenu',
  templateUrl: './Side_Menu.html',
  styleUrls: ['./Side_Menu.scss']
})
export class Side_Menu_Component implements OnInit {

  @Input() iconOnly: boolean = false;
  @Input() menus: any;
  @Input() hideOnClick: boolean = false;

  constructor() { }

  ngOnInit() { }

}
