import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { Module_System } from '../../system/system.module';
import { HomeWidgetModule } from './components/home/home-widget/home-widget.module';

import { Component_Home } from './components/home/home';
import { Component_Web_Engine } from './components/web_search/web_engine/web_engine';
import { Component_Web_Search_Filter } from './components/web_search/web_search_filter/web_search_filter';
import { Component_Web_Search_Sales_Group } from './components/web_search/web_search_sales_group/web_search_sales_group';
import { Component_Affiliate } from './components/affiliate/affiliate/affiliate';
import { Component_Affiliate_Hold_Time } from './components/affiliate/hold_time/hold_time';
import { Component_Audit_Booking } from './components/audit/audit_booking';
import { Component_Price_Override } from './components/channel_management/price_override/price_override';
import { Component_Product_List } from './components/channel_management/product/product_list/product_list';
import { Component_Product_Details } from './components/channel_management/product/product_details/product_details';
import { Component_Product_Selection } from './components/comman/product_selection/product_selection';
import { Component_Mapping } from './components/channel_management/mapping/mapping';
import { Component_Channel_Management_List } from './components/channel_management/list/channel_management_list';
import { Select_Product_Dialog_Compnent } from './components/comman/ui_dialogs/select_product/select_product';

import { Web_Engine_Service } from './services/Web_Engine/Web_Engine';
import { Web_Filter_Service } from './services/Web_Search_Filter/Web_Filter';
import { Web_Search_Sales_Group_Service } from './services/Web_Search_Sales_Group/Web_Search_Sales_Group';
import { Affiliate_Service } from './services/Affiliate/Affiliate';
import { Audit_Booking_Service } from './services/Audit/Audit_Booking';
import { Price_Override_Service } from './services/Channel_Management/Price_Override/Price_Override';
import { Product_Selection_Service } from './services/Comman/Product_Selection/Product_Selection';
import { Cache_Server_Service } from './services/Type_Cache_Server/Cache_Server';
import { Mapping_Service } from './services/Channel_Management/Mapping/Mapping';
import { List_Management_Service } from './services/Channel_Management/List_Management/List';

import { Time_Difference_Pipe } from './pipes/time_difference';



@NgModule({
    imports: [CommonModule, FormsModule, Module_System, HomeWidgetModule],
    declarations: [Component_Home, Component_Web_Engine, Component_Web_Search_Filter, Component_Web_Search_Sales_Group, Component_Affiliate,
        Component_Affiliate_Hold_Time, Component_Audit_Booking, Component_Price_Override, Component_Product_Selection, Time_Difference_Pipe,
        Component_Product_List, Component_Product_Details, Component_Mapping, Component_Channel_Management_List, Select_Product_Dialog_Compnent],
    exports: [Component_Home, Component_Web_Engine, Component_Web_Search_Filter, Component_Web_Search_Sales_Group, Component_Affiliate,
        Component_Affiliate_Hold_Time, Component_Audit_Booking, Component_Price_Override, Component_Product_Selection,
        Component_Product_List, Component_Product_Details, Component_Mapping, Component_Channel_Management_List, Select_Product_Dialog_Compnent],
    providers: [
        Web_Engine_Service,
        Web_Filter_Service,
        Web_Search_Sales_Group_Service,
        Affiliate_Service,
        Audit_Booking_Service,
        Price_Override_Service,
        Product_Selection_Service,
        Cache_Server_Service,
        Mapping_Service,
        List_Management_Service
    ]
})
export class Module_TMS_Gateway { }
