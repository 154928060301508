import { list_mode } from '../../Type/channel_management_list_mode';
import { list_product } from './list_product';



export class list {

    public id: string;
    public name: string;
    public mode_id: number;
    public mode: list_mode;
    public products: list_product[] = [];
}