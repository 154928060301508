import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Confirmation_Dialog_Compnent } from "../.././../../../material_ui/components/UI_Dialogs/Confirmation_Dialog/Confirmation_Dialog";


import { price_override } from '../../../objects/Channel_Management/Price_Override/price_override';
import { price_override_cache_server } from '../../../objects/Channel_Management/Price_Override/price_override_cache_server';
import { price_override_product } from '../../../objects/Channel_Management/Price_Override/price_override_product';
import { price_override_product_departure } from '../../../objects/Channel_Management/Price_Override/price_override_product_departure';
import { price_override_currency } from '../../../objects/Channel_Management/Price_Override/price_override_currency';

import { pagination } from "../../../../../system/objects/pagination";

import { Price_Override_Service } from '../../../services/Channel_Management/Price_Override/Price_Override';
import { Affiliate_Service } from '../../../services/Affiliate/Affiliate';
import { Web_Search_Sales_Group_Service } from '../../../services/Web_Search_Sales_Group/Web_Search_Sales_Group';
import { Helper_Service } from '../../../../../material_ui/services/Core/Helper_Service';

@Component({
    selector: 'price_override',
    templateUrl: './price_override.html'
})
export class Component_Price_Override implements OnInit {

    displayedColumns: string[] = ['name', 'edit'];
    detailFormGroup: UntypedFormGroup;
    price_override_header: string = "";
    search_text: string = "";
    price_override_list: price_override[];
    pagination_price_override: pagination = new pagination();

    // Cache Server Screen Variables
    availableCacheServerItems: any[] = [];
    selectedCacheServerItems: any[] = [];
    pagination_cache_servers: pagination = new pagination();
    price_override_cache_server: price_override_cache_server[] = [];
    // Products Screen Variables
    selectedProductItems: any[] = [];
    price_override_products: price_override_product[] = [];
    // Product Departure Screen Variables
    departureDate: Date;
    product: price_override_product;
    ds_departures = new MatTableDataSource();
    // Product Currencies Screen Variables
    currencies: any[] = [{ iso: "AUD", currency: "Australian Dollars" },
    { iso: "CAD", currency: "Canadian Dollars" },
    { iso: "EUR", currency: "Euro" },
    { iso: "GBP", currency: "Pounds Sterling" },
    { iso: "NZD", currency: "NZ Dollars" },
    { iso: "THA", currency: "Thai Baht" },
    { iso: "USD", currency: "US Dollars" },
    { iso: "ZAR", currency: "South African Rand" }];
    isoCurrencyCode: string;
    ds_currencies = new MatTableDataSource();
    // Product Currency Amount Screen Variable
    currencyAmount: number;
    product_currency: price_override_currency
    ds_amounts = new MatTableDataSource();

    //Http Post/Get Value
    price_override: price_override = new price_override;

    // Flags
    updateButton: boolean = false;
    showSearchBlock: boolean = true;
    matNextStep: boolean = true;
    matBackStep: boolean = false;
    cacheStepCompleted: boolean = true;
    productStepCompleted: boolean = false;
    @ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;

    constructor(private Price_Override_Service: Price_Override_Service, private Affiliate_Service: Affiliate_Service,
        private helper_service: Helper_Service, private Web_Search_Sales_Group_Service: Web_Search_Sales_Group_Service, private FormBuilder: UntypedFormBuilder, private dialog: MatDialog) { }

    ngOnInit() {
        this.detailFormGroup = this.FormBuilder.group({
            id: [],
            name: ['', Validators.required],
            percentage: ['', Validators.required]

        });
        // call function to get data from backend
        this.getBackend_Data_Price_Override(null, null);
        this.getBackend_Data_Cache_Servers_Available(null, null);
    }

    //#region Price Override Main
    onClick_Price_Override_Pagination(e: any) {
        this.getBackend_Data_Price_Override(e.pageIndex + 1, e.pageSize);
    }
    getBackend_Data_Price_Override(page: number, pageSize: number) {
        this.Price_Override_Service.getPrice_Override_List(page, pageSize).subscribe((res) => {
            this.price_override_list = res.body["price_override"];
            this.pagination_price_override = res.body["pagination"];
        });
    }
    onPrice_Override_Search(value: string) {
        this.search_text = value;
        this.Price_Override_Service.getWPrice_Override_Search(null, this.search_text).subscribe((res) => {
            this.price_override_list = res.body["price_override"];
            this.pagination_price_override = res.body["pagination"];
        });
    }
    onAdd_Price_Override() {
        // Bind Variables 
        this.price_override_header = "Enter Details To Add a New Price Override:"
        this.showSearchBlock = false;
    }
    onEdit_Price_Override(selected_price_override: price_override) {
        // Bind Variables 
        this.price_override_header = "Edit Details: " + selected_price_override.name;

        // Details
        this.detailFormGroup.patchValue({
            id: selected_price_override.id,
            name: selected_price_override.name,
            percentage: selected_price_override.percentage
        });

        this.price_override = selected_price_override;

        // Update Flag
        this.updateButton = true;
        this.showSearchBlock = false;
    }
    onDelete_Price_Override(selected_price_override: price_override) {
        const dialogRef = this.dialog.open(Confirmation_Dialog_Compnent, {
            data: { title: "Delete !", text: "Are you sure you want to delete " + selected_price_override.name + "?" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
                // call delete service
                this.Price_Override_Service.deletePriceOverride(selected_price_override).subscribe(
                    (response) => { this.helper_service.openSnackbarNotification(selected_price_override.name + ' has been deleted successfully', 'Close', 'success'); },
                    (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
                    () => { this.rest_PriceOverrideForm() }
                );
            }
        });
    }
    //#endregion

    //#region Price Override Cache Server
    onClick_Cache_Servers_Pagination(e: any) {
        this.getBackend_Data_Cache_Servers_Available(e.pageIndex + 1, e.pageSize);
    }
    onClick_Cache_Servers_Search(value: string) {
        this.Affiliate_Service.getAffiliate(null, value).subscribe((res) => {
            this.pagination_cache_servers = res.body["pagination"];
            this.availableCacheServerItems = [];
            (res.body["affiliate"]).forEach(obj => {
                this.availableCacheServerItems.push({ id: obj.app_id, name: obj.name, selected: false });
            });
        });
    }
    getBackend_Data_Cache_Servers_Available(page: number, pageSize: number) {
        this.Affiliate_Service.getAffiliate_List(page, pageSize).subscribe((res) => {
            this.pagination_cache_servers = res.body["pagination"];
            this.availableCacheServerItems = [];
            (res.body["affiliate"]).forEach(obj => {
                this.availableCacheServerItems.push({ id: obj.app_id, name: obj.name, selected: false });
            });
        });
    }
    map_Data_Cache_Servers_Selected() {
        this.selectedCacheServerItems = [];
        this.price_override.cache_servers.forEach(obj => {
            this.selectedCacheServerItems.push({ id: obj.cache_server_id, name: obj.cache_server.name, selected: true })
        });
    }
    onAddCacheServer(item: any) {
        var cache_server = { name: item.name };
        this.price_override.cache_servers.push(Object.assign({ price_override_id: this.price_override.id == undefined ? null : this.price_override.id, cache_server_id: item.id, cache_server: cache_server }));
    }
    onRemoveCacheServer(item: any) {
        this.price_override.cache_servers = this.price_override.cache_servers.filter(x => x.cache_server_id != item.id);
    }
    //#endregion

    //#region Price Override Products

    map_Data_Products_Selected() {
        this.selectedProductItems = [];
        this.price_override.products.forEach(obj => {
            this.selectedProductItems.push({ id: obj.product.external_identifier, name: obj.product.company_finance_centre_product_group_product, selected: true, product_id: obj.product_id })
        });
    }
    onAddProduct(item: any) {
        var product = { name: item.company_finance_centre_product_group_product };
        this.price_override.products.push(Object.assign({ product_id: item.id, departures: [], currencies: [], product: product }));
    }
    onRemoveProduct(item: any) {
        this.price_override.products = this.price_override.products.filter(x => x.product_id != item.product_id);
    }
    //#endregion

    //#region Price Override Products Departures
    onProductSelectForDepartures(item: any) {
        this.product = this.price_override.products.find(x => x.product_id == item.product_id)
        // Init data sources for Departue and Curency Screen
        this.ds_departures = new MatTableDataSource(this.product.departures)
        this.ds_currencies = new MatTableDataSource(this.product.currencies)
        // Mark Step Completed
        if (this.stepper.selectedIndex == 2) {
            this.stepper.selected.completed = true;
            this.stepper.next();
        }

    }
    onAdd_Product_Departure() {
        if (this.product.departures.find(x => x.departure == this.departureDate.toLocaleString()) == null && this.departureDate != undefined) {
            this.product.departures.push({ id: null, price_override_product_id: null, departure: this.departureDate.toDateString(), price_override_product: null });
            this.ds_departures = new MatTableDataSource(this.product.departures);
        }
    }
    onDelete_Product_Departure(item: any) {
        this.product.departures = this.product.departures.filter(x => x.id != item.id);
        this.ds_departures = new MatTableDataSource(this.product.departures);
    }
    //#endregion

    //#region Price Override Currencies and Amounts
    onAdd_Product_Currency() {
        if (this.product.currencies.find(x => x.iso_currency_code == this.isoCurrencyCode) == null && this.isoCurrencyCode != undefined) {
            this.product.currencies.push(Object.assign({ id: null, price_override_product_id: null, iso_currency_code: this.isoCurrencyCode, amounts: [] }));
            this.ds_currencies = new MatTableDataSource(this.product.currencies);
        }
        // Hide Amount Screen
        this.product_currency = null;
    }

    onDelete_Product_Currency(item: any) {
        this.product.currencies = this.product.currencies.filter(x => x.iso_currency_code != item.iso_currency_code);
        this.ds_currencies = new MatTableDataSource(this.product.currencies);
    }

    onAdd_Product_Currency_Amount(item: any) {
        this.product_currency = item;
        this.ds_amounts = new MatTableDataSource(this.product_currency.amounts);
    }

    onAdd_Currency_Amount() {
        this.product_currency.amounts.push(Object.assign({ amount: this.currencyAmount }));
        this.ds_amounts = new MatTableDataSource(this.product_currency.amounts);
    }
    onDelete_Currency_Amount(item: any) {
        this.product_currency.amounts = this.product_currency.amounts.filter(x => x.amount != item.amount);
        this.ds_amounts = new MatTableDataSource(this.product_currency.amounts);
    }
    //#endregion

    //#region Screen Actions
    onAdd_Update_Price_Override_Submit() {
        this.price_override.id = this.detailFormGroup.controls["id"].value;
        this.price_override.name = this.detailFormGroup.controls["name"].value;
        this.price_override.percentage = this.detailFormGroup.controls["percentage"].value;

        this.Price_Override_Service.addPriceOverride(this.price_override).subscribe(
            (response) => {
                if (this.updateButton) {
                    this.helper_service.openSnackbarNotification('You have successfully updated the price override', 'Close', 'success');
                }
                else {
                    this.helper_service.openSnackbarNotification('You have successfully added the price override', 'Close', 'success');
                }
            },
            (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
            () => { this.rest_PriceOverrideForm() }
        );
    }
    rest_PriceOverrideForm() {
        this.showSearchBlock = true;
        this.updateButton = false;
        this.price_override_header = "";
        this.search_text = "";
        this.matNextStep = true;
        this.matBackStep = false;
        this.price_override = null
        //Reset Detail
        this.detailFormGroup.reset();
        // Reset Cache Server vars
        this.availableCacheServerItems = [];
        this.selectedCacheServerItems = [];
        // Reset Products Screen vars
        //this.availableProductItems = [];
        this.selectedProductItems = [];
        // Reset Departure var
        this.departureDate = null;
        this.product = null;
        this.ds_departures = new MatTableDataSource();
        // Reset Currencies var
        this.isoCurrencyCode = "";
        this.ds_currencies = new MatTableDataSource();
        // Reset Amounts var
        this.currencyAmount = null;
        this.product_currency = null;
        this.ds_amounts = new MatTableDataSource();
        // Refresh API Call data
        this.getBackend_Data_Price_Override(null, null);
        this.getBackend_Data_Cache_Servers_Available(null, null);
        //this.getBackend_Data_Products_Available(null, null, "");
    }
    stepClick(ev: MatHorizontalStepper) {
        // Detail Step
        if (ev.selectedIndex == 0) {
            this.matNextStep = true;
            this.matBackStep = false;
        }
        // Cache Server Step
        if (ev.selectedIndex == 1) {
            this.map_Data_Cache_Servers_Selected();
            this.matNextStep = true;
            this.matBackStep = true;
        }
        // Product Step
        if (ev.selectedIndex == 2) {
            this.map_Data_Products_Selected();
            this.matNextStep = false;
            this.matBackStep = true;
            // Mark Steps Un Completed if retrun back
            if (this.stepper.selectedIndex == 3 || this.stepper.selectedIndex == 4) {
                this.stepper._steps["_results"][2].completed = false;
            }
        }
        // Departure Step
        if (ev.selectedIndex == 3) {
            this.matNextStep = true;
            this.matBackStep = true;
        }
        // Currencies Step
        if (ev.selectedIndex == 4) {
            this.matNextStep = true;
            this.matBackStep = true;
        }
    }
    onStepNext() {
        this.stepper.next();
    }
    onStepPrevious() {
        this.stepper.previous();
    }
    //#endregion










}
