<div mat-dialog-title>
        <div class="clearfix">
            <div style="float:left">Select Product</div>
            <div style="float:right">
                <button mat-mini-fab (click)="onClose()">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
        </div>
    </div>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px" style="margin-bottom: 25px;">
        <mat-form-field fxFlex>
            <mat-select placeholder="Connector" [(ngModel)]="selected_connector" (selectionChange)="onConnectorFilter($event.value)">
                <mat-option *ngFor="let connector of conector_type_list" [value]="connector.id">{{connector.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field fxFlex>
            <mat-select placeholder="Company" [(ngModel)]="selected_company" (selectionChange)="onCompanyFilter()">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let company of companies" [value]="company">{{company}}</mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field fxFlex>
            <mat-select placeholder="Finance Centres" (selectionChange)="onFinanceCentreFilter()" [(ngModel)]="selected_finance_centre">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let finance_centre of finance_centres" [value]="finance_centre">{{finance_centre}}</mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field fxFlex>
            <mat-select placeholder="Product Group" (selectionChange)="onProductGroupFilter()" [(ngModel)]="selected_product_group">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let group of product_groups" [value]="group">{{group}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <!-- Search Record -->
    <div class="mat-elevation-z8">
        <!-- Filter Database results -->
        <div fxLayout="row">
            <div>
                <mat-input-debounce placeholder="Product Search..." (value)="onProduct_Search($event)" style="padding:20px">
                </mat-input-debounce>
            </div>
        </div>
    
        <!-- Result table -->
        <table class="amd-table mat-elevation-z8" mat-table [dataSource]="pagedProductItems">
            <!-- Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Product Name </th>
                <td mat-cell *matCellDef="let element"> {{element.company_finance_centre_product_group_product}} </td>
            </ng-container>
            <!-- Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutGap="10px">
                        <!--  <button color="accent" mat-mini-fab (click)="onEdit_Product(element)">
                            <mat-icon matTooltip="Edit">edit</mat-icon>
                        </button> -->
                        <mat-radio-group>
                            <mat-radio-button (change)="onSingleSelect($event)" [value]="element"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </td>
            </ng-container>
            <!-- Table Header -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!-- Pagination -->
        <mat-paginator [pageIndex]="pagination_product.current_page -1" [length]="pagination_product.total_count"
            [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" (page)="onClick_Product_Pagination($event)">
        </mat-paginator>
    </div>