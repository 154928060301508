<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>timer</mat-icon> Hold Time
            </h5>
        </mat-card-title>
        <mat-doc-component class="amd-card-help-button" [docLink]="'hold-time'"></mat-doc-component>
    </mat-card-header>
    <!-- Main page content -->
    <mat-card-content class="amd-card-content">
        <!-- Add new record -->
        <div class="mat-elevation-z8" style="margin-bottom: 25px;">
            <form [formGroup]="hold_time_form" #form="ngForm">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" style="padding:20px">
                    <mat-form-field fxFlex>
                        <input matInput placeholder="Name" formControlName="name">
                        <mat-error *ngIf="hold_time_form.get('name').invalid">You must enter a value</mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <input type="number" matInput placeholder="Days" formControlName="days">
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <input type="number" matInput placeholder="Hours" formControlName="hours">
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <input type="number" matInput placeholder="Minutes" formControlName="minutes">
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <input type="number" matInput placeholder="Seconds" formControlName="seconds">
                    </mat-form-field>
                    <div>
                        <div fxLayout="row" fxLayoutGap="10px">
                            <button mat-raised-button color="accent" (click)="onAdd_Update_Affiliate_Hold_Time_Submit(form)">
                                <mat-icon>{{updateButton ? "edit" : "add"}}</mat-icon> {{updateButton ? "Update Hold Time"
                                : "Add Hold Time"}}
                            </button>
                            <button *ngIf="updateButton" mat-raised-button color="accent" (click)="rest_Affiliate_Hold_Time_Form(form)">
                                <mat-icon>cancel</mat-icon> Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- Search Record -->
        <div class="mat-elevation-z8">
            <!-- Filter Database results -->
            <div fxLayout="row">
                <div>
                    <mat-input-debounce placeholder="Hold Time Search..." (value)="onAffiliate_Hold_Time_Search($event)" style="padding:20px">
                    </mat-input-debounce>
                </div>
            </div>
            <!-- Result table -->
            <table class="amd-table mat-elevation-z8" mat-table [dataSource]="affiliate_hold_time_list">
                <!-- Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Name </th>
                    <td mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="days">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Days </th>
                    <td mat-cell *matCellDef="let element">{{element.days}}</td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="hours">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Hours </th>
                    <td mat-cell *matCellDef="let element">{{element.hours}}</td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="minutes">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Minutes </th>
                    <td mat-cell *matCellDef="let element">{{element.minutes}}</td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="seconds">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Seconds </th>
                    <td mat-cell *matCellDef="let element">{{element.seconds}}</td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <button color="accent" mat-mini-fab (click)="onEdit_Affiliate_Hold_Time(element)">
                                <mat-icon matTooltip="Edit">edit</mat-icon>
                            </button>
                            <button color="warn" mat-mini-fab (click)="onDelete_Affiliate_Hold_Time(element)">
                                <mat-icon matTooltip="Delete">delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <!-- Table Header -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!-- Pagination -->
            <mat-paginator [length]="pagination_hold_time.total_count" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" (page)="onClick_Affiliate_Hold_Time_Pagination($event)">
            </mat-paginator>
        </div>

    </mat-card-content>
</mat-card>