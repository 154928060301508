<mat-toolbar color="primary" class="header-box-shadow" style="position: sticky; top: 0; z-index: 10;">
  <button mat-icon-button (click)="helper_service.leftSideNav.toggle()">
    <mat-icon *ngIf="helper_service.leftSideNav.opened">menu_open</mat-icon>
    <mat-icon *ngIf="!helper_service.leftSideNav.opened">menu</mat-icon>
  </button>
  <button mat-button>
    {{appTitle}}
  </button>
  <span class="spacer"></span>
  <!-- User Menu Component -->
  <mat-user-menu></mat-user-menu>
  <!-- App Switch Component -->
  <mat-app-switch></mat-app-switch>
  <!--  Side Nav -->
  <mat-icon title="Menu" (click)="onShowRightSideNav()">more_vert</mat-icon>
</mat-toolbar>
