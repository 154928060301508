import { Injectable } from '@angular/core';

import { Guid_Service } from './Guid_Service';
import { Date_Service } from './Date_Service';
import { Service_Cryptography } from './Cryptography_Service';
import * as crypto from "crypto-js";

@Injectable()
export class Service_Security {
  constructor(public Service_Date: Date_Service, public Service_Guid: Guid_Service, private Service_Cryptography: Service_Cryptography) {
  }

  randomString(length : number, chars : string) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];

    return result;
  }

  generate_hash = (app_id: string, api_key: string) => {
    if (app_id == undefined || api_key == undefined || app_id == "" || api_key == "") {
      console.log("app_id or api_key undefined or empty!")
    }
    
    const randomstring = this.Service_Guid.MakeNew_Plain(); //this.randomString(32, "abcdefghijklmnopqrstuvwxyz");
    const timestamp = Math.ceil(Date.now() / 1000);
    const data = app_id + timestamp + randomstring;
    const key = crypto.enc.Base64.parse(api_key);
    const hash = crypto.HmacSHA256(data, key);
    const encoded = crypto.enc.Base64.stringify(hash);

    return encoded + ":" + randomstring + ":" + timestamp;
  };
  

}
