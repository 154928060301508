import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Confirmation_Dialog_Compnent } from "../../.././../../material_ui/components/UI_Dialogs/Confirmation_Dialog/Confirmation_Dialog";

import { web_search_filter } from '../../../objects/Web_Search/web_search_filter';
import { web_search_filter_tag } from '../../../objects/Web_Search/web_search_filter_tag';
import { web_search_sales_group } from '../../../objects/Web_Search/web_search_sales_group';
import { web_search_filter_tag_product } from '../../../objects/Web_Search/web_search_filter_tag_product';

import { pagination } from "../../../../../system/objects/pagination";

import { Web_Filter_Service } from '../../../services/Web_Search_Filter/Web_Filter';
import { Web_Search_Sales_Group_Service } from '../../../services/Web_Search_Sales_Group/Web_Search_Sales_Group';
import { Pagination_Service } from "../../../../../system/services/Pagination_Service";
import { Helper_Service } from '../../../../../material_ui/services/Core/Helper_Service';


@Component({
  selector: 'web_search_filter',
  templateUrl: './web_search_filter.html'
})
export class Component_Web_Search_Filter {

  web_filter_header: string = "";
  web_tag_header: string = "";
  web_filter_search: string = "";
  web_filter_list: web_search_filter[];
  pagination_web_filter: pagination = new pagination();
  pagination_filter_tag: pagination;
  pagination_sales_group: pagination = new pagination();
  displayedColumns: string[] = ['name', 'edit'];

  // Form & Controls Variables
  panelStep: number = 0;
  filter_tag_list: web_search_filter_tag[] = [];
  web_filter: web_search_filter = new web_search_filter;
  web_filter_name = new UntypedFormControl('', [Validators.required]);
  web_filter_tag: web_search_filter_tag = new web_search_filter_tag;
  web_filter_tag_sales_group: web_search_filter_tag_product[] = [];
  display_filter_tag_list: web_search_filter_tag[] = [];
  web_sales_group_result: web_search_sales_group[];
  availableWebSalesGroup: any[] = [];
  selectedWebSalesGroup: any[] = [];

  // Flags
  updateButton: boolean = false;
  showSearchBlock: boolean = true;

  constructor(private Web_Filter_Service: Web_Filter_Service, private Pagination_Service: Pagination_Service, private helper_service: Helper_Service,
     private dialog: MatDialog,private Web_Search_Sales_Group_Service:Web_Search_Sales_Group_Service) { }

  ngOnInit() {
    this.getBackend_Data_Web_Filter(null, null);
    this.getBackend_Data_Web_Sales_Group(null, null);
    this.RefreshPaginationFilterTag();
  }

  onClick_Web_Filter_Pagination(e: any) {
    this.getBackend_Data_Web_Filter(e.pageIndex + 1, e.pageSize);
  }

  onClick_Web_Tag_Pagination(e: any) {
    this.RefreshPaginationFilterTag(String(e.pageIndex + 1), e.pageSize);
  }

  onClick_Sales_Group_Pagination(e: any) {
    this.getBackend_Data_Web_Sales_Group(e.pageIndex + 1, e.pageSize);
  }

  getBackend_Data_Web_Filter(page: number, pageSize: number) {
    this.Web_Filter_Service.getWeb_Filter_List(page, pageSize).subscribe((res) => {
      this.web_filter_list = res.body['web_search_filter'];
      this.pagination_web_filter = res.body['pagination'];
    });
  }
  onClick_Web_Sales_Group_Search(value: string) {
    this.Web_Search_Sales_Group_Service.getWeb_Sales_Group(null,value,null).subscribe((res) => {
      this.web_sales_group_result = res.body['web_search_sales_group'];
      this.pagination_sales_group = res.body['pagination'];
      this.availableWebSalesGroup = [];
      (res.body["web_search_sales_group"]).forEach(obj => {
        this.availableWebSalesGroup.push({ id: obj.id.toString(), name: obj.name, selected: false });
      });
    });
  }
  getBackend_Data_Web_Sales_Group(page: number, pageSize: number) {
    this.Web_Search_Sales_Group_Service.getWeb_Sales_Group_List(page, pageSize).subscribe((res) => {
      this.web_sales_group_result = res.body['web_search_sales_group'];
      this.pagination_sales_group = res.body['pagination'];
      this.availableWebSalesGroup = [];
      (res.body["web_search_sales_group"]).forEach(obj => {
        this.availableWebSalesGroup.push({ id: obj.id.toString(), name: obj.name, selected: false });
      });
    });
  }
  map_Data_Web_Sales_Group_Selected() {
    this.selectedWebSalesGroup = [];
    this.web_filter_tag_sales_group.forEach(obj => {
      this.selectedWebSalesGroup.push({ id: obj.sales_group_id.toString(), name: obj.sales_group.name, selected: true })
    });
  }
  onAddWebSalesGroup(item: any) {
    this.web_filter_tag_sales_group.push({ tag_id: this.web_filter_tag.id, tag: null, sales_group_id: +item.id, sales_group: null });
  }
  onRemoveWebSalesGroup(item: any) {
    this.web_filter_tag_sales_group = this.web_filter_tag_sales_group.filter(x => x.sales_group_id.toString() !== item.id);
  }

  RefreshPaginationFilterTag(page?: string, page_size?: number) {
    var output = this.Pagination_Service.Array_Paginator(this.filter_tag_list, page, page_size);
    this.display_filter_tag_list = output.data;
    this.pagination_filter_tag = output.pagination;
  }

  onWeb_Filter_Search(value: string) {
    this.web_filter_search = value;
    this.Web_Filter_Service.getWeb_Filters(null, this.web_filter_search).subscribe((res) => {
      this.web_filter_list = res.body['web_search_filter'];
      this.pagination_web_filter = res.body['pagination'];
    });
  }

  onAdd_Web_Filter() {
    // Bind Variables 
    this.web_filter_header = "Enter Details To Add a New Web Serach Filter:"
    this.showSearchBlock = false;
  }

  onEdit_Web_Filter(selected_Web_Filter: web_search_filter) {
    // Bind Variables 
    this.web_filter_header = "Edit Details: " + selected_Web_Filter.name;
    this.web_filter = selected_Web_Filter;
    this.web_filter_name.setValue(selected_Web_Filter.name);
    this.filter_tag_list = selected_Web_Filter.web_search_filter_tags;
    // Update Flag
    this.updateButton = true;
    this.showSearchBlock = false;
    // Call Local Funtion
    this.RefreshPaginationFilterTag();
    this.panelStep = 0;
  }

  onDelete_Web_Filter(selected_Web_Filter: web_search_filter) {

    const dialogRef = this.dialog.open(Confirmation_Dialog_Compnent, {
      data: { title: "Delete !", text: "Are you sure you want to delete " + selected_Web_Filter.name + "?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        // call delete service
        this.Web_Filter_Service.deleteWeb_Filter(selected_Web_Filter).subscribe(
          (response) => { this.helper_service.openSnackbarNotification(selected_Web_Filter.name + ' has been deleted successfully', 'Close', 'success'); },
          (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
          () => { this.rest_WebFilterForm() }
        );
      }
    });
  }

  onAddFilterTag() {
    // Bind Variables 
    this.web_tag_header = "Enter Details To Add a New Filter Tag:"
    this.web_filter_tag_sales_group = [];
    this.web_filter_tag.filter_id = this.web_filter.id;
    this.web_filter.web_search_filter_tags.push(this.web_filter_tag);
    this.getBackend_Data_Web_Sales_Group(null, null);
    this.panelStep = 1;

  }

  onUpdateFilterTag(selectItem: any) {
    // Bind Variables 
    this.web_tag_header = "Edit Details: " + selectItem.name;
    this.web_filter_tag = selectItem;
    this.web_filter_tag_sales_group = selectItem.tag_products;
    this.getBackend_Data_Web_Sales_Group(null, null);
    this.map_Data_Web_Sales_Group_Selected();
    this.panelStep = 1;
  }

  onDeleteFilterTag(selectItem: any) {

    const dialogRef = this.dialog.open(Confirmation_Dialog_Compnent, {
      data: { title: "Delete !", text: "Are you sure you want to delete " + selectItem.name + "?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.filter_tag_list = this.web_filter.web_search_filter_tags = this.filter_tag_list.filter(x => x.id != selectItem.id);
        this.RefreshPaginationFilterTag();
      }
    });
  }

  onAdd_Update_Web_Filter_Submit() {
    if (this.web_filter_name.valid) {
      //  Update Web Engine
      if (this.updateButton) {
        this.Web_Filter_Service.updateWeb_Filter(this.map_Form_Data_For_Backend()).subscribe(
          (response) => { this.helper_service.openSnackbarNotification('You have successfully updated the web filter', 'Close', 'success'); },
          (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
          () => { this.rest_WebFilterForm() }
        );
      }
      // Create New Web Engine
      else {
        this.Web_Filter_Service.addWeb_Filter(this.map_Form_Data_For_Backend()).subscribe(
          (response) => { this.helper_service.openSnackbarNotification('You have successfully added the web filter', 'Close', 'success'); },
          (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
          () => { this.rest_WebFilterForm() }
        );
      }
    }
    else {
      this.web_filter_name.markAsTouched();
    }
  }

  map_Form_Data_For_Backend(): web_search_filter {

    this.web_filter.name = this.web_filter_name.value;

    if (this.web_filter.web_search_filter_tags.length == 0) {
      this.web_filter_tag.tag_products = this.web_filter_tag_sales_group;
      this.web_filter.web_search_filter_tags.push(this.web_filter_tag);
    }
    else {
      this.web_filter.web_search_filter_tags.forEach(x => {
        if (x.id == this.web_filter_tag.id) {
          x.filter_id = this.web_filter_tag.filter_id;
          x.id = this.web_filter_tag.id;
          x.name = this.web_filter_tag.name;
          x.tag_products = this.web_filter_tag_sales_group;
        }
      });
    }
    return this.web_filter;
  }

  setPanelStep(index: number) {
    this.panelStep = index;
  }

  rest_WebFilterForm() {
    this.getBackend_Data_Web_Filter(null, null);
    this.getBackend_Data_Web_Sales_Group(null, null);
    this.RefreshPaginationFilterTag();

    this.showSearchBlock = true;
    this.updateButton = false;
    this.panelStep = 0;

    this.web_filter.id = 0;
    this.web_filter.name = "";
    this.web_filter.web_search_filter_tags = [];
    this.web_filter_name.reset();
    this.web_filter_tag.id = 0;
    this.web_filter_tag.name = "";
    this.web_filter_tag.tag_products = [];

    this.web_filter_header = "";
    this.web_tag_header = "";

    this.filter_tag_list = [];
    this.web_filter_tag_sales_group = [];
    this.display_filter_tag_list = [];
    this.web_sales_group_result = [];
    this.availableWebSalesGroup = [];
    this.selectedWebSalesGroup = [];
  }
}

