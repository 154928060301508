import { Component } from '@angular/core';
import { Call_Info_Service } from '../../../system/services/Call_Info_Service';


@Component({
    selector: 'mat-loading-indicator',
    templateUrl: './Loading_Indicator.html',
    styleUrls: ['./Loading_Indicator.scss']
})
export class Loading_Indicator_Component {

    showProgress: boolean = false;

    constructor(private call_info_service: Call_Info_Service) {
    }

    ngDoCheck() {
        // Show the progress bar if any call is in queue
        if (this.call_info_service.active_calls.length > 0) {
            this.showProgress = true;
            this.call_info_service.showProgress = true;
        }
        else {
            this.showProgress = false;
            this.call_info_service.showProgress = false;
        }
    }

}