import { Component } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.html'
})
export class Component_Home {

  constructor() {
  }
}
