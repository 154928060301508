import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';

import { OAuthService, AuthConfig, JwksValidationHandler } from 'angular-oauth2-oidc';

import { Environment_Service } from "../Environment_Service";
import { LocalStorage_Service } from "../system/LocalStorage_Service";

import { token_login } from "./../../objects/identity/token_login";
import { tms_internal_user_info } from '../../objects/System/user_info/tms_internal_user_info';
import { error_message } from "./../../objects/identity/error_message";

import { v1_3_identity_request } from "../../objects/identity/v1_3_identity_request";
import { v1_3_identity_claim_options } from "../../objects/identity/claims/v1_3_identity_claim_options";

import { v1_3_identity_switch_certificate_request } from "../../objects/identity/switch_certificate/v1_3_identity_switch_certificate_request";
import { v1_3_identity_switch_certificate_response } from "../../objects/identity/switch_certificate/v1_3_identity_switch_certificate_response";

import { Response_Service } from "../Response_Service";
import { Call_Info_Service } from "../Call_Info_Service";


@Injectable({
  providedIn: 'root'
})
export class OAuth_Service {

  constructor(
    private oAuthService: OAuthService,
    private Response_Service: Response_Service,
    private Call_Info_Service: Call_Info_Service,
    private Environment_Service: Environment_Service,
    private router: Router,
    private localStorage_Service: LocalStorage_Service,
    @Optional() @SkipSelf() parentModule?: OAuth_Service) {
    //    this.initialise_OAuth();
    if (parentModule) {
      throw new Error(
        'OAuth_Service is already loaded. Import it in the AppModule only');
    }
  }
  //private access_token: string = null;

  public user_info: tms_internal_user_info = new tms_internal_user_info();
  claim_info: v1_3_identity_claim_options = new v1_3_identity_claim_options();

  public initialise_OAuth() {
    var authConfig: AuthConfig = new AuthConfig();
    authConfig.issuer = this.Environment_Service.environment.identity_server;
    authConfig.redirectUri = window.location.protocol + "//" + window.location.host;
    authConfig.clientId = this.Environment_Service.environment.identity_client_id;
    authConfig.scope = this.Environment_Service.environment.identity_scope;
    authConfig.silentRefreshRedirectUri = window.location.origin + '/assets/silent-refresh.html';
    
    this.oAuthService.configure(authConfig);
    this.oAuthService.setupAutomaticSilentRefresh();
    //this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.oAuthService.loadDiscoveryDocumentAndTryLogin();
  }

  public login() {
    this.oAuthService.initImplicitFlow();
  }

  public logoff() {
    this.localStorage_Service.removeAccessToken();
    this.oAuthService.logOut();
  }

  public getUser_Info<T>() {
    if (this.isLoggedIn() == true) {
      var token = this.getToken();
      console.log('get userinfo with token: ' + token.substring(token.length - 25));
      return this.Response_Service.getResponseInfo<T>(this.Environment_Service.environment.identity_server + "/connect/userinfo", token);
    }
  }

  public getLogin_Password(User_Name: string, Password: string) {
    this.oAuthService.tokenEndpoint = this.Environment_Service.environment.identity_server + "/connect/token";
    this.oAuthService.clientId = this.Environment_Service.environment.identity_client_id;
    this.oAuthService.dummyClientSecret = this.Environment_Service.environment.identity_client_secret;

    return this.oAuthService.fetchTokenUsingPasswordFlow(User_Name, Password);
  }

  public isLoggedIn(): boolean {
    return (this.oAuthService.getAccessToken() != null && this.Call_Info_Service.Currently_Logged_In() == true);
  }

  public setToken(token: string) {
    console.log('set token: ' + token.substring(token.length - 25));
    //this.access_token = token;
    this.localStorage_Service.setAccessToken(token);
    console.log('token set: ' + this.getTokenFromStorage().substring(this.getTokenFromStorage().length - 25));
  }

  public getTokenFromStorage() {
    return this.localStorage_Service.getAccessToken();
  }

  public getToken(): string {
    if (this.Call_Info_Service.Currently_Logged_In() == true) {
      if (this.getTokenFromStorage() != null) {
        return this.getTokenFromStorage();
      }
      var accessToken = this.oAuthService.getAccessToken();
      this.localStorage_Service.setAccessToken(accessToken);
      return accessToken;
    }
    return "";
  }

  public getError_Message(errorId: string): Observable<error_message> {
    return this.Response_Service.getResponse<error_message>(this.Environment_Service.environment.identity_server + "/api/v1/identity/error?errorId=" + errorId);
  }

  public getUser_Claims(identity_login: v1_3_identity_request): Observable<HttpResponse<v1_3_identity_claim_options>> {
    return this.Response_Service.post_Data<v1_3_identity_claim_options>(this.Environment_Service.environment.identity_server + "/api/v1.3/" + this.Environment_Service.environment.identity_server_app_id + "/identity/login/claims", identity_login);
  }

  public cacheUserInfo() {
    this.getUser_Info<tms_internal_user_info>().subscribe(
      User_Info => {
        console.log('User Info received: ' + User_Info.operator_id + '-' + User_Info.finance_centre_id);
        this.user_info = User_Info;
        var Identity_Request: v1_3_identity_request = new v1_3_identity_request();
        Identity_Request.user_name = User_Info.name;

        this.getUser_Claims(Identity_Request).subscribe(Claim_Response => {
          this.claim_info = Claim_Response.body;
          //this.currentUserFinanceCentreId = this.claim_info.scopes[0].controls[0].value;
          this.user_info.certificate = this.claim_info.scopes[0].controls[0].options.find(fc => fc.id == this.user_info?.finance_centre_id).name;
        });
      },
      err => {
        //issue with getting the userinfo, could be because of expired token, logout.
        //would really need a refreshtoken here?
        console.log(err);

        //logout
        this.user_info = null;
        this.localStorage_Service.removeAccessToken();
        this.logoff();

        //try to login
        this.login();
      }
    );
  }

  public Switch_Certificate(User_Name: string, Finance_Centre_ID: string): Observable<HttpResponse<v1_3_identity_switch_certificate_response>> {
    var Switch_Certificate_Request: v1_3_identity_switch_certificate_request = new v1_3_identity_switch_certificate_request();
    Switch_Certificate_Request.user_name = User_Name;
    Switch_Certificate_Request.finance_centre_id = +Finance_Centre_ID;

    return this.Response_Service.post_Data<v1_3_identity_switch_certificate_response>(this.Environment_Service.environment.identity_server + "/api/v1.3/" + this.Environment_Service.environment.identity_server_app_id + "/identity/login/switch_certificate", Switch_Certificate_Request);
  }

  public get_Logout() {
    var Post_Body: HttpParams = new HttpParams();
    Post_Body.set("token", this.oAuthService.getAccessToken());
    Post_Body.set("client_id", this.Environment_Service.environment.identity_client_id);
    Post_Body.set("client_secret", this.Environment_Service.environment.identity_client_secret);

    this.Response_Service.post_Data_URL_Encoded(this.Environment_Service.environment.identity_server + "/connect/revocation", Post_Body);

    this.localStorage_Service.removeAccessToken();

    this.router.navigate(["/account/login"]);
  }

  public loadAndAuthorize(): Promise<boolean> {
    return this.oAuthService.loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        
        if (!this.oAuthService.hasValidAccessToken()) {
          this.oAuthService.initImplicitFlow();
        }
        else {
          return this.oAuthService.loadUserProfile().then(() => {
            return this.oAuthService.hasValidAccessToken();
          });
        }
      });
  }

  get isAuthorized() {
    return this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      return this.oAuthService.hasValidAccessToken();
    });
  }

  get identityClaims(): tms_internal_user_info {
    return Object.assign(this.oAuthService.getIdentityClaims());
  }

}
