<mat-tab-group>
        <mat-tab label="Overview">
                <h3 class="mat-subheading-2 text-center">Theme Color</h3>
                <!-- Theme Picker Component -->
                <mat-theme-picker></mat-theme-picker>
                <mat-divider></mat-divider>
                <div class="environment">
                        <h3 class="mat-subheading-2 text-center">Environment</h3>
                        <div class="text-center setting">Config: {{environment_service.environment.environment_name}}</div>
                        <div class="text-center setting">Version: {{environment_service.environment.app_build_version}}</div>
                        <div class="text-center setting">Date: {{environment_service.environment.app_build_date}}</div>
                        <div class="text-center setting">Description: {{environment_service.environment.app_build_description}}</div>
                </div>
        </mat-tab>

        <mat-tab label="Call Status">
                <div>

                        <h3 class="mat-subheading-2 text-center" style="margin: 0px 14px 0;">Successful Calls
                        </h3>
                        <mat-divider></mat-divider>
                        <perfect-scrollbar style="max-height: 350px;">
                                <mat-list>
                                        <mat-list-item *ngFor="let call_info of call_info_data.audit_calls">

                                                <mat-icon matListIcon style="color:green">done</mat-icon>
                                                <h3 matLine>
                                                        <div class="text_move">{{call_info.call_url}}</div>
                                                </h3>
                                                <div matLine class="text_move">
                                                        <span> {{call_info.start_time | date :'hh:mm:ss'}}
                                                        </span>
                                                        <span> -- {{call_info.end_time | date :'hh:mm:ss'}}
                                                        </span>
                                                        <span> -- Processing Time:
                                                                {{call_info.processing_milliseconds}}ms
                                                        </span>
                                                </div>
                                                <mat-divider></mat-divider>
                                        </mat-list-item>
                                </mat-list>
                        </perfect-scrollbar>



                        <h3 class="mat-subheading-2 text-center" style="margin: 0px 14px 0;">Failed Calls</h3>
                        <mat-divider></mat-divider>
                        <perfect-scrollbar style="max-height: 350px;">
                                <mat-list>
                                        <mat-list-item *ngIf="call_info_data.error_calls.length == 0">No Errors
                                        </mat-list-item>
                                        <mat-list-item *ngFor="let call_info of call_info_data.error_calls">

                                                <mat-icon matListIcon style="color:red">close</mat-icon>
                                                <h3 matLine>
                                                        <div class="text_move">{{call_info.call_url}}</div>
                                                </h3>
                                                <div matLine class="text_move">
                                                        <span> {{call_info.start_time | date :'hh:mm:ss'}}
                                                        </span>
                                                        <span> -- {{call_info.end_time | date :'hh:mm:ss'}}
                                                        </span>
                                                        <span> -- Processing Time:
                                                                {{call_info.processing_milliseconds}}ms
                                                        </span>
                                                </div>
                                                <mat-divider></mat-divider>
                                        </mat-list-item>
                                </mat-list>
                        </perfect-scrollbar>


                        <div *ngIf="showActiveCalls">
                                <h3 class="mat-subheading-2 text-center" style="margin: 0px 14px 0;">Active Calls</h3>
                                <mat-divider></mat-divider>
                                <perfect-scrollbar style="max-height: 350px;">
                                        <mat-list>
                                                <mat-list-item *ngFor="let call_info of call_info_data.active_calls">

                                                        <mat-icon matListIcon>info</mat-icon>
                                                        <h3 matLine>
                                                                <div class="text_move">{{call_info.call_url}}</div>
                                                        </h3>
                                                        <div matLine class="text_move">
                                                                <span> {{call_info.start_time | date :'hh:mm:ss'}}
                                                                </span>
                                                                <span> -- {{call_info.end_time | date :'hh:mm:ss'}}
                                                                </span>
                                                                <span> -- Processing Time:
                                                                        {{call_info.processing_milliseconds}}ms
                                                                </span>
                                                        </div>
                                                        <mat-divider></mat-divider>
                                                </mat-list-item>
                                        </mat-list>
                                </perfect-scrollbar>
                        </div>
                </div>

        </mat-tab>
</mat-tab-group>