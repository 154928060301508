<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>group_work</mat-icon> Sales Group
            </h5>
        </mat-card-title>
        <mat-card-title>
            <h6>{{web_sales_group_header}}</h6>
        </mat-card-title>
        <mat-doc-component class="amd-card-help-button" [docLink]="'sales-group'"></mat-doc-component>
    </mat-card-header>
    <!-- Main page content -->
    <mat-card-content class="amd-card-content" *ngIf="showSearchBlock">
        <!-- Add new record -->
        <div class="amd-corner-button" style="margin-top: 20px;">
            <button mat-fab color="accent" (click)="onAdd_Sales_Group()">
                <mat-icon matTooltip="Add Sales Group">add</mat-icon>
            </button>
        </div>
        <!-- Search Record -->
        <div class="mat-elevation-z8">
            <!-- Filter Database results -->
            <div fxLayout="row" fxLayoutGap="100px">
                <div>
                    <mat-input-debounce placeholder="Sales Group Search..." [inputValue]="sales_group_search" (value)="onSales_Group_Search($event)"
                        style="padding:20px"> </mat-input-debounce>
                </div>
                <div>
                    <mat-input-debounce title="Use TourID to search for products within sales groups" placeholder="Product Search..." [inputValue]="product_search" (value)="onProduct_Search($event)"
                        style="padding:20px"> </mat-input-debounce>
                </div>
            </div>
            <!-- Result table -->
            <table class="amd-table mat-elevation-z8" mat-table [dataSource]="web_sales_group_result">
                <!-- Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Sales Group Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <button color="accent" mat-mini-fab (click)="onEdit_Sales_Group(element)">
                                <mat-icon matTooltip="Edit">edit</mat-icon>
                            </button>
                            <button color="warn" mat-mini-fab (click)="onDelete_Sales_Group(element)">
                                <mat-icon matTooltip="Delete">delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <!-- Table Header -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!-- Pagination -->
            <mat-paginator [length]="pagination_web_sales_group.total_count" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"
                [showFirstLastButtons]="true"
                (page)="onClick_Sales_Group_Pagination($event)" >
            </mat-paginator>
        </div>
    </mat-card-content>
    <!-- Add or Update page content -->
    <mat-card-content class="amd-card-content" *ngIf="!showSearchBlock">
        <!-- Form Fields -->
        <mat-form-field class="amd-form_field">
            <input matInput placeholder="Sales Group Name" [formControl]="sales_group_name">
            <mat-error *ngIf="sales_group_name.invalid">You must enter a value</mat-error>
        </mat-form-field>
        <!-- Header -->
        <h3 style="text-align: center">Sales Group Products</h3>
        <!-- Data List -->
        <div style="margin-bottom: 25px;">
            <!-- Call product selection component -->
            <product-selection [(selectededItems)]="selectedProductItems" (addProductSelected)="onAddProduct($event)" (removeProductSelected)="onRemoveProduct($event)" 
            [enableSelectedRowSettings]="true" (productSelectedOptionSettings)="onProductSettings($event)" [settingsTemplateRef]="productSettingsTemplate" >
            </product-selection> 
        </div>
        <mat-form-field class="amd-form_field">
            <mat-select placeholder="Default Product" [formControl]="product_id" >
                <mat-option *ngFor="let product of defaultProductItems" [value]="product.id">{{product.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="product_id.invalid">You must select a product</mat-error>
        </mat-form-field>

    </mat-card-content>
    <!--Actions Control  -->
    <mat-card-actions align="center" *ngIf="!showSearchBlock">
        <div class="amd-card-content">
            <button mat-raised-button color="accent" (click)="onAdd_Update_Sales_Group_Submit()">
                <mat-icon>{{updateButton ? "edit" : "add"}}</mat-icon> {{updateButton ? "Update Sales Group" : "Add Sales Group"}}
            </button>
            <button mat-raised-button color="accent" (click)="reset_SalesGroupForm()">
                <mat-icon>cancel</mat-icon> Cancel
            </button>
        </div>
    </mat-card-actions>
</mat-card>

<!-- Custom Template for Sales Group Product Options -->
<ng-template #productSettingsTemplate>
    <div class="menu-wrapper mat-elevation-z8" tabIndex="0">
        <mat-checkbox class="menu-item" [(ngModel)] = "sales_group_product_settings.not_for_sale">Not For Sale</mat-checkbox>
    </div>
</ng-template>