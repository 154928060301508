import { Injectable } from '@angular/core';

import { affiliate } from "../../../gateway/objects/Affiliate/affiliate";
import { affiliate_hold_time } from '../../objects/Affiliate/affiliate_hold_time';
import { affiliate_cache } from '../../objects/Affiliate/affiliate_cache';
import { affiliate_channel_management_list } from '../../objects/Affiliate/affiliate_channel_management_list';

import { Environment_Service } from "../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../system/services/Response_Service";


@Injectable()
export class Affiliate_Service {

    constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

    getAffiliate_List(page_number: number, page_size: number) {

        if (page_number != null && page_size != null) {
            return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate?page_number=" + page_number + "&page_size=" + page_size);
        }
        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate");
    }

    getAffiliate(paged_url: string, Search: string) {
        if (paged_url != null) {
            return this.Response_Service.getResponse_Paged(paged_url);
        }

        var search_options: string[] = [];

        if (Search != "") {
            search_options.push("search=" + Search);
        }


        var search_option: string = "";

        for (var index = 0; index < search_options.length; index++) {
            search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
        }
        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate" + search_option);
    }

    updateAffiliate(affiliate: affiliate) {
        affiliate.sales_centre_id = +affiliate.sales_centre_id;
        return this.Response_Service.put_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate", affiliate);
    }

    addAffiliate(new_affiliate: affiliate) {
        new_affiliate.sales_centre_id = +new_affiliate.sales_centre_id;
        return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate", new_affiliate);
    }

    getApi_info(paged_url: string) {

        if (paged_url != null) {
            return this.Response_Service.getResponse(paged_url);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/security/generate_api_info");
    }
    getFinance_Centre_List(paged_url: string) {

        if (paged_url != null) {
            return this.Response_Service.getResponse(paged_url);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/company_finance_centre");
    }
    getConnector_Type_List(paged_url: string) {

        if (paged_url != null) {
            return this.Response_Service.getResponse(paged_url);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/connector_type");
    }
    // Affiliate Hold Time Calls
    getAffiliate_Hold_Time(page_number?: number, page_size?: number, all_result?: boolean) {

        if (page_number != null && page_size != null) {
            return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/hold_time?page_number=" + page_number + "&page_size=" + page_size);
        }
        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/hold_time?all_results=" + all_result);
    }
    getAffiliate_Hold_Time_Search(paged_url: string, Search: string) {
        if (paged_url != null) {
            return this.Response_Service.getResponse_Paged(paged_url);
        }

        var search_options: string[] = [];

        if (Search != "") {
            search_options.push("search=" + Search);
        }


        var search_option: string = "";

        for (var index = 0; index < search_options.length; index++) {
            search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
        }
        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/hold_time" + search_option);
    }
    addUpdate_Affiliate_Hold_Time(affiliate_hold_time: affiliate_hold_time) {
        return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/hold_time", affiliate_hold_time);
    }
    delete_Affiliate_Hold_Time(affiliate_hold_time: affiliate_hold_time) {
        return this.Response_Service.delete_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/hold_time/" + affiliate_hold_time.id);
    }
    //  Afflicate Cache
    getCache_Server(paged_url: string) {
        if (paged_url != null) {
            return this.Response_Service.getResponse(paged_url);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/cache_server");
    }
    get_Affiliate_Cache(app_id: string) {
        if (app_id != null) {
            return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/affilicate_cache?app_id=" + app_id);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/affilicate_cache");
    }
    addUpdate_Affiliate_Cache(affiliate_cache: affiliate_cache[], app_id: string) {
        return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/affilicate_cache?App_id=" + app_id, affiliate_cache);
    }
    // Channel Management List
    get_Affiliate_Channel_Management_List(app_id: string) {
        if (app_id != null) {
            return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/channel_management_list?app_id=" + app_id);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/channel_management_list");
    }
    addUpdate_Affiliate_Channel_Management_List(affiliate_channel_management_list: affiliate_channel_management_list[], app_id: string) {
        return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate/channel_management_list?App_id=" + app_id, affiliate_channel_management_list);
    }
}
