<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="50px">
    <div fxFlex>
        <h6 class="text-center">Available Options</h6>
        <mat-input-debounce placeholder="Available Options Search..." (value)="onAvailableOptionsSearch($event)"> </mat-input-debounce>
        <table mat-table [dataSource]="availableItems" class="amd-table mat-elevation-z8">
            <!-- Option Column -->
            <ng-container matColumnDef="option">
                <th mat-header-cell *matHeaderCellDef> Option </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    <mat-chip *ngIf="element.selected" color="accent" selected>Selected</mat-chip>
                    <mat-checkbox *ngIf="!element.selected" (change)="onAvailableItems(element)"></mat-checkbox>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!-- Pagination -->
        <mat-paginator [pageIndex]="availableItemsPagination.current_page -1" [length]="availableItemsPagination.total_count" [pageSize]="10"
            [pageSizeOptions]="[5, 10]" (page)="onAvailableItemsPaginate($event)" class="mat-elevation-z8">
        </mat-paginator>
    </div>

    <div fxFlex>
        <h6 class="text-center">Selected Options</h6>
        <mat-input-debounce placeholder="Selected Options Search..." (value)="onSelectedOptionsSearch($event)"> </mat-input-debounce>
        <table mat-table [dataSource]="displaySelectededItems" class="amd-table mat-elevation-z8">

            <!-- Option Column -->
            <ng-container matColumnDef="option">
                <th mat-header-cell *matHeaderCellDef> Option </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    <!-- <mat-checkbox [checked]="element.selected" (change)="onSelectedItems(element)"></mat-checkbox> -->
                    <button mat-icon-button color="accent" (click)="onSelectedItems(element)"><mat-icon>delete</mat-icon></button>
                    <button *ngIf="selectedRowAddShow" mat-icon-button color="accent" (click)="onSelectedRowAdd(element)"><mat-icon>add</mat-icon></button>
                    <button *ngIf="selectedRowSettings" mat-icon-button color="accent" (click)="onSelectedSettings(element)" [satPopoverAnchorFor]="menu" (click)="menu.toggle()"><mat-icon>more_horiz</mat-icon></button>
                    <sat-popover #menu yAlign="start" horizontalAlign="after" hasBackdrop>
                        <ng-container *ngTemplateOutlet="templateRef"></ng-container>
                    </sat-popover>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!-- Pagination -->
        <mat-paginator [pageIndex]="selectededItemsPagination.current_page -1" [length]="selectededItemsPagination.total_count" [pageSize]="10"
            [pageSizeOptions]="[5, 10]" (page)="onSelectedItemsPaginate($event)" class="mat-elevation-z8">
        </mat-paginator>
    </div>
</div>
