<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>perm_identity</mat-icon> User Profile
            </h5>
        </mat-card-title>
    </mat-card-header>
    <!-- Main page content -->

    <mat-card-content class="amd-card-content">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <div fxFlex="30%">
                <mat-card class="card-profile">
                    <a>
                        <img [src]="profile.photoUrl || ''">
                    </a>
                    <div class="card-profile-title">
                        <header>
                            <h4>{{profile.firstName}} {{profile.lastName}}</h4>
                            <h5>{{profile.userName}}</h5>
                        </header>
                    </div>
                </mat-card>
            </div>

            <div fxFlex="70%">
                <mat-card fxFlex class="amd-card">
                    <mat-card-header class="amd-card-header">
                        <mat-card-title>
                            <h3 class="mat-subheading-2">Edit User Profile</h3>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="amd-card-content">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                            <div fxFlex="50%">
                                <mat-form-field fxFlex>
                                    <input matInput placeholder="First Name" [(ngModel)]="user_info.first_name">
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%">
                                <mat-form-field fxFlex>
                                    <input matInput placeholder="Last Name" [(ngModel)]="user_info.last_name">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                            <mat-form-field fxFlex>
                                <input matInput placeholder="Photo URL" [(ngModel)]="user_info.photo_url">
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                    <!--Actions Control  -->
                    <mat-card-actions align="center">
                        <button mat-raised-button color="accent" (click)="updateProfile()">
                            <mat-icon>edit</mat-icon> Update Profile
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>

        </div>
    </mat-card-content>


    <!--Actions Control  -->
    <mat-card-actions align="center">
        <div class="amd-card-content">

        </div>
    </mat-card-actions>

</mat-card>