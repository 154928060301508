import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { OAuthService, AuthConfig, JwksValidationHandler } from 'angular-oauth2-oidc';
import { Environment_Service } from "../../../modules/system/services/Environment_Service";

@Injectable()
export class oAuth_Internal_Identity_Guard implements CanActivate {

    constructor(private oAuthService: OAuthService,private Environment_Service: Environment_Service) {

        var authConfig: AuthConfig = new AuthConfig();
        authConfig.issuer = this.Environment_Service.environment.identity_server;
        authConfig.redirectUri = this.Environment_Service.environment.identity_server_login_redirect;
        authConfig.clientId = this.Environment_Service.environment.identity_client_id;
        authConfig.scope = this.Environment_Service.environment.identity_scope;
        authConfig.silentRefreshRedirectUri = window.location.origin + '/assets/silent-refresh.html';
        authConfig.requireHttps = false;
        this.oAuthService.configure(authConfig);
        this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
        this.oAuthService.loadDiscoveryDocumentAndTryLogin();
        this.oAuthService.setupAutomaticSilentRefresh();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        return this.oAuthService.loadDiscoveryDocumentAndTryLogin()
        .then(() => {
          if (!this.oAuthService.hasValidAccessToken()) {
            this.oAuthService.initImplicitFlow();
          }
          else {
            return this.oAuthService.loadUserProfile().then(() => {
              return this.oAuthService.hasValidAccessToken();
            });
          }
        });
    }
}