import { Component, OnInit, ViewChild } from '@angular/core';
import { Confirmation_Dialog_Compnent } from "../.././../../../material_ui/components/UI_Dialogs/Confirmation_Dialog/Confirmation_Dialog";
import { Select_Product_Dialog_Compnent } from '../../comman/ui_dialogs/select_product/select_product';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { mapping } from '../../../objects/Channel_Management/Mapping/mapping';
import { pagination } from "../../../../../system/objects/pagination";
import { cache_server } from '../../../objects/Type/cache_server';
import { affiliate } from '../../../objects/Affiliate/affiliate';

import { Mapping_Service } from '../../../services/Channel_Management/Mapping/Mapping';
import { Helper_Service } from '../../../../../material_ui/services/Core/Helper_Service';
import { Product_Selection_Service } from '../../../services/Comman/Product_Selection/Product_Selection';



@Component({
    selector: 'mapping',
    templateUrl: './mapping.html'
})
export class Component_Mapping {

    mapping_header: string = "";
    search_text: string = "";
    mapping: mapping = new mapping;
    mapping_list: mapping[];
    pagination_mapping: pagination = new pagination();
    displayedColumns: string[] = ['mapping_code', 'actions'];
    select_product: any[] = [];

    //Affiliate Cache Control
    @ViewChild("cacheList") cacheList: MatSelect;
    @ViewChild('chipList') chipList;
    selected_affiliate: affiliate[] = [];
    affiliates: affiliate[] = [];

    // Flags
    updateButton: boolean = false;
    showSearchBlock: boolean = true;

    constructor(private mapping_service: Mapping_Service, private helper_service: Helper_Service, private dialog: MatDialog, private Product_Selection_Service: Product_Selection_Service) { }

    ngOnInit() {
        //this.getBackend_Data_For_Controls();
        this.getBackend_Data_Mapping(null, null);
    }

    //#region Mapping Main
    onClick_Mapping_Pagination(e: any) {
        this.getBackend_Data_Mapping(e.pageIndex + 1, e.pageSize);
    }

    getBackend_Data_Mapping(page: number, pageSize: number) {
        // Get Mapping Data
        this.mapping_service.getMapping_List(page, pageSize).subscribe((res) => {
            this.mapping_list = res.body['mapping'];
            this.pagination_mapping = res.body['pagination'];
        });

        // Get Type Affiliates
        this.mapping_service.getAffiliates(null).subscribe(res => {
            this.affiliates = res["affiliate"];
        });
    }

    onMapping_Search(value: string) {
        this.search_text = value;
        this.mapping_service.getMapping_Search(null, this.search_text).subscribe((res) => {
            this.mapping_list = res.body['mapping'];
            this.pagination_mapping = res.body['pagination'];
        });
    }

    onAdd_Mapping() {
        // Bind Variables 
        this.mapping_header = "Enter Details To Add a New Price Override:"
        this.showSearchBlock = false;
    }

    onEdit_Mapping(selected_mapping: mapping) {
        // Bind Variables 
        this.mapping_header = "Edit Details: " + selected_mapping.mapping_code;

        this.mapping = selected_mapping;

        // Populate Product Control
        this.select_product.push(this.mapping.product);
        // Populate Cache Server List
        this.mapping_service.getMapping_Search(null, "").subscribe(() => {
            //Mapping Cache Get and Select on UI
            this.mapping.mapping_cache_servers.forEach(obj => {
                this.cacheList.options.forEach(x => {
                    if (x.value.app_id.toUpperCase() === obj.affiliate_id.toUpperCase()) {
                        x.select();
                    }
                });
            });
        });

        // Update Flag
        this.updateButton = true;
        this.showSearchBlock = false;
    }
    onDelete_Mapping(selected_mapping: mapping) {

        const dialogRef = this.dialog.open(Confirmation_Dialog_Compnent, {
            data: { title: "Delete !", text: "Are you sure you want to delete " + selected_mapping.mapping_code + "?" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
                // call delete service
                this.mapping_service.deleteMapping(selected_mapping).subscribe(
                    (response) => { this.helper_service.openSnackbarNotification(selected_mapping.mapping_code + ' has been deleted successfully', 'Close', 'success'); },
                    (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
                    () => { this.rest_MappingForm() }
                );
            }
        });
    }
    //#endregion


    //#region Mapping Detail
    addProduct() {
        const dialogRef = this.dialog.open(Select_Product_Dialog_Compnent, {
            height: '850px',
            width: '920px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
                this.select_product = result;
            }
            this.chipList.errorState = this.select_product.length > 0 ? false : true;
        });
    }

    removeProduct(product: any): void {
        const index = this.select_product.indexOf(product);

        if (index >= 0) {
            this.select_product.splice(index, 1);
        }

        this.chipList.errorState = this.select_product.length == 0 ? true : false;
    }
    //#endregion

    //#region Screen Actions

    onAdd_Update_Mapping_Submit() {
        if (this.select_product.length > 0) {
            // Add Product Id
            this.mapping.product_id = this.select_product[0].id;
            // Add Cache Server
            var mapping_affiliate: any[] = [];
            this.selected_affiliate.forEach(o => {
                mapping_affiliate.push({ mapping_id: this.mapping.id, affiliate_id: o.app_id });
            });
            this.mapping.mapping_cache_servers = mapping_affiliate;

            if (this.updateButton) {
                this.mapping_service.addMapping(this.mapping).subscribe(
                    (response) => { this.helper_service.openSnackbarNotification('You have successfully updated the mapping', 'Close', 'success'); },
                    (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
                    () => { this.rest_MappingForm() }
                );
            }
            else {
                this.mapping_service.addMapping(this.mapping).subscribe(
                    (response) => { this.helper_service.openSnackbarNotification('You have successfully added the mapping', 'Close', 'success'); },
                    (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
                    () => { this.rest_MappingForm() }
                );
            }
        }
        else {
            this.chipList.errorState = true;
        }
    }

    rest_MappingForm() {
        this.showSearchBlock = true;
        this.updateButton = false;
        this.mapping_header = "";
        this.search_text = "";
        this.mapping = new mapping();
        this.select_product = [];
        // Refresh API Call data
        this.getBackend_Data_Mapping(null, null);
    }
    //#endregion

}
