import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { OAuth_Service } from '../services/system/OAuth_Service';


@Injectable()
export class Auth_Guard implements CanActivate {

    constructor(private OAuth_Service: OAuth_Service) {
        this.OAuth_Service.initialise_OAuth();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.OAuth_Service.loadAndAuthorize();
    }
}