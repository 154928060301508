import { Injectable } from '@angular/core';

import { web_search_sales_group } from "../../../gateway/objects/Web_Search/web_search_sales_group";

import { Environment_Service } from "../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../system/services/Response_Service";

@Injectable()
export class Web_Search_Sales_Group_Service {

  constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

  getWeb_Sales_Group_List(page_number: number, page_size: number) {

    if (page_number != null && page_size != null) {
      return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_sales_group?page_number=" + page_number + "&page_size=" + page_size);
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_sales_group");
  }

  getWeb_Sales_Group(paged_url: string, Search: string, pageSize: number) {
    if (paged_url != null) {
      return this.Response_Service.getResponse_Paged(paged_url);
    }

    var search_options: string[] = [];

    if (Search != "") {
      search_options.push("search=" + Search);
    }

    if (pageSize != null) {
      search_options.push("page_number=1");
      search_options.push("page_size=" + pageSize);
    }

    var search_option: string = "";

    for (var index = 0; index < search_options.length; index++) {
      search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_sales_group" + search_option);
  }

  getWeb_Sales_Group_For_Product(paged_url: string, Search: string, pageSize: number) {
    if (paged_url != null) {
      return this.Response_Service.getResponse_Paged(paged_url);
    }

    var search_options: string[] = [];

    if (Search != "") {
      search_options.push("search=" + Search);
    }

    if (pageSize != null) {
      search_options.push("page_number=1");
      search_options.push("page_size=" + pageSize);
    }

    var search_option: string = "";

    for (var index = 0; index < search_options.length; index++) {
      search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_sales_group_for_product" + search_option);
  }

  updateWeb_Sales_Group(web_search_sales_group: web_search_sales_group) {
    return this.Response_Service.put_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_sales_group", web_search_sales_group);
  }

  addWeb_Sales_Group(new_web_search_sales_group: web_search_sales_group) {
    return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_sales_group", new_web_search_sales_group);
  }

  deleteWeb_Sales_Group(web_search_sales_group: web_search_sales_group) {
    return this.Response_Service.delete_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_sales_group/" + web_search_sales_group.id);
  }

  getWeb_Salse_Group_Product_List(page_number: number, page_size: number, search: string) {

    var search_options: string[] = [];
    if (search != "") {
      search_options.push("search=" + search);
    }

    var search_option: string = "";
    for (var index = 0; index < search_options.length; index++) {
      search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
    }

    if (page_number != null && page_size != null) {
      return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_sales_group_products?page_number=" + page_number + "&page_size=" + page_size);
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_sales_group_products" + search_option);
  }
}
