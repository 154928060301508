import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { MdePopoverModule } from '@material-extended/mde';

import { Sidebar_Component } from './Sidebar/Sidebar';
import { Side_Menu_Component } from './Side_Menu/Side_Menu';
import { Side_Menu_Item_Component } from './Side_Menu_Item/Side_Menu_Item';
import { User_Menu_Component } from './User_Menu/User_Menu';
import { Theme_Picker_Component } from './Theme_Picker/Theme_Picker';
import { Doc_Component } from './Doc_Component/Doc_Component';
import { App_Switch_Component } from './App_Switch/App_Switch';
import { Top_Toolbar_Component } from './Top_Toolbar/Top_Toolbar';
import { FinanceCentre_Header_Component } from './Finance_Centre_Header/Finance_Centre_Header'
import { NewTab_Header_Component } from './New_Tab_Header/New_Tab_Header'

import { Main_Top_Toolbar_Component } from "./Main_Top_Toolbar/Main_Top_Toolbar";
import { Main_NavMenu_Toolbar_Component } from "./Main_NavMenu_Toolbar/Main_NavMenu_Toolbar";
import { Toolbar_Menu_Item_Component } from "./Toolbar_Menu_Item/Toolbar_Menu_Item";
import { Toolbar_Submenu_Component } from "./Toolbar_Submenu/Toolbar_Submenu";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({

    declarations: [
        Sidebar_Component,
        Side_Menu_Component,
        Side_Menu_Item_Component,
        User_Menu_Component,
        Theme_Picker_Component,
        Doc_Component,
        App_Switch_Component,
        Top_Toolbar_Component,
        Main_Top_Toolbar_Component,
        Main_NavMenu_Toolbar_Component,
        Toolbar_Menu_Item_Component,
        Toolbar_Submenu_Component,
        FinanceCentre_Header_Component,
        NewTab_Header_Component
    ],

    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        SatPopoverModule,
        MdePopoverModule,

        MatButtonModule,
        MatTabsModule,
        MatListModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatBadgeModule,
        MatGridListModule,
        MatToolbarModule
    ],

    exports: [
        PerfectScrollbarModule,
        SatPopoverModule,
        MdePopoverModule,

        Sidebar_Component,
        Side_Menu_Component,
        Side_Menu_Item_Component,
        User_Menu_Component,
        Theme_Picker_Component,
        Doc_Component,
        App_Switch_Component,
        Top_Toolbar_Component,
        Main_Top_Toolbar_Component,
        Main_NavMenu_Toolbar_Component,
        Toolbar_Menu_Item_Component,
        Toolbar_Submenu_Component,
        FinanceCentre_Header_Component,
        NewTab_Header_Component
    ],

    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]

})
export class Core_Module { }
