import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';

import { menu_list_item } from "../../objects/Menu_List/menu_list_item";

@Injectable()
export class Helper_Service {
  public rightSideNav: any;
  public leftSideNav: any;
  public navMenu: any;
  public showProgressBar: boolean = false;
  public menuItemClicked$ = new Subject<any>();

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { 
  }

  getDataFromLocalJsonFile(filePath: string) {
    return this.http.get(filePath);
  }

  getData<T>(URL: string): Observable<T> {
    return this.http.get<T>(URL);
  }

  openSnackbarNotification(message: string, action: string, status: string) {
    if (status == "success") {
      message = "✔️ - " + message;
    }
    else if (status == "error") {
      message = "❌  - " + message;
    }
    this.snackBar.open(message, action, {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'center'
    });
  }
  // Right Side Nav Functions
  public toggleRighSideNav() {
    this.rightSideNav?.toggle();
  }
  // Left Side Nav Functions
  public toggleLeftSideNav() {
    this.leftSideNav?.toggle();
  }
  public closeLeftSideNav() {
    this.leftSideNav?.close();
  }
  public showNavMenu() {
    this.navMenu?.show();
  }
  public hideNavMenu() {
    this.navMenu?.hide();
  }

  public setTmsUrl(menu: any) {
    this.menuItemClicked$.next(menu);
  }

}
