import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';

import { Core_Module } from "./components/Core/core.module";

// Mat Modules
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Custom Components
import { Header_Component } from './components/Header/Header';
import { Drop_Down_Component } from './components/Drop_Down/Drop_Down';
import { Dual_List_Component } from './components/Dual_List/Dual_List';
import { MatInputDebounce } from './components/Input_Debounce/Input_Debounce';
import { Confirmation_Dialog_Compnent } from '../../modules/material_ui/components/UI_Dialogs/Confirmation_Dialog/Confirmation_Dialog';
import { Json_Viewer_Component } from '../../modules/material_ui/components/Json_Viewer/Json_Viewer';
import { Json_Viewer_Dialog_Compnent } from '../../modules/material_ui/components/UI_Dialogs/Json_Viewer_Dialog/Json_Viewer_Dialog';
import { Loading_Indicator_Component } from './components/Loading_Indicator/Loading_Indicator';
import { Loading_Indicator_Overlay_Component } from './components/Loading_Indicator_Overlay/Loading_Indicator_Overlay';

// Custom Servies
import { Helper_Service } from './services/Core/Helper_Service';
import { Snackbar_Service } from './services/Core/Snackbar_Service';
import { SelectAutocompleteComponent } from './components/Select_Autocomplete/Select_Autocomplete';



@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        LayoutModule,
        RouterModule,
        FlexLayoutModule,
        Core_Module,
        // Mat Modules
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatMenuModule,
        MatDividerModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatGridListModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatDialogModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatDatepickerModule, MatNativeDateModule,
        MatChipsModule,
        MatTabsModule,
        MatProgressBarModule,
        MatAutocompleteModule
    ],
    declarations: [MatInputDebounce,
        Header_Component,
        Drop_Down_Component,
        Dual_List_Component,
        Confirmation_Dialog_Compnent,
        Json_Viewer_Dialog_Compnent,
        Json_Viewer_Component,
        Loading_Indicator_Component,
        Loading_Indicator_Overlay_Component,
        SelectAutocompleteComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        FlexLayoutModule,
        Core_Module,
        // Mat Modules
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatMenuModule,
        MatDividerModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatGridListModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatDialogModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        MatTabsModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        // Custom Components
        MatInputDebounce,
        Header_Component,
        Drop_Down_Component,
        Dual_List_Component,
        Json_Viewer_Component,
        Loading_Indicator_Component,
        Loading_Indicator_Overlay_Component,
        SelectAutocompleteComponent
    ],
    providers: [Helper_Service, Snackbar_Service]
})
export class Material_Module { }

