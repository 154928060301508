<mat-loading-indicator></mat-loading-indicator>
<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>attachment</mat-icon> Affiliate
            </h5>
        </mat-card-title>
        <mat-card-title>
            <h6>{{affiliate_header}}</h6>
        </mat-card-title>
        <mat-doc-component class="amd-card-help-button" [docLink]="'affiliate'"></mat-doc-component>
    </mat-card-header>
    <!-- Main page content -->
    <mat-card-content class="amd-card-content" *ngIf="showSearchBlock">
        <!-- Add new record -->
        <div class="amd-corner-button" style="margin-top: 20px;">
            <button mat-fab color="accent" (click)="onAdd_Affiliate()">
                <mat-icon matTooltip="Add Affiliate">add</mat-icon>
            </button>
        </div>
        <!-- Search Record -->
        <div class="mat-elevation-z8">
            <!-- Filter Database results -->
            <div fxLayout="row">
                <div>
                    <mat-input-debounce placeholder="Affiliate Search..." (value)="onAffiliate_Search($event)"
                        style="padding:20px">
                    </mat-input-debounce>
                </div>
            </div>
            <!-- Result table -->
            <table class="amd-table mat-elevation-z8" mat-table [dataSource]="affiliate_list">
                <!-- Column -->
                <ng-container matColumnDef="connector">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Connector </th>
                    <td mat-cell *matCellDef="let element"> {{element.connector.name}} </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Affiliate Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="agent_id">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Agent ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.agent_id}} </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <button color="accent" mat-mini-fab (click)="onEdit_Affiliate(element)">
                                <mat-icon matTooltip="Edit">edit</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <!-- Table Header -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!-- Pagination -->
            <mat-paginator [length]="pagination_affiliate.total_count" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"
                (page)="onClick_Affiliate_Pagination($event)">
            </mat-paginator>
        </div>
    </mat-card-content>
    <!-- Add or Update page content -->
    <mat-card-content class="amd-card-content" *ngIf="!showSearchBlock">
        <!-- Form Fields -->
        <form fxLayout="column" [formGroup]="affilateForm">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex>
                    <input matInput placeholder="App ID" formControlName="app_id">
                </mat-form-field>

                <mat-form-field fxFlex>
                    <input matInput placeholder="Api Key" formControlName="api_key">
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex>
                    <input matInput placeholder="Name" formControlName="name">
                    <mat-error *ngIf="affilateForm.get('name').invalid">You must enter a value</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-select [formControl]="affilateForm.controls.selectedCacheServer" placeholder="External Cache Server" multiple>
                        <mat-select-trigger>
                            {{affilateForm.controls.selectedCacheServer.value ? affilateForm.controls.selectedCacheServer.value[0]?.name : ''}}
                            <span *ngIf="affilateForm.controls.selectedCacheServer.value?.length > 1"
                                class="example-additional-selection">
                                (+{{affilateForm.controls.selectedCacheServer.value.length - 1}}
                                {{affilateForm.controls.selectedCacheServer.value?.length === 2 ? 'other' : 'others'}})
                            </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let cs of cache_server" [value]="cs">{{cs.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-select-autocomplete style = "position:relative; top:-10px;" fxFlex [placeholder]="'Channel Management List'" [errorMsg]="errorMessage"
                    [showErrorMsg]="showError" [options]="channel_management_lists" [multiple]='true' [labelCount]="2"
                    [selectedOptions]="selected_list" (selectionChange)="getSelectedOptions($event)">
                </mat-select-autocomplete>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex>
                    <input matInput placeholder="Agent ID" formControlName="agent_id">
                </mat-form-field>

                <mat-form-field fxFlex>
                    <input type="number" matInput placeholder="Agent Contact" formControlName="agent_contact_id">
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">

                <mat-form-field fxFlex>
                    <mat-select placeholder="Default Hold Time" formControlName="default_hold_time_id">
                        <mat-option *ngFor="let ht of hold_time_list" [value]="ht.id">
                            {{ht.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-select placeholder="Max Hold Time" formControlName="max_hold_time_id">
                        <mat-option *ngFor="let ht of hold_time_list" [value]="ht.id">
                            {{ht.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <input matInput type="number" placeholder="Payment Delay Minutes"
                        formControlName="payment_delay_minutes">
                </mat-form-field>
                <mat-form-field fxFlex>
                    <input matInput type="text" placeholder="UTM Source" formControlName="utm_source">
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex>
                    <mat-select placeholder="Connector" formControlName="connector_id"
                        (selectionChange)="onConnectorFilter($event.value)">
                        <mat-option *ngFor="let connector of connectors" [value]="connector.id">{{connector.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-select placeholder="Company" formControlName="company_id"
                        (selectionChange)="onCompanyFilter($event.value)">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-select placeholder="Sales Group" formControlName="sales_centre_id">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let finance_centre of finance_centres" [value]="finance_centre.id">
                            {{finance_centre.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="80px" fxLayoutGap.lt-md="0px">
                <section>
                    <mat-checkbox class="amd-card-content" formControlName="allow_price_override">Allow Price Override
                    </mat-checkbox>
                    <mat-checkbox class="amd-card-content" formControlName="send_itinerary_agency">Send Itinerary Agency
                    </mat-checkbox>
                    <mat-checkbox class="amd-card-content" formControlName="send_itinerary_only">Send Itinerary Only
                    </mat-checkbox>
                    <mat-checkbox class="amd-card-content" formControlName="allow_confirmed_cancellation">Allow
                        Confirmed Cancellation</mat-checkbox>
                    <mat-checkbox class="amd-card-content" formControlName="enable_production">Enable Production
                    </mat-checkbox>
                    <mat-checkbox class="amd-card-content" formControlName="white_label">White Label</mat-checkbox>
                </section>
            </div>
        </form>
    </mat-card-content>
    <!--Actions Control  -->
    <mat-card-actions align="center" *ngIf="!showSearchBlock">
        <div class="amd-card-content">
            <button mat-raised-button color="accent" (click)="onAdd_Update_Affiliate_Submit()">
                <mat-icon>{{updateButton ? "edit" : "add"}}</mat-icon> {{updateButton ? "Update Affiliate" : "Add
                Affiliate"}}
            </button>
            <button mat-raised-button color="accent" (click)="rest_AffiliateForm()">
                <mat-icon>cancel</mat-icon> Cancel
            </button>
        </div>
    </mat-card-actions>

</mat-card>
