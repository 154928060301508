import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Json_Viewer_Dialog_Compnent } from "../.././../../material_ui/components/UI_Dialogs/Json_Viewer_Dialog/Json_Viewer_Dialog";

import { audit_booking } from '../../objects/Audit/audit_booking';
import { affiliate } from '../../objects/Affiliate/affiliate';
import { pagination } from "../../../../system/objects/pagination";

import { Audit_Booking_Service } from '../../services/Audit/Audit_Booking';



@Component({
    selector: 'audit_booking',
    templateUrl: './audit_booking.html'
})
export class Component_Audit_Booking implements OnInit {

    audit_booking_list: audit_booking[];
    pagination_audit_booking: pagination = new pagination();
    affiliates: affiliate[] = [];
    displayedColumns: string[] = ['affiliate', 'upload_date', 'response_time', 'method', 'action'];
    time_period_list: string[] = [null, 'Last Hour', 'Last 24 Hours', 'Last 3 Days', 'Last Week', 'Last Month', 'Last Year'];
    http_method_list: string[] = [null, 'GET', 'POST', 'DELETE', 'PUT'];

    // Form & Controls Variables & Validation
    audit_booking: audit_booking;
    app_id_data: string;
    time_period_data: string;
    http_method_data: string;
    date_from_data: string;
    date_to_data: string;
    app_id_control = new UntypedFormControl();
    time_period_control = new UntypedFormControl();
    http_method_control = new UntypedFormControl();
    date_from_control = new UntypedFormControl();
    date_to_control = new UntypedFormControl();
    upload_data_json;
    response_data_json;
    process_data_json;
    response_process_data_json;

    // Flags
    showSearchBlock: boolean = true;


    constructor(private Audit_Booking_Service: Audit_Booking_Service, private dialog: MatDialog) { }

    ngOnInit() {
        // Set Default Time
        this.time_period_control.setValue(this.time_period_data = "Last 24 Hours");
        // Get Affiliate List
        this.Audit_Booking_Service.getAffiliate_List().subscribe(res => {
            this.affiliates = res["affiliate"];
        })
        // Get Audit bookings
        this.getBackend_Data_Audit_Booking(null, null, this.app_id_data, this.http_method_data, this.getTimePeriod(this.time_period_data), this.date_to_data);
    }

    onClick_Audit_Booking_Pagination(e: any) {
        if (this.date_from_data == null) {
            this.date_from_data = this.getTimePeriod(this.time_period_data);
        }
        this.getBackend_Data_Audit_Booking(e.pageIndex + 1, e.pageSize, this.app_id_data, this.http_method_data, this.date_from_data, this.date_to_data);
    }

    getBackend_Data_Audit_Booking(page: number, pageSize: number, app_id: string, http_method: string, datefrom: string, dateTo: string) {
        this.Audit_Booking_Service.getAutid_Booking(page, pageSize, app_id, http_method, datefrom, dateTo).subscribe((res) => {
            this.audit_booking_list = res.body['audit_booking'];
            this.pagination_audit_booking = res.body['pagination'];
        });
    }

    getTimePeriod(period: string) {

        var datePipe = new DatePipe("en-gb");
        var today = new Date();

        switch (period) {

            case "Last Hour": {
                return datePipe.transform(new Date(today.getTime() - (1000 * 60 * 60)), "yyyy-MM-dd HHmm");
            }
            case "Last 24 Hours": {
                return datePipe.transform(new Date().setDate(today.getDate() - 1), "yyyy-MM-dd HHmm");
            }
            case "Last 3 Days": {
                return datePipe.transform(new Date().setDate(today.getDate() - 3), "yyyy-MM-dd HHmm");
            }
            case "Last Week": {
                return datePipe.transform(new Date().setDate(today.getDate() - 7), "yyyy-MM-dd HHmm");
            }
            case "Last Month": {
                return datePipe.transform(new Date().setMonth(today.getMonth() - 1), "yyyy-MM-dd HHmm");
            }
            case "Last Year": {
                return datePipe.transform(new Date().setFullYear(today.getFullYear() - 1), "yyyy-MM-dd HHmm");
            }
            default: {
                return null;
            }
        }
    }

    onFilterResultRest() {
        // Assign form control values to varibales 
        this.app_id_data = this.app_id_control.value;
        this.time_period_data = this.time_period_control.value;
        this.http_method_data = this.http_method_control.value;
        this.date_from_data = new DatePipe("en-gb").transform(this.date_from_control.value, "yyyy-MM-dd")
        this.date_to_data = new DatePipe("en-gb").transform(this.date_to_control.value, "yyyy-MM-dd")

        if (this.date_from_data == null) {
            this.date_from_data = this.getTimePeriod(this.time_period_data);
        }
        this.getBackend_Data_Audit_Booking(null, null, this.app_id_data, this.http_method_data, this.date_from_data, this.date_to_data);
    }

    onAudit_Booking_View(selected_affiliate: audit_booking) {

        // Bind Variables 
        this.audit_booking = selected_affiliate;
        this.upload_data_json = JSON.parse(this.audit_booking.upload_data);
        this.response_data_json = JSON.parse(this.audit_booking.response_data);
        this.process_data_json = JSON.parse(this.audit_booking.processed_data);
        this.response_process_data_json = JSON.parse(this.audit_booking.response_processed_data);

        // Update Flag
        this.showSearchBlock = false;
    }
    onBackPage() {
        this.showSearchBlock = true;
    }

    onViewJson(dialogTitle: string, jsonValue: string) {
        if (jsonValue != null) {
            this.dialog.open(Json_Viewer_Dialog_Compnent, {
                height: '600px',
                width: '600px',
                data: {
                    title: dialogTitle,
                    jsonData: jsonValue
                }
            });

        }
    }





}
