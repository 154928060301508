import { Injectable } from '@angular/core';

import { Environment_Service } from "../../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../../system/services/Response_Service";

@Injectable()
export class Audit_Identity_Service {

    constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

    getAutid_Activity(page_number: number, page_size: number, id: string, type: number, user_id: number, datefrom: string, dateTo: string) {
        var search_options: string[] = [];

        if (id != null || type != null || datefrom != null || dateTo != null) {
            var search_filters: string[] = []

            if (id != null) {
                search_filters.push("id:(" + id + ")");
            }

            if (type != null) {
                search_filters.push("type:(" + type + ")");
            }

            if (user_id != null) {
                search_filters.push("user_id:(" + user_id + ")");
            }

            if (datefrom != null) {
                search_filters.push("activity_date_from:(" + datefrom + ")");
            }

            if (dateTo != null) {
                search_filters.push("activity_date_to:(" + dateTo + ")");
            }

            var search_filter: string = "";

            for (var index = 0; index < search_filters.length; index++) {
                search_filter = search_filter + ((index == 0) ? "" : " and ") + search_filters[index];
            }

            if (search_filter != "") {
                search_options.push("filter=" + search_filter);
            }
        }
        var search_option: string = "";

        for (var index = 0; index < search_options.length; index++) {
            search_option = search_option + search_options[index];
        }


        if (page_number != null && page_size != null) {
            return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/audit/activity?page_number=" + page_number + "&page_size=" + page_size + "&" + search_option);
        }

        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/audit/activity?" + search_option);

    }

    getAutid_Error(page_number: number, page_size: number, id: string, type: number, user_id: number, datefrom: string, dateTo: string) {
        var search_options: string[] = [];

        if (id != null || type != null || datefrom != null || dateTo != null) {
            var search_filters: string[] = []

            if (id != null) {
                search_filters.push("id:(" + id + ")");
            }

            if (type != null) {
                search_filters.push("type:(" + type + ")");
            }

            if (user_id != null) {
                search_filters.push("user_id:(" + user_id + ")");
            }

            if (datefrom != null) {
                search_filters.push("error_date_from:(" + datefrom + ")");
            }

            if (dateTo != null) {
                search_filters.push("error_date_to:(" + dateTo + ")");
            }

            var search_filter: string = "";

            for (var index = 0; index < search_filters.length; index++) {
                search_filter = search_filter + ((index == 0) ? "" : " and ") + search_filters[index];
            }

            if (search_filter != "") {
                search_options.push("filter=" + search_filter);
            }
        }
        var search_option: string = "";

        for (var index = 0; index < search_options.length; index++) {
            search_option = search_option + search_options[index];
        }


        if (page_number != null && page_size != null) {
            return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/audit/error?page_number=" + page_number + "&page_size=" + page_size + "&" + search_option);
        }

        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/audit/error?" + search_option);

    }

    getActivityTypes(name: string) {
        if (name != "") {
            return this.Response_Service.getResponse(this.Environment_Service.environment.identity_server + "/api/v1/audit/activity_type?name=" + name);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.identity_server + "/api/v1/audit/activity_type");
    }

    getErrorTypes(name: string) {
        if (name != "") {
            return this.Response_Service.getResponse(this.Environment_Service.environment.identity_server + "/api/v1/audit/error_type?name=" + name);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.identity_server + "/api/v1/audit/error_type");
    }
}
