<mat-sidenav-container fullscreen>
  <!-- Left Side Nav -->
  <mat-sidenav #leftSideNav class="sidenav" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">
    <div class="no-v-scroll">
      <!-- Corner Logo -->
      <mat-toolbar color="primary" class="mat-elevation-z4">
        <a class="logo" href=""></a>
      </mat-toolbar>
      <!-- Side Menu Components -->
      <mat-sidemenu [menus]="navItems"></mat-sidemenu>
    </div>
  </mat-sidenav>

  <!-- CONTENT -->
  <mat-sidenav-content style="z-index: unset;overflow: hidden;">
    <mat-drawer-container>
      <mat-drawer-content style="z-index: unset;overflow: hidden;">
        <!-- Top Toolbar Component -->
        <mat-top-toolbar [appTitle]="title.getTitle()"></mat-top-toolbar>
        <!-- Progress Bar -->
        <mat-progress-bar *ngIf="showProgress" mode="indeterminate"></mat-progress-bar>
        <!-- Sontent  -->
        <perfect-scrollbar class="content-scroll">
          <router-outlet></router-outlet>
        </perfect-scrollbar>
      </mat-drawer-content>
    </mat-drawer-container>

  </mat-sidenav-content>

  <!-- Right Side Nav -->
  <mat-sidenav #rightSideNav position="end" class="right-sidenav">
    <!-- SideBar Compoment -->
    <mat-sidebar></mat-sidebar>
  </mat-sidenav>


</mat-sidenav-container>