import { Injectable } from '@angular/core';

import { user } from "../../../objects/maintenance/User/User";

import { Environment_Service } from "../../../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../../../system/services/Response_Service";

@Injectable()
export class User_Service {

  constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

  getUser_List(paged_url: string) {

    if (paged_url != null) {
      return this.Response_Service.getResponse_Paged(paged_url);
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/user");
  }
  getUsers(page_number:number,page_size:number, Search: string) {

    if (page_number != null && page_size != null) {
      return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/user?page_number="+ page_number +"&page_size=" + page_size);
    }

    var search_options: string[] = [];

    if (Search != "") {
      search_options.push("search=" + Search);
    }


    var search_option: string = "";

    for (var index = 0; index < search_options.length; index++) {
      search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/user" + search_option);
  }

  updateUser(userId: number, new_user: user) {
    return this.Response_Service.put_Data(this.Environment_Service.environment.identity_server + "/api/v1/user/" + userId, new_user);
  }
  addUser(new_user: user) {
    return this.Response_Service.post_Data(this.Environment_Service.environment.identity_server + "/api/v1/user/", new_user);
  }
  deleteUser(user: user) {
    return this.Response_Service.delete_Data(this.Environment_Service.environment.identity_server + "/api/v1/user/" + user.id);
  }
  getUserByID(paged_url: string, user_id: number) {
    if (paged_url != null) {
      return this.Response_Service.getResponse_Paged(paged_url);
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/user/" + user_id);
  }
}
