import { NgModule } from '@angular/core';
import { Module_System } from '../../../../../system/system.module';

import { Component_External_API_Sync } from './api_server_sync/api_server_sync';

@NgModule({
  imports: [
    Module_System,
  ],
  declarations: [
    Component_External_API_Sync
      ],
  exports: [
    Component_External_API_Sync
  ]
})
export class HomeWidgetModule { }