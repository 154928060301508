import { Component, ElementRef, HostListener } from '@angular/core';

import { Environment_Service } from '../../../../system/services/Environment_Service';
import { Helper_Service } from '../../../services/Core/Helper_Service';
import { Permissions_Service } from '../../../../system/services/Permissions_Service';


@Component({
    selector: 'mat-app-switch',
    templateUrl: './App_Switch.html',
    styleUrls: ['./App_Switch.scss']
})
export class App_Switch_Component {

    apps: any[] = [];
    isOpen: boolean = false;
    @HostListener('document:click', ['$event', '$event.target'])

    onClick(event: MouseEvent, targetElement: HTMLElement) {
        if (!targetElement) {
            return;
        }
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.isOpen = false;
        }
    }

    constructor(private elementRef: ElementRef, private Environment_Service: Environment_Service,
        private Helper_Service: Helper_Service, private Permissions_Service: Permissions_Service) { }

    ngOnInit() {
        this.Helper_Service.getDataFromLocalJsonFile("../assets/app_switch.json").subscribe((res: any[]) => {
            var env = res.find(x => x.environment_name === this.Environment_Service.environment.environment_name);
            if (env != undefined) {
                this.apps = env.apps;
            }
        });
    }
}
