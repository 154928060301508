import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorage_Service {

  private KEY_ACCESSTOKEN: string = 'AccessToken';

  constructor() { }

  private setData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  private getData(key: string) {
    return localStorage.getItem(key)
  }
  private removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  public setAccessToken(token: string) {
    this.setData(this.KEY_ACCESSTOKEN, token);
  }

  public getAccessToken() : string {
    return this.getData(this.KEY_ACCESSTOKEN);
  }

  public removeAccessToken() {
    this.removeData(this.KEY_ACCESSTOKEN);
  }

}

