import { Injectable } from '@angular/core';

import { Date_Service } from "./Date_Service";

import { calendar_month } from "../../objects/System/Calendar_Month";
import { calendar_week } from "../../objects/System/Calendar_Week";
import { calendar_day } from "../../objects/System/Calendar_Day";

@Injectable()
export class Calendar_Service {
  constructor(private Date_Service: Date_Service) {
  }

  build_month(start_date: Date): calendar_month {

    var monthIndex = start_date.getMonth();

    start_date.setDate(start_date.getDate() - (start_date.getDate() - 1));
    start_date = this.Date_Service.previous_monday(start_date);

    var calendar_month_output = new calendar_month();
    calendar_month_output.weeks = [];

    var done = false, date = start_date, count = 0;

    while (!done) {
      calendar_month_output.weeks.push(this.build_week(new Date(date), monthIndex));
      date.setDate(date.getDate() + 7);
      done = count++ > 2 && monthIndex !== date.getMonth();
      monthIndex = date.getMonth();
    }

    return calendar_month_output;
  };

  build_week(date: Date, current_month: number): calendar_week {
    var week: calendar_week = new calendar_week();
    week.days = [];

    for (var i = 0; i < 7; i++) {

      var day: calendar_day = new calendar_day();
      day.name = date.getDate().toString();
      day.number = date.getDate();
      day.is_current_month = date.getMonth() === current_month;
      day.is_today = this.Date_Service.is_today(date);
      day.date = this.Date_Service.string_date(date);
      day.output_date = this.Date_Service.string_date_output(date);

      week.days.push(day);

      date.setDate(date.getDate() + 1);
    }
    return week;
  };
}
