export class active_call {
    public guid: string;
    public start_time: number;
    public end_time: number;
    public call_url: string;
    public method: string;
    public processing_milliseconds: number;
    public error_code: string;
    public error_message: string;
    public status_code: number;
}
