import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Confirmation_Dialog_Compnent } from "../../.././../../material_ui/components/UI_Dialogs/Confirmation_Dialog/Confirmation_Dialog";

import { web_search_engine } from '../../../objects/Web_Search/web_search_engine';
import { web_search_engine_filter } from '../../../objects/Web_Search/web_search_engine_filter';
import { web_search_filter } from '../../../objects/Web_Search/web_search_filter';
import { cache_server } from '../../../objects/Type/cache_server';
import { web_search_engine_cache } from '../../../objects/Web_Search/web_search_engine_cache';
import { pagination } from "../../../../../system/objects/pagination";

import { Web_Engine_Service } from '../../../services/Web_Engine/Web_Engine';
import { Web_Filter_Service } from '../../../services/Web_Search_Filter/Web_Filter';
import { Cache_Server_Service } from '../../../services/Type_Cache_Server/Cache_Server';
import { Helper_Service } from '../../../../../material_ui/services/Core/Helper_Service';

@Component({
  selector: 'web_engine',
  templateUrl: './web_engine.html'
})
export class Component_Web_Engine {

  //Component Vaiables
  web_engine_header: string = "";
  web_engine_list: web_search_engine[];
  pagination_web_engine: pagination = new pagination();
  pagination_web_engine_filter: pagination = new pagination();
  pagination_cache_server: pagination = new pagination();
  web_filter_result: web_search_filter[] = [];
  cache_server_result: cache_server[] = [];
  displayedColumns: string[] = ['name', 'edit'];

  // Form & Controls Variables & Validation
  web_engine_id: number;
  web_engine_name = new UntypedFormControl('', [Validators.required]);
  //Web Engine Filter Lists
  web_search_engine_filter: web_search_engine_filter[] = [];
  availableWebEngineFilters: any[] = [];
  selectedWebEngineFilters: any[] = [];
  //Cache Server Lists
  web_search_engine_cache: web_search_engine_cache[] = []
  availableCacheServers: any[];
  selectedCacheServers: any[];
  // Flags
  updateButton: boolean = false;
  showSearchBlock: boolean = true;

  constructor(private Web_Engine_Service: Web_Engine_Service, private Web_Filter_Service: Web_Filter_Service, private helper_service: Helper_Service, private dialog: MatDialog, private Cache_Server_Service: Cache_Server_Service) { }

  ngOnInit() {
    this.getBackend_Data_Web_Engine(null, null);
    this.getBackend_Data_Web_Engine_Filters(null, null);
    this.getBackend_Data_Cache_Servers(null, null);
  }

  onClick_Web_Engine_Pagination(e: any) {
    this.getBackend_Data_Web_Engine(e.pageIndex + 1, e.pageSize);
  }
  getBackend_Data_Web_Engine(page: number, pageSize: number) {
    this.Web_Engine_Service.getWeb_Engine_List(page, pageSize).subscribe((res) => {
      this.web_engine_list = res.body['web_search_engine'];
      this.pagination_web_engine = res.body['pagination'];
    });
  }
  onClick_Web_Engine_Filter_Pagination(e: any) {
    this.getBackend_Data_Web_Engine_Filters(e.pageIndex + 1, e.pageSize);
  }
  onClick_Web_Engine_Filter_Search(value: string) {
    this.Web_Filter_Service.getWeb_Filters(null, value).subscribe((res) => {
      this.web_filter_result = res.body['web_search_filter'];
      this.pagination_web_engine_filter = res.body['pagination'];
      this.availableWebEngineFilters = [];
      (res.body["web_search_filter"]).forEach(obj => {
        this.availableWebEngineFilters.push({ id: obj.id.toString(), name: obj.name, selected: false });
      });
    });
  }
  getBackend_Data_Web_Engine_Filters(page: number, pageSize: number) {
    this.Web_Filter_Service.getWeb_Filter_List(page, pageSize).subscribe((res) => {
      this.web_filter_result = res.body['web_search_filter'];
      this.pagination_web_engine_filter = res.body['pagination'];
      this.availableWebEngineFilters = [];
      (res.body["web_search_filter"]).forEach(obj => {
        this.availableWebEngineFilters.push({ id: obj.id.toString(), name: obj.name, selected: false });
      });
    });
  }

  map_Data_Web_Engine_Filters_Selected() {
    this.selectedWebEngineFilters = [];
    this.web_search_engine_filter.forEach(obj => {
      this.selectedWebEngineFilters.push({ id: obj.filter_id.toString(), name: obj.web_search_filter.name, selected: true })
    });
  }
  onAddWebEngineFilter(item: any) {
    var number_filter_id: number = +item.id;
    this.web_search_engine_filter.push({ engine_id: this.web_engine_id, web_search_engine: null, filter_id: number_filter_id, web_search_filter: null });
  }
  onRemoveWebEngineFilter(item: any) {
    this.web_search_engine_filter = this.web_search_engine_filter.filter(x => x.filter_id.toString() !== item.id);
  }

  getBackend_Data_Cache_Servers(page: number, pageSize: number){
    this.Cache_Server_Service.getCache_Server_List(page, pageSize).subscribe((res) => {
      this.cache_server_result = res.body['cache_server'];
      this.pagination_cache_server = res.body['pagination'];
      this.availableCacheServers = [];
      (res.body["cache_server"]).forEach(obj => {
        this.availableCacheServers.push({ id: obj.app_id.toString(), name: obj.name, selected: false });
      });
    });
  }

  onClick_Cache_Server_Pagination(e: any) {
    this.getBackend_Data_Cache_Servers(e.pageIndex + 1, e.pageSize);
  }

  onClick_Cache_Server_Search(value: string) {
    this.Cache_Server_Service.getCache_Servers(null, value).subscribe((res) => {
      this.cache_server_result = res.body['cache_server'];
      this.pagination_cache_server = res.body['pagination'];
      this.availableCacheServers = [];
      (res.body["cache_server"]).forEach(obj => {
        this.availableCacheServers.push({ id: obj.app_id.toString(), name: obj.name, selected: false });
      });
    });
  }

  map_Data_Cache_Servers_Selected() {
    this.selectedCacheServers = [];
    this.web_search_engine_cache.forEach(obj => {
      this.selectedCacheServers.push({ id: obj.cache_server_id, name: obj.cache_server.name, selected: true })
    });
  }

  onAdd_Cache_Server(item: any) {
    this.web_search_engine_cache.push({  engine_id: this.web_engine_id, web_search_engine: null, cache_server_id: item.id, cache_server: null });
  }

  onRemove_Cache_Server(item: any) {
    this.web_search_engine_cache = this.web_search_engine_cache.filter(x => x.cache_server_id.toString() !== item.id);
  } 

  onAdd_Web_Engine() {
    // Bind Variables 
    this.web_engine_header = "Enter Details To Add a New Web Serach Engine:"
    this.showSearchBlock = false;
  }

  onEdit_Web_Engine(selected_Web_Engine: web_search_engine) {
    // Bind Variables 
    this.web_engine_header = "Edit Details: " + selected_Web_Engine.name;
    this.web_engine_id = selected_Web_Engine.id;
    //this.web_engine_name = selected_Web_Engine.name;
    this.web_engine_name.setValue(selected_Web_Engine.name);
    this.web_search_engine_filter = selected_Web_Engine.web_search_engine_filters;
    this.web_search_engine_cache = selected_Web_Engine.web_search_engine_caches;
    // Update Flag
    this.updateButton = true;
    this.showSearchBlock = false;
    // Call Local Funtion
    this.map_Data_Web_Engine_Filters_Selected();
    this.map_Data_Cache_Servers_Selected();
  }

  onDelete_Web_Engine(selected_Web_Engine: web_search_engine) {

    const dialogRef = this.dialog.open(Confirmation_Dialog_Compnent, {
      data: { title: "Delete !", text: "Are you sure you want to delete " + selected_Web_Engine.name + "?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        // call delete service
        this.Web_Engine_Service.deleteWeb_Engine(selected_Web_Engine).subscribe(
          (response) => { this.helper_service.openSnackbarNotification(selected_Web_Engine.name + ' has been deleted successfully', 'Close', 'success'); },
          (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
          () => { this.rest_WebEngineForm() }
        );
      }
    });
  }

  onAdd_Update_Web_Engine_Submit() {

    var addUpdateWebEngine = new web_search_engine;
    addUpdateWebEngine.id = this.web_engine_id;
    addUpdateWebEngine.name = this.web_engine_name.value;
    addUpdateWebEngine.web_search_engine_filters = this.web_search_engine_filter;
    addUpdateWebEngine.web_search_engine_caches = this.web_search_engine_cache;


    if (this.web_engine_name.valid) {
      //  Update Web Engine
      if (this.updateButton) {
        this.Web_Engine_Service.updateWeb_Engine(addUpdateWebEngine).subscribe(
          (response) => { this.helper_service.openSnackbarNotification('You have successfully updated the web engine', 'Close', 'success'); },
          (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
          () => { this.rest_WebEngineForm() }
        );
      }
      // Create New Web Engine
      else {
        this.Web_Engine_Service.addWeb_Engine(addUpdateWebEngine).subscribe(
          (response) => { this.helper_service.openSnackbarNotification('You have successfully added the web engine', 'Close', 'success'); },
          (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
          () => { this.rest_WebEngineForm() }
        );
      }
    }
    else {
      this.web_engine_name.markAsTouched();
    }


  }
  rest_WebEngineForm() {
    this.getBackend_Data_Web_Engine(null, null);
    this.getBackend_Data_Web_Engine_Filters(null, null);
    this.getBackend_Data_Cache_Servers(null, null);

    this.showSearchBlock = true;
    this.updateButton = false;
    this.web_engine_header = "";
    this.web_engine_id = 0;
    this.web_engine_name.reset();
    this.web_search_engine_filter = [];
    this.availableWebEngineFilters = [];
    this.selectedWebEngineFilters = [];
    this.web_search_engine_cache = [];
    this.availableCacheServers = [];
    this.selectedCacheServers = [];
  }

}
