import { connector } from '../Type/connector';
import { affiliate_hold_time } from "./affiliate_hold_time"
import { cache_server } from '../Type/cache_server';
export class affiliate {
    public app_id: string;
    public api_key: string;
    public name: string;
    public allow_price_override: boolean;
    public payment_delay_minutes?: number;
    public connector_id: number
    public connector: connector;
    public agent_id: string;
    public agent_contact_id?: number;
    public sales_centre_id?: number;
    public allow_confirmed_cancellation: boolean;
    public default_hold_time_id: number;
    public default_hold_time: affiliate_hold_time;
    public max_hold_time_id: number;
    public max_hold_time: affiliate_hold_time;
    public enable_production: boolean;
    public utm_source: string;
    public white_label: boolean;
    public send_itinerary_only: boolean;
  public send_itinerary_agency: boolean;
    public selected_cache_servers: cache_server[];
}
