<mat-card class="amd-card">
  <mat-card-header class="amd-card-header">
    <mat-card-title>
      <h5>
        <mat-icon>web</mat-icon> Web Engine
      </h5>
    </mat-card-title>
    <mat-card-title>
      <h6>{{web_engine_header}}</h6>
    </mat-card-title>
    <mat-doc-component class="amd-card-help-button" [docLink]="'web-engine'"></mat-doc-component>
  </mat-card-header>
  <!-- Main page content -->
  <mat-card-content class="amd-card-content" *ngIf="showSearchBlock">
    <!-- Add new record -->
    <div class="amd-corner-button" style="margin-top: 20px;">
      <button mat-fab color="accent" (click)="onAdd_Web_Engine()">
        <mat-icon matTooltip="Add Web Engine">add</mat-icon>
      </button>
    </div>
    <!-- Search Record -->
    <div class="mat-elevation-z8">
      <!-- Filter Database results -->
      <div fxLayout="row">
        <div>
          <mat-input-debounce placeholder="Web Engine Search..." (value)="onWeb_Engine_Search($event)" style="padding:20px">
          </mat-input-debounce>
        </div>
      </div>
      <!-- Result table -->
      <table class="amd-table mat-elevation-z8" mat-table [dataSource]="web_engine_list">
        <!-- Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Web Engine Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <!-- Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
          <td mat-cell *matCellDef="let element">
            <div fxLayout="row" fxLayoutGap="10px">
              <button color="accent" mat-mini-fab (click)="onEdit_Web_Engine(element)">
                <mat-icon matTooltip="Edit">edit</mat-icon>
              </button>
              <button color="warn" mat-mini-fab (click)="onDelete_Web_Engine(element)">
                <mat-icon matTooltip="Delete">delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <!-- Table Header -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <!-- Pagination -->
      <mat-paginator [length]="pagination_web_engine.total_count" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"
        (page)="onClick_Web_Engine_Pagination($event)">
      </mat-paginator>
    </div>

  </mat-card-content>
  <!-- Add or Update page content -->
  <mat-card-content class="amd-card-content" *ngIf="!showSearchBlock">
    <!-- Form Fields -->
    <mat-form-field class="amd-form_field">
      <input matInput placeholder="Web Engine Name" [formControl]="web_engine_name">
      <mat-error *ngIf="web_engine_name.invalid">You must enter a value</mat-error>
    </mat-form-field>
    <!-- Header -->
    <h3 style="text-align: center">Web Engine Filters</h3>
    <!-- Data List -->
    <div style="margin-bottom: 25px;">
      <mat-dual_list fxFlex [availableItems]="availableWebEngineFilters" [(selectededItems)]="selectedWebEngineFilters"
        [availableItemsPagination]="pagination_web_engine_filter" (availableItemsPage)="onClick_Web_Engine_Filter_Pagination($event)"
        (availableSearch)=onClick_Web_Engine_Filter_Search($event) (add)="onAddWebEngineFilter($event)" (remove)="onRemoveWebEngineFilter($event)">
      </mat-dual_list>
    </div>
    <!-- Header -->
    <h3 style="text-align: center">Cache Servers</h3>
    <!-- Data List -->
    <div>
      <mat-dual_list fxFlex [availableItems]="availableCacheServers" [(selectededItems)]="selectedCacheServers"
        [availableItemsPagination]="pagination_cache_server" (availableItemsPage)="onClick_Cache_Server_Pagination($event)"
        (availableSearch)=onClick_Cache_Server_Search($event) (add)="onAdd_Cache_Server($event)" (remove)="onRemove_Cache_Server($event)">
      </mat-dual_list>
    </div>
  </mat-card-content>
  <!--Actions Control  -->
  <mat-card-actions align="center" *ngIf="!showSearchBlock">
    <div class="amd-card-content">
      <button mat-raised-button color="accent" (click)="onAdd_Update_Web_Engine_Submit()">
        <mat-icon>{{updateButton ? "edit" : "add"}}</mat-icon> {{updateButton ? "Update Web Engine" : "Add Web
        Engine"}}
      </button>
      <button mat-raised-button color="accent" (click)="rest_WebEngineForm()">
        <mat-icon>cancel</mat-icon> Cancel
      </button>
    </div>
  </mat-card-actions>
</mat-card>