import { Injectable } from '@angular/core';

import { web_search_filter } from "../../../gateway/objects/Web_Search/web_search_filter";

import { Environment_Service } from "../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../system/services/Response_Service";

@Injectable()
export class Web_Filter_Service {

  constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }
 
  getWeb_Filter_List(page_number:number,page_size:number) {

    if (page_number != null && page_size != null) {
      return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_filter?page_number="+ page_number +"&page_size=" + page_size);
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_filter");
  }

  getWeb_Filters(paged_url: string, Search: string) {
    if (paged_url != null) {
      return this.Response_Service.getResponse_Paged(paged_url);
    }

    var search_options: string[] = [];

    if (Search != "") {
      search_options.push("search=" + Search);
    }
    

    var search_option: string = "";

    for (var index = 0; index < search_options.length; index++) {
      search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_filter" + search_option);
  }

  updateWeb_Filter(web_filter: web_search_filter) {
    return this.Response_Service.put_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_filter", web_filter);
  }

  addWeb_Filter(new_web_filter: web_search_filter) {
    return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_filter", new_web_filter);
  }

  deleteWeb_Filter(web_filter: web_search_filter) {
    return this.Response_Service.delete_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/web_search_filter/" + web_filter.id);
  }
}