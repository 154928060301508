import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { affiliate } from '../../../objects/Affiliate/affiliate';
import { company_finance_centre } from '../../../objects/External/company_finance_centre';
import { connector } from '../../../objects/Type/connector';
import { affiliate_hold_time } from '../../../objects/Affiliate/affiliate_hold_time';
import { cache_server } from '../../../objects/Type/cache_server';
import { pagination } from "../../../../../system/objects/pagination";
import { affiliate_cache } from '../../../objects/Affiliate/affiliate_cache';
import { affiliate_channel_management_list } from '../../../objects/Affiliate/affiliate_channel_management_list';
import { product } from '../../../../booking_engine/objects/v1_2/Web_Engine/Product/Product';
import { product_info } from '../../../objects/External/product_info';

import { Affiliate_Service } from '../../../services/Affiliate/Affiliate';
import { Helper_Service } from '../../../../../material_ui/services/Core/Helper_Service';
import { Product_Selection_Service } from '../../../services/Comman/Product_Selection/Product_Selection';
import { List_Management_Service } from '../../../services/Channel_Management/List_Management/List';

@Component({
    selector: 'affiliate',
    templateUrl: './affiliate.html'
})
export class Component_Affiliate {

    affilateForm: UntypedFormGroup;

    affiliate_header: string = "";
    search_text: string = "";
    affiliate_list: affiliate[];
    company_finance_centre_list: company_finance_centre[];
    hold_time_list: affiliate_hold_time[];
    pagination_affiliate: pagination = new pagination();
    cache_server: cache_server[] = [];
    connectors: connector[] = [];
    companies: any[] = [];
    finance_centres: any[] = [];
    product_info: string = "";
    selected_company: string = null;
    selected_finance_centre: number = null;
    //Channel Management Variables
    channel_management_lists: any[];
    //selected_channel_management_list: any[] = [];
    selected_list: any[] = [];
    showError = false;
    errorMessage = 'You must enter a value';

    displayedColumns: string[] = ['connector', 'name', 'agent_id', 'edit'];
    //Affiliate Cache Control
    @ViewChild("cacheList") cacheList: MatSelect;

    // Form & Controls Variables
    affilate_app_id: string;
    affilate_api_key: string;

    // Flags
    updateButton: boolean = false;
    showSearchBlock: boolean = true;

    constructor(private Affiliate_Service: Affiliate_Service, private Helper_Service: Helper_Service,
        private Product_Selection_Service: Product_Selection_Service, private List_Management_Service: List_Management_Service) { }

    ngOnInit() {
        this.getBackend_Data_For_Controls();
        this.getBackend_Data_Affiliate(null, null);
    }

    onClick_Affiliate_Pagination(e: any) {
        this.getBackend_Data_Affiliate(e.pageIndex + 1, e.pageSize);
    }

    getBackend_Data_For_Controls() {
        // Get Api Info
        this.Affiliate_Service.getApi_info(null).subscribe((res) => {
            this.affilate_api_key = res["api_key"];
            this.affilate_app_id = res["app_id"];
        });
        // Get Affiliate Hold Time List
        this.Affiliate_Service.getAffiliate_Hold_Time(null, null, true).subscribe((res) => {
            this.hold_time_list = res.body["affiliate_hold_time"];
        });
        // Get Affiliate Type Cache Server
        this.Affiliate_Service.getCache_Server(null).subscribe(res => {
            this.cache_server = <cache_server[]>res;
        });
        // Get Connector List
        this.Product_Selection_Service.getConnector_Type_List(null).subscribe((res: connector[]) => {
            this.connectors = <connector[]>res;
        });
        // Get Channel Management List
        this.List_Management_Service.getChannel_Management_List(null, null, "", true).subscribe(res => {
            this.channel_management_lists = res.body["list"];
        });

    }

    getBackend_Data_Affiliate(page: number, pageSize: number) {
        this.Affiliate_Service.getAffiliate_List(page, pageSize).subscribe((res) => {
            this.affiliate_list = res.body['affiliate'];
            this.pagination_affiliate = res.body['pagination'];
        });
    }

    onAffiliate_Search(value: string) {
        this.search_text = value;
        this.Affiliate_Service.getAffiliate(null, this.search_text).subscribe((res) => {
            this.affiliate_list = res.body['affiliate'];
            this.pagination_affiliate = res.body['pagination'];
        });
    }

    onAdd_Affiliate() {
        var affilate_data = new affiliate;

        // Bind Variables 
        this.affiliate_header = "Enter Details To Add a New Affiliate:"
        affilate_data.api_key = this.affilate_api_key;
        affilate_data.app_id = this.affilate_app_id;
        this.selected_company = null;
        this.selected_finance_centre = null;
        this.setAffiliateForm(affilate_data);

        // Update Flag
        this.showSearchBlock = false;
    }

    async onEdit_Affiliate(selected_affiliate: affiliate) {
        // Bind Variables 
        this.affiliate_header = "Edit Details: " + selected_affiliate.name;
        this.selected_company = null;
        this.selected_finance_centre = null;
        // Reset Variables before populating
        this.companies = [];
        this.finance_centres = [];

        // Set Company / Finance Centre dropdowns
        this.product_info = (<product>await this.Product_Selection_Service.getConnector_Product_List(null, selected_affiliate.connector_id).toPromise())[0].product_info;
        var productInfo: product_info = JSON.parse(this.product_info);
        productInfo.companies.forEach(c => {
            // Populate data for Company Filter DropDown
            this.companies.push({ id: Number(c.company_identifier), name: c.name });
            if (selected_affiliate.sales_centre_id != null) {
                // Find company for sales centre (finance centre)
                var foundFinanceCentre = c.finance_centres.find(x => x.finance_centre_identifier == selected_affiliate.sales_centre_id.toString())
                if (foundFinanceCentre != null) {
                    this.selected_company = c.company_identifier;
                    this.selected_finance_centre = selected_affiliate.sales_centre_id;
                    c.finance_centres.forEach(fc => {
                        this.finance_centres.push({ id: +fc.finance_centre_identifier, name: fc.name });
                    });
                }
            }
        });

        selected_affiliate.selected_cache_servers = [];
        this.Affiliate_Service.get_Affiliate_Cache(selected_affiliate.app_id).subscribe((res: affiliate_cache[]) => {
            res.forEach(obj => {
                this.cache_server.forEach(x => {
                    if (x.app_id == obj.cache_server_id) {
                        selected_affiliate.selected_cache_servers.push(x);
                    }
                });
            });
            this.setAffiliateForm(selected_affiliate);
        });

        //Channel Mangement List Get and Select on UI
        var res_list = <affiliate_channel_management_list[]>await this.Affiliate_Service.get_Affiliate_Channel_Management_List(selected_affiliate.app_id).toPromise();
        res_list.forEach(obj => {
            this.selected_list.push({ id: obj.list_id, name: obj.list.name });
        });

        // Update Flag
        this.updateButton = true;
        this.showSearchBlock = false;
    }

    onAdd_Update_Affiliate_Submit() {
        // Check Form Is Valid
        if (this.affilateForm.valid) {
            //  Update Affiliate
            if (this.updateButton) {
                this.Affiliate_Service.updateAffiliate(this.affilateForm.getRawValue()).subscribe(
                    (response) => {
                        // Add Affiliate Cache Data
                        var affiliate_cache: affiliate_cache[] = [];
                        this.affilateForm.controls["selectedCacheServer"].value.forEach(obj => {
                            affiliate_cache.push(Object.assign({ app_id: this.affilateForm.controls["app_id"].value, cache_server_id: obj.app_id }));
                        })
                        // Call to Save Data
                        this.Affiliate_Service.addUpdate_Affiliate_Cache(affiliate_cache, this.affilateForm.controls["app_id"].value).subscribe();

                        // Add Affiliate Channel Management List Data
                        var affiliate_channel_management_list: affiliate_channel_management_list[] = [];
                        this.selected_list.forEach(obj => {
                            affiliate_channel_management_list.push(Object.assign({ affiliate_id: this.affilateForm.controls["app_id"].value, list_id: obj.id }));
                        });
                        // Call to Save Data
                        this.Affiliate_Service.addUpdate_Affiliate_Channel_Management_List(affiliate_channel_management_list, this.affilateForm.controls["app_id"].value).subscribe();

                        this.Helper_Service.openSnackbarNotification('You have successfully updated the affiliate', 'Close', 'success');
                    },
                    (error) => { this.Helper_Service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
                    () => { this.rest_AffiliateForm() }
                );
            }
            // Create New Affiliate
            else {
                this.Affiliate_Service.addAffiliate(this.affilateForm.getRawValue()).subscribe(
                    (response) => {
                        // Add Affiliate Cache Data
                        var affiliate_cache: affiliate_cache[] = [];
                        this.affilateForm.controls["selectedCacheServer"].value.forEach(obj => {
                            affiliate_cache.push(Object.assign({ app_id: this.affilateForm.controls["app_id"].value, cache_server_id: obj.app_id }));
                        })
                        // Call to Save Data
                        this.Affiliate_Service.addUpdate_Affiliate_Cache(affiliate_cache, this.affilateForm.controls["app_id"].value).subscribe();

                        // Add Affiliate Channel Management List Data
                        var affiliate_channel_management_list: affiliate_channel_management_list[] = [];
                        this.selected_list.forEach(obj => {
                            affiliate_channel_management_list.push(Object.assign({ affiliate_id: this.affilateForm.controls["app_id"].value, list_id: obj.id }));
                        });
                        // Call to Save Data
                        this.Affiliate_Service.addUpdate_Affiliate_Channel_Management_List(affiliate_channel_management_list, this.affilateForm.controls["app_id"].value).subscribe();

                        this.Helper_Service.openSnackbarNotification('You have successfully added the affiliate', 'Close', 'success');
                    },
                    (error) => { this.Helper_Service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
                    () => { this.rest_AffiliateForm() }
                );
            }

        }
        else {
            const controls = this.affilateForm.controls;
            Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
        }
    }

    setAffiliateForm(affiliateModel: affiliate) {
        this.affilateForm = new UntypedFormGroup({
            app_id: new UntypedFormControl({ value: affiliateModel.app_id, disabled: true }),
            api_key: new UntypedFormControl({ value: affiliateModel.api_key, disabled: true }),
            name: new UntypedFormControl(affiliateModel.name, [Validators.required]),
            agent_id: new UntypedFormControl(affiliateModel.agent_id),
            agent_contact_id: new UntypedFormControl(affiliateModel.agent_contact_id),
            sales_centre_id: new UntypedFormControl((this.selected_finance_centre), [Validators.required]),
            connector_id: new UntypedFormControl(affiliateModel.connector_id, [Validators.required]),
            payment_delay_minutes: new UntypedFormControl(affiliateModel.payment_delay_minutes),
            allow_price_override: new UntypedFormControl(affiliateModel.allow_price_override == null ? false : affiliateModel.allow_price_override),
            allow_confirmed_cancellation: new UntypedFormControl(affiliateModel.allow_confirmed_cancellation == null ? false : affiliateModel.allow_confirmed_cancellation),
            default_hold_time_id: new UntypedFormControl(affiliateModel.default_hold_time_id),
            enable_production: new UntypedFormControl(affiliateModel.enable_production == null ? false : affiliateModel.enable_production),
            max_hold_time_id: new UntypedFormControl(affiliateModel.max_hold_time_id),
            utm_source: new UntypedFormControl(affiliateModel.utm_source),
            white_label: new UntypedFormControl(affiliateModel.white_label == null ? false : affiliateModel.white_label),
            send_itinerary_agency: new UntypedFormControl(affiliateModel.send_itinerary_agency == null ? false : affiliateModel.send_itinerary_agency),
            send_itinerary_only: new UntypedFormControl(affiliateModel.send_itinerary_only == null ? false : affiliateModel.send_itinerary_only),
            company_id: new UntypedFormControl(Number(this.selected_company)),
            selectedCacheServer:  new UntypedFormControl(affiliateModel.selected_cache_servers)
        });
    }

    rest_AffiliateForm() {
        this.showSearchBlock = true;
        this.updateButton = false;
        this.affiliate_header = "";
        this.search_text = "";
        this.affilate_api_key = "";
        this.affilate_app_id = "";
        this.affilateForm.reset();
        this.selected_company = null;
        this.selected_finance_centre = null;
        this.selected_list = [];
        // Reset Variables before populating
        this.companies = [];
        this.finance_centres = [];

        this.getBackend_Data_For_Controls();
        this.getBackend_Data_Affiliate(null, null);
    }

    onConnectorFilter(item: any) {
        // Get Contector Product Info from Backend
        this.Product_Selection_Service.getConnector_Product_List(null, item).subscribe((res: product) => {
            this.product_info = res[0].product_info;
            var productInfo: product_info = JSON.parse(this.product_info);

            // Reset Variables before populating
            this.companies = [];
            this.finance_centres = [];

            productInfo.companies.forEach(c => {
                // Populate data for Company Filter DropDown
                this.companies.push({ id: c.company_identifier, name: c.name });
            });
            this.selected_company = null;
            this.selected_finance_centre = null;
        });
    }

    onCompanyFilter(item: any) {
        var productInfo: product_info = JSON.parse(this.product_info);

        // Filter on company
        if (item != null) {
            this.selected_company = item;
            productInfo.companies.filter(company => company.company_identifier == this.selected_company);
        }

        // Reset Variables before populating
        this.finance_centres = [];

        //Rest selected filters
        this.selected_finance_centre = null;
        productInfo.companies.forEach(c => {
            if (c.company_identifier == this.selected_company) {
                c.finance_centres.forEach(fc => {
                    //Populate data for finiance centre Filter DropDown
                    this.finance_centres.push({ id: +fc.finance_centre_identifier, name: fc.name });
                });
            }
        });
    }

    getSelectedOptions(selected) {
        this.selected_list = selected;
    }
}
