import { Injectable } from '@angular/core';

import { audit_booking } from "../../../gateway/objects/Audit/audit_booking";

import { Environment_Service } from "../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../system/services/Response_Service";

@Injectable()
export class Audit_Booking_Service {

  constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

  getAutid_Booking(page_number: number, page_size: number, app_id: string, http_method: string, datefrom: string, dateTo: string) {
    var search_options: string[] = [];

    if (app_id != null || http_method != null || datefrom != null || dateTo != null) {
      var search_filters: string[] = []

      if (app_id != null) {
        search_filters.push("app_id:(" + app_id + ")");
      }

      if (http_method != null) {
        search_filters.push("method:(" + http_method + ")");
      }

      if (datefrom != null) {
        search_filters.push("upload_date_from:(" + datefrom + ")");
      }

      if (dateTo != null) {
        search_filters.push("upload_date_to:(" + dateTo + ")");
      }

      var search_filter: string = "";

      for (var index = 0; index < search_filters.length; index++) {
        search_filter = search_filter + ((index == 0) ? "" : " and ") + search_filters[index];
      }

      if (search_filter != "") {
        search_options.push("filter=" + search_filter);
      }
    }
    var search_option: string = "";

    for (var index = 0; index < search_options.length; index++) {
      search_option = search_option + search_options[index];
    }


    if (page_number != null && page_size != null) {
      return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/audit/booking?page_number=" + page_number + "&page_size=" + page_size + "&" + search_option);
    }

    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/audit/booking?" + search_option);

  }
  getAffiliate_List() {
    return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate?all_results=true");
  }
}
