import { Component } from '@angular/core';

import { user } from '../../objects/maintenance/User/User';

import { User_Service } from '../../services/maintenance/User/User';
import { OAuth_Service } from '../../../../../system/services/system/OAuth_Service';
import { Helper_Service } from '../../../../../material_ui/services/Core/Helper_Service';

export class profile {
    firstName: string;
    lastName: string;
    userName: string;
    jobTitle: string;
    photoUrl: string;
}

@Component({
    templateUrl: './User_Profile.html',
    styleUrls: ['./User_Profile.scss']
})
export class Component_User_Profile {

    user_info: user = new user;
    profile: profile = new profile;

    constructor(private User_Service: User_Service, private OAuth_Service: OAuth_Service, private Helper_Service: Helper_Service) { }

    ngOnInit() {
        this.getUserData();
    }
    
    getUserData() {
        this.User_Service.getUserByID(null, parseInt(this.OAuth_Service.identityClaims.user_id)).subscribe(res => {
            this.user_info = <user>res.body;

            this.profile.firstName = this.user_info.first_name;
            this.profile.lastName = this.user_info.last_name;
            this.profile.userName = this.user_info.user_name;
            if (this.user_info.photo_url == null || this.user_info.photo_url == "") {
                this.profile.photoUrl = "../assets/images/noavatar.png";
            }
            else {
                this.profile.photoUrl = this.user_info.photo_url;
            }
        });
    }
    updateProfile() {
        this.User_Service.updateUser(this.user_info.id, this.user_info).subscribe(
            (response) => { this.Helper_Service.openSnackbarNotification('You have successfully updated the profile', 'Close', 'success'); },
            (error) => { this.Helper_Service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
            () => { this.getUserData(); }
        );
    }
}

