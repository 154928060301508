<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>notes</mat-icon> Audit Booking
            </h5>
        </mat-card-title>
        <mat-doc-component class="amd-card-help-button" [docLink]="'audit'"></mat-doc-component>
    </mat-card-header>
    <!-- Main page content -->
    <mat-card-content class="amd-card-content" *ngIf="showSearchBlock">

        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="50px" fxLayoutGap.lt-lg="0px">

            <mat-form-field fxFlex>
                <mat-select placeholder="Affiliate" [formControl]="app_id_control">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option *ngFor="let item of affiliates" [value]="item.app_id">{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-select placeholder="Time Period" [formControl]="time_period_control">
                    <mat-option *ngFor="let item of time_period_list" [value]="item">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-select placeholder="HTTP Methods" [formControl]="http_method_control">
                    <mat-option *ngFor="let item of http_method_list" [value]="item">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field fxFlex>
                <input matInput [matDatepicker]="picker" placeholder="Date From" [formControl]="date_from_control">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex>
                <input matInput [matDatepicker]="picker2" placeholder="Date To" [formControl]="date_to_control">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px" fxLayoutAlign="center center"
            style="margin-bottom: 20px;">
            <div>
                <button mat-raised-button color="accent" (click)="onFilterResultRest()">
                    <mat-icon>filter_list</mat-icon> Filter Results
                </button>
            </div>

        </div>

        <!-- Result table -->
        <table class="amd-table mat-elevation-z8" mat-table [dataSource]="audit_booking_list">
            <!--Column -->
            <ng-container matColumnDef="affiliate">
                <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Affiliate </th>
                <td mat-cell *matCellDef="let element"> {{element.affiliate.name}} </td>
            </ng-container>
            <!--Column -->
            <ng-container matColumnDef="upload_date">
                <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Upload Date </th>
                <td mat-cell *matCellDef="let element"> {{element.upload_date | date: "yyyy-MM-dd HH:mm:ss"}}</td>
            </ng-container>
            <!-- Column -->
            <ng-container matColumnDef="response_time">
                <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Response Time(sec) </th>
                <td mat-cell *matCellDef="let element"> {{element.upload_date | timeDifference :
                    element.response_date}}
                </td>
            </ng-container>
            <!--Column -->
            <ng-container matColumnDef="method">
                <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Method </th>
                <td mat-cell *matCellDef="let element"> {{element.method}} </td>
            </ng-container>
            <!-- Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutGap="10px">
                        <button color="accent" mat-mini-fab (click)="onAudit_Booking_View(element)">
                            <mat-icon matTooltip="View">pageview</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <!-- Table Header -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!-- Pagination -->
        <mat-paginator [pageIndex]="pagination_audit_booking.current_page -1" [length]="pagination_audit_booking.total_count"
            [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" (page)="onClick_Audit_Booking_Pagination($event)" class="mat-elevation-z8">
        </mat-paginator>

    </mat-card-content>
    <!-- Detail page content -->
    <mat-card-content class="amd-card-content" *ngIf="!showSearchBlock">
        <!-- Flex Row -->
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">
            <!-- Form Fields -->
            <mat-form-field fxFlex>
                <input matInput placeholder="ID" [value]="audit_booking.id" readonly>
            </mat-form-field>

            <mat-form-field fxFlex>
                <input matInput placeholder="App ID" [value]="audit_booking.app_id" readonly>
            </mat-form-field>
        </div>
        <!-- Flex Row -->
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">

            <mat-form-field fxFlex>
                <input matInput placeholder="URL" [value]="audit_booking.url" readonly>
            </mat-form-field>

        </div>
        <!-- Flex Row -->
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex>
                <input matInput placeholder="Upload Date" [value]="audit_booking.upload_date | date:'yyyy-MM-dd HH:mm:ss'"
                    readonly>
            </mat-form-field>

            <mat-form-field fxFlex>
                <input matInput placeholder="Response Date" [value]="audit_booking.response_date | date:'yyyy-MM-dd HH:mm:ss'"
                    readonly>
            </mat-form-field>

            <mat-form-field fxFlex>
                <input matInput placeholder="Method" [value]="audit_booking.method" readonly>
            </mat-form-field>

            <mat-form-field fxFlex>
                <input matInput placeholder="Query" [value]="audit_booking.query" readonly>
            </mat-form-field>

        </div>
        <!-- Flex Row -->
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex>
                <textarea matInput rows="10" placeholder="Upload Data" [value]="upload_data_json| json" readonly
                    #Upload></textarea>
                <div><button mat-button fxFlex color="accent" (click)="onViewJson('Upload Data',audit_booking.upload_data)">View
                        Json
                    </button></div>
            </mat-form-field>
            <mat-form-field fxFlex>
                <textarea matInput rows="10" placeholder="Processed Data" [value]="process_data_json  | json" readonly
                    #Processed></textarea>
                <div><button mat-button fxFlex color="accent" (click)="onViewJson('Processed Data',audit_booking.processed_data)">View
                        Json
                    </button></div>
            </mat-form-field>

            <mat-form-field fxFlex>
                <textarea matInput rows="10" placeholder="Response Data" [value]="response_data_json  | json" readonly
                    #Response></textarea>
                <div><button mat-button fxFlex color="accent" (click)="onViewJson('Response Data',audit_booking.response_data)">View
                        Json
                    </button></div>
            </mat-form-field>

            <mat-form-field fxFlex>
                <textarea matInput rows="10" placeholder="Response Processed Data" [value]="response_process_data_json | json"
                    readonly #Response_Processed></textarea>
                <div><button mat-button fxFlex color="accent" (click)="onViewJson('Response Processed Data',audit_booking.response_processed_data)">View
                        Json
                    </button></div>
            </mat-form-field>
        </div>

    </mat-card-content>
    <!--Actions Control  -->
    <mat-card-actions align="center" *ngIf="!showSearchBlock">
        <div class="amd-card-content">
            <button mat-raised-button color="accent" (click)="onBackPage()">
                <mat-icon>arrow_back</mat-icon> Back
            </button>
        </div>
    </mat-card-actions>
</mat-card>