import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    jsonData: any;
}

@Component({
    selector: 'json_viewer_dialog',
    templateUrl: 'Json_Viewer_Dialog.html',
    styleUrls: ['./Json_Viewer_Dialog.scss']
})
export class Json_Viewer_Dialog_Compnent {

    json: any;
    expanded: boolean = true;
    expandToolTip: string = "Unexpand";

    constructor(
        public dialogRef: MatDialogRef<Json_Viewer_Dialog_Compnent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        this.json = JSON.parse(data.jsonData);
    }

    onExpendUnexpand() {
        this.expanded = !this.expanded;
        this.expandToolTip = this.expanded ? "Unexpand" : "Expand";
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

}