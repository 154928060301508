<div class="toolbar-user-container">
  <button mat-button (click)="isOpen = !isOpen" class="toolbar-user-btn" [class.open]="isOpen" [ngStyle.xs]="{'min-width': '70px'}">
    <span fxLayout="row" fxLayoutAlign="start center">
      <img class="avatar" [src]="currentUser?.photo_url || 'assets/images/noavatar.png'">
      <span class="name" fxHide fxShow.gt-xs>{{currentUser?.name || "Account" | titlecase}}</span>
      <mat-icon class="icon" fxHide fxShow.gt-xs>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content">
      <mat-nav-list>
        <mat-list-item>
          <a matLine [routerLink]="['/user_profile']" (click)="isOpen = !isOpen">Profile</a>
          <button mat-icon-button>
            <mat-icon>account_circle</mat-icon>
          </button>
        </mat-list-item>
        <mat-list-item>
          <a matLine href="/docs" target="_blank" (click)="isOpen = !isOpen">Help</a>
          <button mat-icon-button>
            <mat-icon>help</mat-icon>
          </button>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item (click)="isLoggedOn() == true ? logoff() : login()">
          <a matLine>{{isLoggedOn() == true ? "Logout" : "Login"}}</a>
          <button mat-icon-button>
            <mat-icon>{{isLoggedOn() == true ? "exit_to_app" : "person"}}</mat-icon>
          </button>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </div>
</div>
