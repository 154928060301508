<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>filter_list</mat-icon> Web Filter
            </h5>
        </mat-card-title>
        <mat-card-title>
            <h6>{{web_filter_header}}</h6>
        </mat-card-title>
        <mat-doc-component class="amd-card-help-button" [docLink]="'web-filter'"></mat-doc-component>
    </mat-card-header>
    <!-- Main page content -->
    <mat-card-content class="amd-card-content" *ngIf="showSearchBlock">
        <!-- Add new record -->
        <div class="amd-corner-button" style="margin-top: 20px;">
            <button mat-fab color="accent" (click)="onAdd_Web_Filter()">
                <mat-icon matTooltip="Add Web Filter">add</mat-icon>
            </button>
        </div>
        <!-- Search Record -->
        <div class="mat-elevation-z8">
            <!-- Filter Database results -->
            <div fxLayout="row">
                <div>
                    <mat-input-debounce placeholder="Web Filter Search..." (value)="onWeb_Filter_Search($event)" style="padding:20px">
                    </mat-input-debounce>
                </div>
            </div>
            <!-- Result table -->
            <table class="amd-table mat-elevation-z8" mat-table [dataSource]="web_filter_list">
                <!-- Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Web Filter Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <button color="accent" mat-mini-fab (click)="onEdit_Web_Filter(element)">
                                <mat-icon matTooltip="Edit">edit</mat-icon>
                            </button>
                            <button color="warn" mat-mini-fab (click)="onDelete_Web_Filter(element)">
                                <mat-icon matTooltip="Delete">delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <!-- Table Header -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!-- Pagination -->
            <mat-paginator [length]="pagination_web_filter.total_count" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"
                (page)="onClick_Web_Filter_Pagination($event)">
            </mat-paginator>
        </div>

    </mat-card-content>
    <!-- Add or Update page content -->
    <mat-card-content class="amd-card-content" *ngIf="!showSearchBlock">
        <!-- Form Fields -->
        <mat-form-field class="amd-form_field">
            <input matInput placeholder="Web Filter Name" [formControl]="web_filter_name">
            <mat-error *ngIf="web_filter_name.invalid">You must enter a value</mat-error>
        </mat-form-field>
        <!-- Data Panels -->
        <mat-accordion class="amd-expansion-panel">
            <mat-expansion-panel [expanded]="panelStep === 0" (opened)="setPanelStep(0)" hideToggle>
                <!-- Panel Header -->
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Filter Tags
                    </mat-panel-title>
                    <mat-panel-description>
                        {{web_tag_header}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- Panel Body -->
                <ng-template matStepLabel>Filter Tags</ng-template>
                <!-- Add or Update page content -->
                <mat-toolbar class="bg-transparent">
                    <span class="fill-space"></span>
                    <button mat-raised-button color="accent" (click)="onAddFilterTag(stepper)">
                        <mat-icon>add</mat-icon> Add Tag
                    </button>
                </mat-toolbar>
                <!-- Result table -->
                <table class="amd-table" mat-table [dataSource]="display_filter_tag_list">
                    <!-- Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Tag Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <!-- Column -->
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <div fxLayout="row" fxLayoutGap="10px">
                                <button color="accent" mat-mini-fab (click)="onUpdateFilterTag(element,stepper)">
                                    <mat-icon matTooltip="Edit">edit</mat-icon>
                                </button>
                                <button color="warn" mat-mini-fab (click)="onDeleteFilterTag(element)">
                                    <mat-icon matTooltip="Delete">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <!-- Table Header -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <!-- Pagination -->
                <mat-paginator [length]="pagination_filter_tag.total_count" [pageSize]="10" (page)="onClick_Web_Tag_Pagination($event)">
                </mat-paginator>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="panelStep === 1" (opened)="setPanelStep(1)" hideToggle>
                <!-- Panel Header -->
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Sales Group
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matStepLabel>Sales Group</ng-template>
                <!-- Form Fields -->
                <mat-form-field class="amd-form_field">
                    <input matInput placeholder="Tag Name" [(ngModel)]="web_filter_tag.name">
                </mat-form-field>
                <!-- Header -->
                <h3 style="text-align: center">Sales Group</h3>
                <!-- Data List -->
                <div>
                    <mat-dual_list fxFlex [availableItems]="availableWebSalesGroup" [(selectededItems)]="selectedWebSalesGroup"
                        [availableItemsPagination]="pagination_sales_group" (availableItemsPage)="onClick_Sales_Group_Pagination($event)"
                        (availableSearch)=onClick_Web_Sales_Group_Search($event) (add)="onAddWebSalesGroup($event)"
                        (remove)="onRemoveWebSalesGroup($event)">
                    </mat-dual_list>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
    <!--Actions Control  -->
    <mat-card-actions align="center" *ngIf="!showSearchBlock">
        <div class="amd-card-content">
            <button mat-raised-button color="accent" (click)="onAdd_Update_Web_Filter_Submit()">
                <mat-icon>{{updateButton ? "edit" : "add"}}</mat-icon> {{updateButton ? "Update Web Filter" : "Add Web
                Filter"}}
            </button>
            <button mat-raised-button color="accent" (click)="rest_WebFilterForm()">
                <mat-icon>cancel</mat-icon> Cancel
            </button>
        </div>
    </mat-card-actions>
</mat-card>