import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

import { pagination } from "../../../system/objects/pagination";

import { Pagination_Service } from '../../../system/services/Pagination_Service';

export interface DualList {
    id: string,
    name: string;
    selected: boolean;
    dataType:any;
}

@Component({
    selector: 'mat-dual_list',
    templateUrl: './Dual_List.html'
})
export class Dual_List_Component {

    @Input() selectedRowAddShow:boolean = false;
    @Input() selectedRowSettings:boolean = false;
    @Input() templateRef:TemplateRef<any>;

    @Input() availableItems: DualList[] = [];
    @Input() availableItemsPagination: pagination = new pagination();
    @Input() selectededItems: DualList[] = [];

    @Output() selectededItemsChange = new EventEmitter();
    @Output() availableItemsPage: EventEmitter<any> = new EventEmitter();
    @Output() availableSearch: EventEmitter<any> = new EventEmitter();

    @Output() availableRow: EventEmitter<any> = new EventEmitter();
    @Output() selectedRowAdd: EventEmitter<any> = new EventEmitter();
    @Output() selectedSettings: EventEmitter<any> = new EventEmitter();

    @Output() add: EventEmitter<any> = new EventEmitter();
    @Output() remove: EventEmitter<any> = new EventEmitter();





    displaySelectededItems: DualList[] = [];
    pageNumber: number;
    pageSize: number
    selectededItemsPagination: pagination = new pagination();
    displayedColumns: string[] = ['option', 'status'];

    constructor(private Pagination_Service: Pagination_Service) { }

    ngOnChanges() {
      
        this.availableItems.forEach(x => {
            if (this.selectededItems.find(obj => obj.id.toLocaleLowerCase() == x.id.toLocaleLowerCase())) {
                x.selected = true;
            }
        });
        this.RefreshPaginationSelectedItems(String(this.pageNumber), this.pageSize);
    }
    onAvailableItems(item: DualList) {
        this.availableItems.forEach(x => {
            if (x.id.toLocaleLowerCase() == item.id.toLocaleLowerCase()) {
                x.selected = true;
            }
        });

        if (this.selectededItems.find(x => x.id.toLocaleLowerCase() == item.id.toLocaleLowerCase())) {
            // Do Nothing
        }
        else {
            this.selectededItems.push({ id: item.id, name: item.name, selected: true , dataType:item.dataType });
        }
        // Refresh Paginiation
        this.RefreshPaginationSelectedItems(String(this.pageNumber), this.pageSize);
        // Emit changes if Parent component wants to perform some actions against selected item click
        this.add.emit(item);
    }
    onSelectedItems(item: DualList) {
        this.availableItems.forEach(x => {
            if (x.id.toLocaleLowerCase() == item.id.toLocaleLowerCase()) {
                x.selected = false;
            }
        });

        this.selectededItems = this.selectededItems.filter(x => x.id != item.id);
        // Refresh Paginiation
        this.RefreshPaginationSelectedItems(String(this.pageNumber), this.pageSize);
        // Emit changes to update the ngModel value
        this.selectededItemsChange.emit(this.selectededItems);
        // Emit changes if Parent component wants to perform some actions against selected item click
        this.remove.emit(item);
    }
    onAvailableItemsPaginate(event: any) {
        this.availableItemsPage.emit(event);
    }
    onSelectedItemsPaginate(e: any) {
        this.pageNumber = e.pageIndex + 1;
        this.pageSize = e.pageSize;
        this.RefreshPaginationSelectedItems(String(this.pageNumber), this.pageSize);
    }
    RefreshPaginationSelectedItems(page?: string, page_size?: number) {
        var output = this.Pagination_Service.Array_Paginator(this.selectededItems, page, page_size);
        this.displaySelectededItems = output.data;
        this.selectededItemsPagination = output.pagination;
    }
    onAvailableOptionsSearch(vlaue: string) {
        this.availableSearch.emit(vlaue);
    }
    onSelectedOptionsSearch(vlaue: string) {

        if (vlaue != "") {
            this.displaySelectededItems = this.displaySelectededItems.filter(x => x.name.toLocaleLowerCase().includes(vlaue.toLocaleLowerCase()));
        }
        else {
            this.RefreshPaginationSelectedItems(String(this.pageNumber), this.pageSize);
        }
    }
    onAvailableRowClick(vlaue:any){
        this.availableRow.emit(vlaue);
    }
    onSelectedRowAdd(vlaue:any){
        this.selectedRowAdd.emit(vlaue);
    }
    onSelectedSettings(vlaue:any){
        this.selectedSettings.emit(vlaue);
    }
}
