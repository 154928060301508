<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px" style="margin-bottom: 25px;">
    <mat-form-field fxFlex>
        <mat-select placeholder="Connector" [(ngModel)]="selected_connector" (selectionChange)="onConnectorFilter($event.value)">
            <mat-option *ngFor="let connector of conector_type_list" [value]="connector.id">{{connector.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex>
        <mat-select placeholder="Company" [(ngModel)]="selected_company" (selectionChange)="onCompanyFilter()">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex>
        <mat-select placeholder="Finance Centres" (selectionChange)="onFinanceCentreFilter()" [(ngModel)]="selected_finance_centre">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let finance_centre of finance_centres" [value]="finance_centre.id">{{finance_centre.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex>
        <mat-select placeholder="Product Group" (selectionChange)="onProductGroupFilter()" [(ngModel)]="selected_product_group">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let group of product_groups" [value]="group.id">{{group.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div>
    <mat-dual_list fxFlex [availableItems]="availableProductItems" [(selectededItems)]="selectededItems"
        [availableItemsPagination]="pagination_products" (availableItemsPage)="onClick_Products_Pagination($event)"
        (availableSearch)=onClick_Product_Search($event) [selectedRowAddShow]="enableSelectedRowAdd" (selectedRowAdd)="onProductSelectedAddOption($event)"
        [selectedRowSettings]="enableSelectedRowSettings" (selectedSettings)="onProductSelectedSettingsOption($event)"
        (add)="onAddProductSelected($event)" (remove)="onRemoveProductSelected($event)" [templateRef]="settingsTemplateRef">
    </mat-dual_list>
</div>
