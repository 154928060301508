import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';

import { user } from '../../../../tms/identity/internal/objects/maintenance/User/User';

import { OAuth_Service } from '../../../../../modules/system/services/system/OAuth_Service';
import { Helper_Service } from '../../../services/Core/Helper_Service';
import { User_Service } from '../../../../tms/identity/internal/services/maintenance/User/User';


@Component({
    selector: 'mat-user-menu',
    templateUrl: './User_Menu.html',
    styleUrls: ['./User_Menu.scss']
})
export class User_Menu_Component implements OnInit {
    isOpen: boolean = false;

    @Input() currentUser : user = null;
    @HostListener('document:click', ['$event', '$event.target'])

    onClick(event: MouseEvent, targetElement: HTMLElement) {
        if (!targetElement) {
            return;
        }
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.isOpen = false;
        }
    }

    constructor(private elementRef: ElementRef, public oauth_Service: OAuth_Service,
        public helper_service: Helper_Service, private user_service: User_Service) { }

    ngOnInit() {
        if (this.oauth_Service.isAuthorized) {
            this.user_service.getUserByID(null, parseInt(this.oauth_Service.identityClaims.user_id)).subscribe(res => {
                this.currentUser = <user>res.body;
                this.currentUser.name = this.parse_name(this.currentUser.user_name);
            });
        }
    }

    isLoggedOn(): boolean {
        return this.oauth_Service.isLoggedIn();
    }

    public login() { this.oauth_Service.login(); }

    public logoff() { this.oauth_Service.logoff(); }

    private parse_name(name: string): string {
        if (name.indexOf(".") > -1) {
            var fullName = name.split(".");
            return fullName[0] + " " + fullName[1];
        } else {
            return name;
        }
    }
     
}