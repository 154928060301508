export class calendar_day {
  public name             : string;
  public number           : number;
  public is_current_month : boolean;
  public is_today         : boolean;
  public date             : string;
  public output_date      : string;
  public isOnRequest      : boolean;
  public hasDeparture     : boolean = false;
  public totalCapacity    : number = -1;
  public fromPrice        : number = -1;
  public toPrice          : number = -1;
}
