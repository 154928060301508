/* https://www.acagroup.be/en/blog/how-to-use-the-broadcastchannel-api-angular/ */

import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { runInZone } from './RunInZone_Service';

export interface BroadcastMessage {
    type: string;
    payload: any;
}

export interface BroadcastMessagePayload {
    fcid: string;
    token: string;
} 

@Injectable()
export class Broadcast_Service {
    public broadcastChannel: BroadcastChannel;
    public onMessage = new Subject<any>();
    private broadcastChannelName: string = 'tmsv3';

    public MESSAGETYPE_FINANCENTERSWITCH: string = 'FINANCENTERSWITCH';

    // constructor(broadcastChannelName: string) {
    //     this.broadcastChannel = new BroadcastChannel(broadcastChannelName);
    //     this.broadcastChannel.onmessage = (message) => this.onMessage.next(message.data);
    // }

    constructor(private ngZone: NgZone) {
        this.broadcastChannel = new BroadcastChannel(this.broadcastChannelName);
        this.broadcastChannel.onmessage = (message) => this.onMessage.next(message.data);
    }

    publish(message: BroadcastMessage): void {
        this.broadcastChannel.postMessage(message);
        console.log("Message broadcasted: ", message);
    }

    messagesOfType(type: string): Observable<BroadcastMessage> {
        return this.onMessage.pipe(
            // It is important that we are running in the NgZone. This will make sure that Angular component changes are immediately visible in the browser when they are updated after receiving messages.
            runInZone(this.ngZone),
            filter(message => message.type === type)
        );
    }

}

// export const DEMO_BROADCAST_SERVICE_TOKEN = new InjectionToken<Broadcast_Service>('tmsv3BroadcastService', {
//     factory: () => {
//         return new Broadcast_Service('tmsv3_fcswitch');
//     },
// });