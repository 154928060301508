import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

export class SnackBarMessage {
    message: any;
    action: string = null;
    config: MatSnackBarConfig = null;
    messageType: string;
}


@Injectable()
export class Snackbar_Service implements OnDestroy {
    private messageQueue: Array<any> = Array<any>();
    private subscription: Subscription;
    private snackBarRef: MatSnackBarRef<any>;
    private isInstanceVisible = false;

    constructor(private snackBar: MatSnackBar) { }

    showNotification(message: any, action?: string, config?: MatSnackBarConfig, messageType: string = '', classOverride: string = 'blue-snackbar'): void {

        if (!config) {
            config = new MatSnackBarConfig();
            config.duration = 3000;
            config.verticalPosition = 'bottom';
            config.horizontalPosition = 'end';
            config.panelClass = [classOverride];
        }

        const sbMessage = new SnackBarMessage();
        sbMessage.message = message;
        sbMessage.action = action;
        sbMessage.config = config;
        sbMessage.messageType = messageType;

        this.messageQueue.push(sbMessage);

        if (!this.isInstanceVisible) {
            this.showNext();
        }
    }

    private showNext() {
        if (this.messageQueue.length === 0) {
            return;
        }

        const message = this.messageQueue.shift();
        this.isInstanceVisible = true;

        if (message.messageType == "TEMPLATE") {
            console.log("if", message);
            this.snackBarRef = this.snackBar.openFromTemplate(message.message, message.config);
        }
        else {
            console.log("else", message);
            this.snackBarRef = this.snackBar.open(message.message, message.action, message.config);
        }
        this.snackBarRef.afterDismissed().subscribe(() => {
            this.isInstanceVisible = false;
            this.showNext();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}

