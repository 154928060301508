import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
    DoCheck
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';



@Component({
    selector: "mat-select-autocomplete",
    templateUrl: './Select_Autocomplete.html',
    styleUrls: ['./Select_Autocomplete.scss']
})
export class SelectAutocompleteComponent implements OnChanges, DoCheck {
    @Input() selectPlaceholder: string = "search...";
    @Input() placeholder: string;
    @Input() options;
    @Input() disabled = true;
    @Input() display = "name";
    @Input() value = "id";
    @Input() formControl: UntypedFormControl = new UntypedFormControl();
    @Input() errorMsg: string = "Field is required";
    @Input() showErrorMsg = false;
    @Input() selectedOptions;
    @Input() multiple = true;

    // New Options
    @Input() labelCount: number = 1;
    @Input() appearance: "standard" | "fill" | "outline" = "standard";

    @Output() selectionChange: EventEmitter<any> = new EventEmitter();

    @ViewChild("selectElem", { static: true }) selectElem: MatSelect;

    filteredOptions: Array<any> = [];
    selectedValue: Array<any> = [];
    selectAllChecked = false;
    displayString = "";

    constructor() { }

    ngOnChanges() {
        if (this.disabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
        this.filteredOptions = this.options;
        if (this.selectedOptions) {
            this.selectedValue = this.selectedOptions;
        } else if (this.formControl.value) {
            this.selectedValue = this.formControl.value;
        }

    }

    ngDoCheck() {
        if (!this.selectedValue.length) {
            this.selectionChange.emit(this.selectedValue);
        }
    }

    toggleSelectAll(val) {
        if (val.checked) {
            var result = _.union(this.selectedValue, this.filteredOptions);
            this.selectedValue = _.uniqBy(result, this.value);

        } else {
            const filteredValues = this.getFilteredOptionsValues();
            this.selectedValue = _.xorBy(filteredValues, this.selectedValue, this.value);
        }
        this.selectionChange.emit(this.selectedValue);
    }

    filterItem(value) {
        this.filteredOptions = this.options.filter(
            item => item[this.display].toLowerCase().indexOf(value.toLowerCase()) > -1
        );
        this.selectAllChecked = true;
        this.filteredOptions.forEach(item => {
            if (!this.selectedValue.includes(item[this.value])) {
                this.selectAllChecked = false;
            }
        });
        if (!this.filteredOptions.length) {
            this.selectAllChecked = false;
        }
    }

    hideOption(option) {
        return !(this.filteredOptions.indexOf(option) > -1);
    }

    getFilteredOptionsValues() {
        const filteredValues = [];
        this.filteredOptions.forEach(option => {
            filteredValues.push(option);
        });
        return filteredValues;
    }

    onDisplayString() {
        this.displayString = "";
        if (this.selectedValue && this.options) {
            let displayOption = [];
            if (this.multiple) {
                // Multi select display
                for (let i = 0; i < this.labelCount; i++) {
                    displayOption[i] = this.options.filter(
                        option => option[this.value] === (this.selectedValue[i] == undefined ? this.selectedValue[i] : this.selectedValue[i][this.value])
                    )[0];
                }
                if (displayOption.length) {
                    for (let i = 0; i < displayOption.length; i++) {
                        if (displayOption[i] && displayOption[i][this.display]) {
                            this.displayString += displayOption[i][this.display] + ",";
                        }
                    }
                    this.displayString = this.displayString.slice(0, -1);
                    if (
                        this.selectedValue.length > 1 &&
                        this.selectedValue.length > this.labelCount
                    ) {
                        this.displayString += ` (+${this.selectedValue.length -
                            this.labelCount} others)`;
                    }
                }
            } else {
                // Single select display
                displayOption = this.options.filter(
                    option => option[this.value] === this.selectedValue[this.value]
                );
                if (displayOption.length) {
                    this.displayString = displayOption[0][this.display];
                }
            }
        }
        return this.displayString;
    }
    onSelectionChange(val) {
        const filteredValues = this.getFilteredOptionsValues();
        let count = 0;
        if (this.multiple) {
            this.selectedValue.filter(item => {
                if (filteredValues.includes(item)) {
                    count++;
                }
            });
            this.selectAllChecked = count === this.filteredOptions.length;
        }
        this.selectedValue = val.value;
        this.selectionChange.emit(this.selectedValue);
    }
    public trackByFn(index, item) {
        return item;
    }
    compareObjects(o1: any, o2: any): boolean {
        return o1 && o2 ? (o1.id === o2.id && o1.display === o2.display) : o1 === o2;
    }
}