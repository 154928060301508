<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>map</mat-icon> Mapping
            </h5>
        </mat-card-title>
        <mat-card-title>
            <h6>{{mapping_header}}</h6>
        </mat-card-title>
        <mat-doc-component class="amd-card-help-button" [docLink]="'mapping'"></mat-doc-component>
    </mat-card-header>
    <!-- Main page content -->
    <mat-card-content class="amd-card-content" *ngIf="showSearchBlock">
        <!-- Add new record -->
        <div class="amd-corner-button" style="margin-top: 20px;">
            <button mat-fab color="accent" (click)="onAdd_Mapping()">
                <mat-icon matTooltip="Add Mapping">add</mat-icon>
            </button>
        </div>
        <!-- Search Record -->
        <div class="mat-elevation-z8">
            <!-- Filter Database results -->
            <div fxLayout="row">
                <div>
                    <mat-input-debounce placeholder="Mapping Search..." (value)="onMapping_Search($event)" style="padding:20px">
                    </mat-input-debounce>
                </div>
            </div>
            <!-- Result table -->
            <table class="amd-table mat-elevation-z8" mat-table [dataSource]="mapping_list">
                <!-- Column -->
                <ng-container matColumnDef="mapping_code">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Mapping Code </th>
                    <td mat-cell *matCellDef="let element"> {{element.mapping_code}} </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <button color="accent" mat-mini-fab (click)="onEdit_Mapping(element)">
                                <mat-icon matTooltip="Edit">edit</mat-icon>
                            </button>
                            <button color="warn" mat-mini-fab (click)="onDelete_Mapping(element)">
                                <mat-icon matTooltip="Delete">delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <!-- Table Header -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!-- Pagination -->
            <mat-paginator [length]="pagination_mapping.total_count" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"
                (page)="onClick_Mapping_Pagination($event)">
            </mat-paginator>
        </div>
    </mat-card-content>
    <!-- Add or Update page content -->
    <mat-card-content class="amd-card-content" *ngIf="!showSearchBlock">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex>
                <input matInput placeholder="Mapping Code" [(ngModel)]="mapping.mapping_code">
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let product of select_product" [selectable]="true" [removable]="true" (removed)="removeProduct(product)">
                        {{product.company_finance_centre_product_group_product}}
                        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Product" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (click)="addProduct()" [readonly]="true">
                </mat-chip-list>
                <mat-error>You must enter a value</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-select placeholder="Affiliates" [(ngModel)]="selected_affiliate" [ngModelOptions]="{standalone: true}"
                    multiple #cacheList>
                    <mat-select-trigger>
                        {{selected_affiliate ? selected_affiliate[0]?.name : ''}}
                        <span *ngIf="selected_affiliate?.length > 1">
                            (+{{selected_affiliate.length - 1}} {{selected_affiliate?.length === 2 ? 'other'
                            : 'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let cs of affiliates" [value]="cs">
                        {{cs.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-card-content>
    <!--Actions Control  -->
    <mat-card-actions align="center" *ngIf="!showSearchBlock">
        <div class="amd-card-content">
            <button mat-raised-button color="accent" (click)="onAdd_Update_Mapping_Submit()">
                <mat-icon>{{updateButton ? "edit" : "add"}}</mat-icon> {{updateButton ? "Update Mapping" : "Add
                Mapping"}}
            </button>
            <button mat-raised-button color="accent" (click)="rest_MappingForm()">
                <mat-icon>cancel</mat-icon> Cancel
            </button>
        </div>
    </mat-card-actions>
</mat-card>