// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  environment_name : "rtg-bab-staging",

  identity_server: "https://identity.staging.tms.radicaltravel.com",
  identity_scope: "openid profile offline_access tour_management_system",
  identity_client_id: "tms.corp.ttc",
  identity_realm: "travcorp-gateway",

  api_internal_gateway: "https://gateway.staging.tms.radicaltravel.com",
  microservice_app_id: "326caf5e-acb9-4bf1-9c33-8158fe552e20",
  microservice_api_key: "mrRWV8ac4Ngo38v0oi64/wkUik3CzXdMLpmeWcH+EiCxv6/URHNaLg==",
};
