<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>shopping_cart</mat-icon> Product Details
            </h5>
            <h6>
                {{product_header}}
            </h6>
        </mat-card-title>
        <mat-doc-component class="amd-card-help-button" [docLink]="'link-product'"></mat-doc-component>
    </mat-card-header>
    <mat-card-content class="amd-card-content">
        <!-- Header -->
        <h5 style="text-align: center">Selling Product</h5>
        <!-- Data List -->
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex>
                <input matInput placeholder="Connector" [formControl]="connector_name">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput placeholder="Company" [formControl]="company">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput placeholder="Finance Centre" [formControl]="company_finance_centre">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput placeholder="Product Group" [formControl]="company_finance_centre_product_group">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex>
                <input matInput placeholder="External Product Code" [formControl]="external_identifier">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput placeholder="Exernal Company" [formControl]="external_company_identifier">
            </mat-form-field>
        </div>
        <!-- Header -->
        <h5 style="text-align: center">Supplier Product</h5>
        <!-- Data List -->
        <div style="margin-bottom: 25px;">
            <product-selection [(selectededItems)]="selectedProductItems" (addProductSelected)="onAddProduct($event)"
            (removeProductSelected)="onRemoveProduct($event)" [selectionFilter]="productSelectionFilter" >
            </product-selection> 
        </div>
    </mat-card-content>
    <!--Actions Control  -->
    <mat-card-actions align="center">
        <div class="amd-card-content">
            <button mat-raised-button color="accent" (click)="onAdd_Update()">
                <mat-icon>link</mat-icon>Update Linked Product
            </button>
            <button mat-raised-button color="accent" (click)="onCancel()">
                <mat-icon>cancel</mat-icon> Cancel
            </button>
        </div>
    </mat-card-actions>
</mat-card>