import { Injectable } from '@angular/core';

import { list } from '../../../objects/Channel_Management/List_Management/list';

import { Environment_Service } from "../../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../../system/services/Response_Service";



@Injectable()
export class List_Management_Service {

    constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

    getChannel_Management_List(page_number: number, page_size: number, Search: string, all_result: boolean) {

        // Add Options from Variable
        var search_options: string[] = [];

        if (Search != "") {
            search_options.push("search=" + Search);
        }

        if (page_number != null) {
            search_options.push("page_number=" + page_number);
        }

        if (page_size != null) {
            search_options.push("page_size=" + page_size);
        }

        if (all_result != null) {
            search_options.push("all_results=" + all_result);
        }

        // Create Search Options
        var search_option: string = "";
        for (var index = 0; index < search_options.length; index++) {
            search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
        }

        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/list" + search_option);
    }


    addChannel_Management_List(new_list: list) {
        return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/list", new_list);
    }

    deleteChannel_Management_List(list: list) {
        return this.Response_Service.delete_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/list/" + list.id);
    }

    getList_Modes(paged_url: string) {
        if (paged_url != null) {
            return this.Response_Service.getResponse(paged_url);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/channel_management_list_mode");
    }
}