import { web_search_sales_group } from "./web_search_sales_group";
import { product_selected} from '../External/product_selected';

export class web_search_sales_group_product{

    public sales_group_id:number;
    public sales_group:web_search_sales_group;
    public product_id:string;
    public product:product_selected;
    public not_for_sale:boolean;
}