import { Component } from '@angular/core';

import { Call_Info_Service } from "../../../../../modules/system/services/Call_Info_Service";
import { Environment_Service } from '../../../../system/services/Environment_Service';

@Component({
  selector: 'mat-sidebar',
  templateUrl: './Sidebar.html',
  styleUrls: ['./Sidebar.scss']
})
export class Sidebar_Component {
  call_info_data: any;
  showActiveCalls: boolean = false;
  today: number = Date.now();

  constructor(public call_info_service: Call_Info_Service, public environment_service: Environment_Service) {
    this.call_info_data = this.call_info_service;
  }
  
  ngDoCheck() {
    if (this.call_info_data.active_calls.length > 0) {
      this.showActiveCalls = true;
    }
    else {
      this.showActiveCalls = false;
    }
  }
}
