export class tms_internal_user_info {
  public sub: string;
  public name: string;
  public operator_id: string;
  public operator: string;
  public finance_centre_id: string;
  public finance_centre: string;
  public user_id: string;
  public user_photo_url: string;
  public certificate: string;
}
