import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Module_System } from '../../modules/system/system.module';
import { Module_TMS_Gateway } from '../../modules/tms/gateway/tms_gateway_module'
import { Module_TMS_Identity_Internal } from '../../modules/tms/identity/internal/tms_identity_internal_module';

import { AppComponent } from './app.component';
import { GatewayComponent } from './main/gateway';
import { AppRouting } from './app.routing';

import { Gateway_Maintenance_Guard } from './guards/Gateway_Maintenance_Guard';

import { environment } from '../environments/environment';
import { Environment_Service } from '../../modules/system/services/Environment_Service';



@NgModule({
  declarations: [
    AppComponent,
    GatewayComponent
  ],
  imports: [
    AppRouting,
    BrowserModule,
    Module_System.forRoot(),
    Module_TMS_Gateway,
    Module_TMS_Identity_Internal,
    OAuthModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [ Gateway_Maintenance_Guard],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private Environment_Service: Environment_Service) {
    this.Environment_Service.environment.production = environment.production;
    this.Environment_Service.environment.environment_name = environment.environment_name;
    
    this.Environment_Service.environment.identity_client_id = environment.identity_client_id;
    this.Environment_Service.environment.identity_scope = environment.identity_scope;
    this.Environment_Service.environment.identity_server = environment.identity_server;

    this.Environment_Service.environment.api_internal_gateway = environment.api_internal_gateway;
    this.Environment_Service.environment.microservice_api_key = environment.microservice_api_key;
    this.Environment_Service.environment.microservice_app_id = environment.microservice_app_id;

  }
}
