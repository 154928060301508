import { Component, OnInit } from '@angular/core';

import { cache_server } from '../../../../objects/Type/cache_server';
import { pagination } from '../../../../../../system/objects/pagination';
import { password_hash_response } from "../../../../../../system/objects/password_hash_response";


import { Cache_Server_Service } from '../../../../services/Type_Cache_Server/Cache_Server';
import { Helper_Service } from '../../../../../../material_ui/services/Core/Helper_Service';

@Component({
    selector: 'external-api-sync',
    templateUrl: './api_server_sync.html',
    styleUrls: ['api_server_sync.scss'],
})
export class Component_External_API_Sync implements OnInit {

    displayedColumns: string[] = ['app_id', 'name', 'last_product_sync', 'last_capacity_sync', 'status', 'actions'];
    cache_server_list: cache_server[] = [];
    pagination_cache_server: pagination = new pagination();
    statusProduct: cache_server[] = [];
    statusCapacity: cache_server[] = [];
    pageNum: number = 1;
    pageSize: number = 5;

    constructor(private cache_server_service: Cache_Server_Service, private helper_service: Helper_Service) { }

    ngOnInit() {
        this.getBackend_Data_Cache_Servers(this.pageNum, this.pageSize);
    }

    getBackend_Data_Cache_Servers(page: number, pageSize: number) {
        this.pageNum = page;
        this.pageSize = pageSize;
        this.cache_server_service.getCache_Server_List(page, pageSize).subscribe((res) => {
            this.cache_server_list = res.body['cache_server'];
            this.pagination_cache_server = res.body['pagination'];
            this.updateStatusOnRefresh();
        });
    }

    onClick_Cache_Server_Pagination(e: any) {
        this.getBackend_Data_Cache_Servers(e.pageIndex + 1, e.pageSize);
    }

    onClick_Cache_Server_Search(value: string) {
        this.cache_server_service.getCache_Servers(null, value).subscribe((res) => {
            this.cache_server_list = res.body['cache_server'];
            this.pagination_cache_server = res.body['pagination'];
        });
    }

    onAllProductsSync() {
        var message;

        this.cache_server_list.forEach(cache => {
            cache.productStatus = "progress";

            this.cache_server_service.productSync(cache.app_id).subscribe(
                (res) => {
                    cache.productStatus = "done";

                    if (res.body['scheduled_Items'].length > 0) {
                        message = cache.name + " has been synced successfully with scheduler job ids: " + res.body['scheduled_Items'][0]['queue_id'] + "-" + res.body['scheduled_Items'][res.body['scheduled_Items'].length - 1]['queue_id']
                    }
                    else {
                        message = cache.name + " is already synced."
                    }
                    this.helper_service.openSnackbarNotification(message, "Close", "success");
                },
                (error) => {
                    cache.productStatus = "error";
                    this.helper_service.openSnackbarNotification(cache.name + " " + error.message, "Close", "error");

                },
                () => {
                    this.getBackend_Data_Cache_Servers(this.pageNum, this.pageSize);
                });
            this.statusProduct.push(cache);
        });
    }

    onAllCapacitiesSync() {
        var message;

        this.cache_server_list.forEach(cache => {
            cache.capacityStatus = "progress";

            this.cache_server_service.capacitySync(cache.app_id).subscribe(
                (res) => {
                    cache.capacityStatus = "done";

                    if (res.body['scheduled_Items'].length > 0) {
                        message = cache.name + " has been synced successfully with scheduler job ids: " + res.body['scheduled_Items'][0]['queue_id'] + "-" + res.body['scheduled_Items'][res.body['scheduled_Items'].length - 1]['queue_id']
                    }
                    else {
                        message = cache.name + " is already synced."
                    }
                    this.helper_service.openSnackbarNotification(message, "Close", "success");
                },
                (error) => {
                    cache.capacityStatus = "error";
                    this.helper_service.openSnackbarNotification(cache.name + " " + error.message, "Close", "error");

                },
                () => {
                    this.getBackend_Data_Cache_Servers(this.pageNum, this.pageSize);
                });
            this.statusCapacity.push(cache);
        });
    }

    reload() {
        this.getBackend_Data_Cache_Servers(1, 5);
    }

    private updateStatusOnRefresh() {
        // Update Product status on refresh
        if (this.statusProduct.length > 0) {
            this.cache_server_list.forEach(cahceServer => {
                var status = this.statusProduct.find(obj => obj.app_id == cahceServer.app_id)
                if (status != null) {
                    cahceServer.productStatus = status.productStatus;
                }
            });
        }
        // Update Capacity status on refresh
        if (this.statusCapacity.length > 0) {
            this.cache_server_list.forEach(cahceServer => {
                var status = this.statusCapacity.find(obj => obj.app_id == cahceServer.app_id)
                if (status != null) {
                    cahceServer.capacityStatus = status.capacityStatus;
                }
            });
        }
    }

    private onRefreshLogin(item: any) {
        this.cache_server_service.getPasswordHash().subscribe(res => {
            var passwordHashResponse = <password_hash_response>res.body;
            this.cache_server_service.singleAffiliateSync(item.app_id, passwordHashResponse.password_hash).subscribe(
                (response) => {
                    this.helper_service.openSnackbarNotification('v1.2 Affiliate login refresh for ' + item.name + ' queued', 'Close', 'success');
                },
                (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); }
            );
        });

        this.cache_server_service.getPasswordHash().subscribe(res => {
            var passwordHashResponse = <password_hash_response>res.body;
            this.cache_server_service.singleAffiliateSyncV13(item.app_id, passwordHashResponse.password_hash).subscribe(
                (response) => {
                    this.helper_service.openSnackbarNotification('v1.3 Affiliate login refresh for ' + item.name + ' queued', 'Close', 'success');
                },
                (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); }
            );
        });

    }
}
