import { Component, OnInit, Input,HostListener, ElementRef, Output, EventEmitter  } from '@angular/core';
import { Router } from '@angular/router';
import { menu_list_item } from '../../../objects/Menu_List/menu_list_item';

import { Helper_Service } from '../../../services/Core/Helper_Service';

@Component({
    selector: 'mat-sidemenu-item',
    templateUrl: './Side_Menu_Item.html',
    styleUrls: ['./Side_Menu_Item.scss']
})
export class Side_Menu_Item_Component implements OnInit {

    @Input() menu;
    @Input() iconOnly: boolean;
    @Input() secondaryMenu = false;
    @Input() hideOnClick: boolean;
    @Output() itemClick: EventEmitter<any> = new EventEmitter();
    //@HostListener('document:click', ['$event', '$event.target']);

    constructor(public router: Router, private helper_service: Helper_Service, private elementRef: ElementRef) { }

    ngOnInit() {
    }

    onClick(event: MouseEvent, targetElement: HTMLElement) {
        if (!targetElement) {
            return;
        }
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
           this.menu.open = false;
        }
    }
    
    openLink(menu: any) {
        this.menu.open = true;
    }

    hasChildMenu(menu : any) {
        return (menu.sub != null && menu.sub.length > 0) ? true : false;
    }

    hasLink(menu: any) : boolean {
        var hasLink = false;
        if (menu.link != undefined) {
            if (menu.link != "" && menu.link!=false ) {
                hasLink = true;
            }
        } 

        return hasLink;
    }

    onItemClick(menu: any) {
        menu.open = !menu.open;
        // Hide the side panel after item selection
        if (this.hideOnClick && !this.hasChildMenu(menu)) {
            this.helper_service.closeLeftSideNav();
        }
        this.itemClick.emit(menu);
    }

    onSubItemClick(menu : any) {
        if (menu != null) {
            if (!this.hasChildMenu(menu) || !menu.open) {
                this.onItemClick(this.menu);
            }
        }
    }
 
}
