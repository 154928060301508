import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { Module_System } from '../../../system/system.module';

import { Component_Home } from './components/home/home';
import { Component_Login } from './components/account/login';
import { Component_Logout } from './components/account/logout';
import { Component_Error } from './components/home/error';
import { Component_Group } from './components/maintenance/group/group';
import { Component_User } from './components/maintenance/user/User';
import { Component_User_Profile } from './components/User_Profile/User_Profile';
import { Component_Audit_Activity } from './components/audit/activity/activity';
import { Component_Audit_Error } from './components/audit/error/error';


import { Group_Service } from './services/maintenance/Group/Group';
import { User_Service } from './services/maintenance/User/User';

import { Service_Login } from './services/Login';
import { Audit_Identity_Service } from './services/audit/Audit';


@NgModule({
  imports: [CommonModule, FormsModule, Module_System],

  declarations: [Component_Login, Component_Logout, Component_Error, Component_Home, Component_Group, Component_User, Component_User_Profile, Component_Audit_Activity, Component_Audit_Error],

  exports: [Component_Login, Component_Logout, Component_Error, Component_Home, Component_Group, Component_User, Component_User_Profile, Component_Audit_Activity, Component_Audit_Error],

  providers: [Group_Service, User_Service, Service_Login, Audit_Identity_Service]
})
export class Module_TMS_Identity_Internal { }
