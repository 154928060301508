import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { user } from '../../tms/identity/internal/objects/maintenance/User/User';

import { User_Service } from '../../tms/identity/internal/services/maintenance/User/User';
import { OAuth_Service } from './system/OAuth_Service';
import { Helper_Service } from '../../material_ui/services/Core/Helper_Service';

@Injectable()
export class Permissions_Service {

    constructor(private User_Service: User_Service, private OAuth_Service: OAuth_Service, private Helper_Service: Helper_Service) { }

    checkAuthenticationClaims(claim: string): boolean | Observable<boolean> {
        var hasAccess: boolean = false;
        if (this.OAuth_Service.isAuthorized) {
            return this.User_Service.getUserByID(null, parseInt(this.OAuth_Service.identityClaims.user_id)).pipe(
                map((res) => {
                    (<user>res.body).user_groups.forEach(group => {
                        if (group.groups.group_claims.some(x => x.claim.name === claim)) {
                            hasAccess = true;
                        }
                    })
                    if (hasAccess == false) {
                        this.Helper_Service.openSnackbarNotification("You do not have permission to access this link", "Close", "error");
                    }
                    return hasAccess;
                }
                ));
        }
        else {
            return hasAccess
        }

    }
}