<mat-nav-list [style.maxHeight]="(menu.open)?'1200px':'48px'" [ngClass]="{'secondaryMenu': secondaryMenu, 'primary': !secondaryMenu}">
  <!-- menu without submenu -->
  <mat-list-item *ngIf="!hasChildMenu(menu)" (click)="onItemClick(menu)" [routerLink]="[menu.link]">
        <mat-icon matListIcon iconsmall>{{menu.icon}} </mat-icon>
        <h3 matLine *ngIf="!iconOnly">{{ menu.name }}</h3>
  </mat-list-item>

  <!-- menu with submenu -->
  <mat-list-item *ngIf="hasChildMenu(menu)" (click)="onItemClick(menu)" [class.open]="menu.open">
    <mat-icon matListIcon iconsmall>{{menu.icon}} </mat-icon>
    <h3 matLine *ngIf="!iconOnly">{{ menu.name }} </h3>
    <mat-icon *ngIf="hasChildMenu(menu)" class="sidenav-dropdown-indicator rotate " [ngClass]="{'indicateOpen':menu.open}">
    expand_more</mat-icon>
</mat-list-item>

<mat-sidemenu-item *ngFor="let submenu of menu?.sub" [menu]="submenu" [class.open]="true" [iconOnly]="iconOnly" [secondaryMenu]="true" [hideOnClick]="hideOnClick" (itemClick)="onSubItemClick(menu)"> </mat-sidemenu-item>

</mat-nav-list>
