import { Injectable } from '@angular/core';

import { Environment_Service } from "../../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../../system/services/Response_Service";
import { supplier_product } from '../../../objects/Product/supplier_product';

@Injectable()
export class Product_Selection_Service {

  constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

  private data: any = null;
  private supplierProduct: supplier_product = null;

  getConnector_Product_List(paged_url: string, connector_id?: number) {

    if (paged_url != null) {
      return this.Response_Service.getResponse(paged_url);
    }
    return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/product?connector_id=" + connector_id);
  }
  getConnector_Type_List(paged_url: string) {

    if (paged_url != null) {
      return this.Response_Service.getResponse(paged_url);
    }
    return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/connector_type");
  }

  addProduct_Selected(new_product_selected: any) {
    return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/product_selected", new_product_selected);
  }

  setProductData(data: any) {
    this.data = data;
  }

  getProductData() {
    return this.data;
  }

  setSupplierProductData(supplierProduct: supplier_product) {
    this.supplierProduct = supplierProduct;
  }

  getSupplierProductData() {
    return this.supplierProduct;
  }

  getSupplierProduct(product_id: string) {
    return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/supplier_product?product_id=" + product_id);
  }

  addSupplierProduct(supplier_product: any) {
    return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/supplier_product", supplier_product);
  }

  getProduct_Selected_List(page_number?: number, page_size?: number, connector_id?: number, company?: string, finance_centre?: string, product_group?: string, Search?: string) {

    var search_options: string[] = [];

      if (Search != "") {
         search_options.push("search=" + Search);
     } 

    var search_filters: string[] = [];

    if (page_number != null) {
      search_filters.push("page_number=" + page_number);
    }

    if (page_size != null) {
      search_filters.push("page_size=" + page_size);
    }

    if (connector_id != null) {
      search_filters.push("connector_id=" + connector_id);
    }

    if (company != null) {
      search_filters.push("company=" + company)
    }

    if (finance_centre != null) {
      search_filters.push("finance_centre=" + finance_centre);
    }

    if (product_group != null) {
      search_filters.push("product_group=" + product_group);
    }

    var search_filter: string = "";

    for (var index = 0; index < search_filters.length; index++) {
      search_filter = search_filter + ((index == 0) ? "" : "&") + search_filters[index];
    }

    if (search_filter != "") {
      search_options.push(search_filter);
    }

    var search_option: string = "";

    for (var index = 0; index < search_options.length; index++) {
      search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
    }

    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/product_selected" + search_option);
  }






}
