import { Injectable } from '@angular/core';

import { mapping } from '../../../objects/Channel_Management/Mapping/mapping';

import { Environment_Service } from "../../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../../system/services/Response_Service";


@Injectable()
export class Mapping_Service {

    constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

    getMapping_List(page_number: number, page_size: number) {

        if (page_number != null && page_size != null) {
            return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/mapping?page_number=" + page_number + "&page_size=" + page_size);
        }
        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/mapping");
    }

    getMapping_Search(paged_url: string, Search: string) {
        if (paged_url != null) {
            return this.Response_Service.getResponse_Paged(paged_url);
        }

        var search_options: string[] = [];

        if (Search != "") {
            search_options.push("search=" + Search);
        }


        var search_option: string = "";

        for (var index = 0; index < search_options.length; index++) {
            search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
        }
        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/mapping" + search_option);
    }

    addMapping(new_mapping: mapping) {
        return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/mapping", new_mapping);
    }

    deleteMapping(mapping: mapping) {
        return this.Response_Service.delete_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/mapping/" + mapping.id);
    }

    getCache_Server(paged_url: string) {
        if (paged_url != null) {
            return this.Response_Service.getResponse(paged_url);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/internal/cache_server");
    }

    getAffiliates(paged_url: string) {
        if (paged_url != null) {
            return this.Response_Service.getResponse(paged_url);
        }
        return this.Response_Service.getResponse(this.Environment_Service.environment.api_internal_gateway + "/api/v1/affiliate?all_results=true");
    }
}
