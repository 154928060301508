<div mat-dialog-title>
    <div class="clearfix">
        <div style="float:left">{{data.title}}</div>
        <div style="float:right">
            <button mat-icon-button [(matTooltip)]="expandToolTip" (click)="onExpendUnexpand()">
                <mat-icon>{{expanded ? "expand_less" : "expand_more"}}</mat-icon>
            </button>
        </div>
    </div>
</div>
<div mat-dialog-content class="content-container">
        <ngx-json-viewer [json]="json" [expanded]="expanded"></ngx-json-viewer>
        <!-- <ngx-json-viewer [json]="segment.value" [expanded]="expanded"></ngx-json-viewer> -->
</div>
<!-- <div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Close</button>
</div> -->