<div fxLayout="column" fxLayoutAlign="center center" style="height: 700px;width: auto">
  <mat-card class="amd-card mat-elevation-z8" style="width:auto">
      <mat-card-content class="amd-card-content">
          <div class="text-center" style="margin-top: 5px;font-family: 'Montserrat', sans-serif;font-size: 150px;">
              OOPS!
          </div>
          <div class="text-center" style="font-family: 'Montserrat', sans-serif;font-size: 28px;">
            THE PAGE DOESN’T EXISTS 
        </div>
          <div class="text-center" style="margin-top: 20px">
            <a mat-raised-button color="accent" href="#" style="text-decoration: none">Go To Homepage</a>
          </div>
      </mat-card-content>
  </mat-card>
</div>