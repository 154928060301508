import { Component, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Helper_Service } from '../../../services/Core/Helper_Service';
import { Permissions_Service } from '../../../../system/services/Permissions_Service';


@Component({
    selector: 'mat-top-toolbar',
    templateUrl: './Top_Toolbar.html',
    styleUrls: ['./Top_Toolbar.scss']
})
export class Top_Toolbar_Component {

    @Input() appTitle: string;

    // Detect Screen Size
    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches)
        );

    constructor(public helper_service: Helper_Service, private Permissions_Service: Permissions_Service, private breakpointObserver: BreakpointObserver) { }

    onShowRightSideNav() {
        this.helper_service.toggleRighSideNav();
    }
}
