import { Response_User_Login_Finance_Centre } from "./Response_User_Login_Finance_Centre";

export class Response_User_Login {
  public return_url: string;
  public valid_login: boolean;
  public finance_centres: Response_User_Login_Finance_Centre[] = [];
  public default_finance_centre: number;
  public is_error: boolean;
  public error_message: string;
}
