//import { stringify } from "querystring";
import { user_group } from "./User_Group";

export class user {

  id: number;
  user_name: string;
  first_name: string;
  last_name: string;
  enabled: boolean;
  last_login: string;
  photo_url: string;
  default_finance_centre: number;
  user_groups: user_group[] = [];

  name : string;
}
