import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Confirmation_Dialog_Compnent } from "../../.././../../material_ui/components/UI_Dialogs/Confirmation_Dialog/Confirmation_Dialog";

import { web_search_sales_group } from '../../../objects/Web_Search/web_search_sales_group';
import { web_search_sales_group_product } from '../../../objects/Web_Search/web_search_sales_group_product';
import { pagination } from "../../../../../system/objects/pagination";

import { Web_Search_Sales_Group_Service } from '../../../services/Web_Search_Sales_Group/Web_Search_Sales_Group';
import { Helper_Service } from '../../../../../material_ui/services/Core/Helper_Service';

@Component({
  selector: 'web_search_sales_group',
  templateUrl: './web_search_sales_group.html',
  styleUrls: ['web_search_sales_group.scss'],
})
export class Component_Web_Search_Sales_Group {

  web_sales_group_header: string = "";
  web_sales_group_result: web_search_sales_group[];
  pagination_web_sales_group: pagination = new pagination();
  displayedColumns: string[] = ['name', 'edit'];

  // Form & Controls Variables
  sales_group_search: string = "";
  product_search: string = "";
  sales_group_id: number;
  sales_group_name = new UntypedFormControl('', [Validators.required]);
  product_id = new UntypedFormControl('', [Validators.required]);
  sales_group_products: web_search_sales_group_product[] = [];
  sales_group_product_settings = new web_search_sales_group_product;
  selectedProductItems: any[] = [];
  defaultProductItems: any[] = [];
  pageIndex: number = 0;
  pageSize: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  // Flags
  updateButton: boolean = false;
  showSearchBlock: boolean = true;

  constructor(private Web_Search_Sales_Group_Service: Web_Search_Sales_Group_Service, private helper_service: Helper_Service, private dialog: MatDialog) { }

  //#region Web Sales Group Main Screen Functions
  ngOnInit() {
    this.getBackend_Data_Sales_Group(null, null);
  }

  onClick_Sales_Group_Pagination(e: any) {
    this.pageIndex = e.pageIndex + 1;
    this.pageSize = e.pageSize
    this.salesGroupPagination();
  }

  salesGroupPagination() {
    if ((this.pageSize != this.pagination_web_sales_group.page_size) ||
      (this.pagination_web_sales_group.previous_page_link == null && this.pagination_web_sales_group.next_page_link == null)) {
      this.buildSalesGroupData();
    } else {
      // Navigate to specific page
      var url: string = this.pagination_web_sales_group.previous_page_link == null ? this.pagination_web_sales_group.next_page_link : this.pagination_web_sales_group.previous_page_link;
      var newPage: string = "page_number=" + this.pageIndex + "&";
      this.navigateSelectedPage(url.replace(/page_number=.+&/, newPage));
    }
  }

  navigateSelectedPage(pageURL: string) {
    this.Web_Search_Sales_Group_Service.getWeb_Sales_Group(pageURL, null, null).subscribe((res) => {
      this.web_sales_group_result = res.body['web_search_sales_group'];
      this.pagination_web_sales_group = res.body['pagination'];
      this.paginator.pageIndex = this.pagination_web_sales_group.current_page - 1;
      this.paginator.pageSize = this.pagination_web_sales_group.page_size;
    });

  }

  buildSalesGroupData() {
    if (this.sales_group_search.length == 0 && this.product_search.length == 0) {
      this.getBackend_Data_Sales_Group(1, this.pageSize);
    }
    if (this.sales_group_search.length > 0) {
      this.searchWebSalesGroup();
    }
    if (this.product_search.length > 0) {
      this.searchWebSalesProduct();
    }
  }

  getBackend_Data_Sales_Group(page: number, pageSize: number) {
    this.Web_Search_Sales_Group_Service.getWeb_Sales_Group_List(page, pageSize).subscribe((res) => {
      this.web_sales_group_result = res.body['web_search_sales_group'];
      this.pagination_web_sales_group = res.body['pagination'];
      this.pageIndex = this.pagination_web_sales_group.current_page;
      this.pageSize = this.pagination_web_sales_group.page_size;
      this.paginator.pageIndex = this.pagination_web_sales_group.current_page - 1;
      this.paginator.pageSize = this.pageSize;
    });
  }

  searchWebSalesGroup() {
    this.Web_Search_Sales_Group_Service.getWeb_Sales_Group(null, this.sales_group_search, this.pageSize).subscribe((res) => {
      this.web_sales_group_result = res.body['web_search_sales_group'];
      this.pagination_web_sales_group = res.body['pagination'];
      this.pageIndex = this.pagination_web_sales_group.current_page;
      this.pageSize = this.pagination_web_sales_group.page_size;
      this.paginator.pageIndex = this.pagination_web_sales_group.current_page - 1;
      this.paginator.pageSize = this.pagination_web_sales_group.page_size;
    });
  }

  searchWebSalesProduct() {
    this.Web_Search_Sales_Group_Service.getWeb_Sales_Group_For_Product(null, this.product_search, this.pageSize).subscribe((res) => {
      this.web_sales_group_result = res.body['web_search_sales_group'];
      this.pagination_web_sales_group = res.body['pagination'];
      this.pageIndex = this.pagination_web_sales_group.current_page;
      this.pageSize = this.pagination_web_sales_group.page_size;
      this.paginator.pageIndex = this.pagination_web_sales_group.current_page - 1;
      this.paginator.pageSize = this.pagination_web_sales_group.page_size;
    });
  }

  onAdd_Sales_Group() {
    // Bind Variables 
    this.web_sales_group_header = "Enter Details To Add a New Web Sales Group"
    this.selectedProductItems = [];
    this.defaultProductItems = [];
    this.sales_group_products = [];
    this.showSearchBlock = false;
  }

  onEdit_Sales_Group(selected_Sales_Group: web_search_sales_group) {
    // Bind Variables 
    this.web_sales_group_header = "Edit Details: " + selected_Sales_Group.name;
    this.sales_group_id = selected_Sales_Group.id;
    this.sales_group_name.setValue(selected_Sales_Group.name);

    this.sales_group_products = selected_Sales_Group.products;

    // Update Flag
    this.updateButton = true;
    this.showSearchBlock = false;
    // Call Local Funtion
    this.map_Data_Products_Selected();
    this.product_id.setValue(selected_Sales_Group.product_id);
  }

  onDelete_Sales_Group(selected_Sales_Group: web_search_sales_group) {

    const dialogRef = this.dialog.open(Confirmation_Dialog_Compnent, {
      data: { title: "Delete !", text: "Are you sure you want to delete " + selected_Sales_Group.name + "?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        // call delete service
        this.Web_Search_Sales_Group_Service.deleteWeb_Sales_Group(selected_Sales_Group).subscribe(
          (response) => { this.helper_service.openSnackbarNotification(selected_Sales_Group.name + ' has been deleted successfully', 'Close', 'success'); },
          (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
          () => { this.reset_SalesGroupForm() }
        );
      }
    });
  }
  //#endregion

  //#Web Sales Group Detail Screen Functions
  map_Data_Products_Selected() {
    this.selectedProductItems = [];
    this.defaultProductItems = [];
    this.sales_group_products.forEach(obj => {
      this.selectedProductItems.push({ id: obj.product.external_identifier, name: obj.product.company_finance_centre_product_group_product, selected: true, product_id: obj.product_id, not_for_sale: obj.not_for_sale });
      this.defaultProductItems.push({ id: obj.product.id, name: obj.product.company_finance_centre_product_group_product, not_for_sale: obj.not_for_sale });
    });
  }

  onAddProduct(item: any) {
    var product = { name: item.company_finance_centre_product_group_product };
    this.sales_group_products.push({ sales_group_id: this.sales_group_id, product_id: item.id, not_for_sale: item.not_for_sale, sales_group: null, product: null });
    this.defaultProductItems.push({ id: item.id, name: item.company_finance_centre_product_group_product });
    if (this.defaultProductItems.length == 1) {
      this.product_id.setValue(item.id);
    }
  }

  onRemoveProduct(item: any) {
    this.sales_group_products = this.sales_group_products.filter(x => x.product_id != item.product_id);
    this.defaultProductItems = this.defaultProductItems.filter(x => x.id != item.product_id);
    if (this.product_id.value == item.product_id) {
      this.product_id.setValue('');
    }
  }

  onProductSettings(item: any) {
    this.sales_group_product_settings = this.sales_group_products.find(x => x.product_id == item.product_id);
  }

  onSales_Group_Search(value: string) {
    this.sales_group_search = value;
    this.product_search = "";
    this.buildSalesGroupData();
  }

  onProduct_Search(value: string) {
    this.product_search = value;
    this.sales_group_search = "";
    this.buildSalesGroupData();
  }

  onAdd_Update_Sales_Group_Submit() {

    var addUpdateWebSalesGroup = new web_search_sales_group;
    addUpdateWebSalesGroup.id = this.sales_group_id;
    addUpdateWebSalesGroup.name = this.sales_group_name.value;
    addUpdateWebSalesGroup.product_id = this.product_id.value;
    addUpdateWebSalesGroup.products = this.sales_group_products;

    if (this.sales_group_name.valid && this.product_id.valid) {
      //  Update Web Sales Group
      if (this.updateButton) {
        this.Web_Search_Sales_Group_Service.updateWeb_Sales_Group(addUpdateWebSalesGroup).subscribe(
          (response) => { this.helper_service.openSnackbarNotification('You have successfully updated the sales group', 'Close', 'success'); },
          (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
          () => { this.reset_SalesGroupForm() }
        );
      }
      // Create New Web Sales Group
      else {
        this.Web_Search_Sales_Group_Service.addWeb_Sales_Group(addUpdateWebSalesGroup).subscribe(
          (response) => { this.helper_service.openSnackbarNotification('You have successfully added the sales group', 'Close', 'success'); },
          (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); },
          () => { this.reset_SalesGroupForm() }
        );
      }
    }
    else {
      this.sales_group_name.markAsTouched();
    }
  }

  reset_SalesGroupForm() {
    this.salesGroupPagination();
    this.showSearchBlock = true;
    this.updateButton = false;
    this.web_sales_group_header = "";
    this.sales_group_id = 0;
    this.sales_group_name.reset();
    this.selectedProductItems = [];
    this.defaultProductItems = [];
    this.sales_group_products = [];
  }
  //#endregion
}

