import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Material_Module } from "../material_ui/material.module";

import { Call_Audit_Component } from './components/Call_Audit';
import { Page_Not_Found_Component } from './components/Page_Not_Found';
import { Pagination_Component } from './components/Pagination';

import { Call_Info_Service } from './services/Call_Info_Service';
import { Environment_Service } from './services/Environment_Service';
import { Response_Service } from './services/Response_Service';
import { Pagination_Service } from "./services/Pagination_Service";

import { Calendar_Service } from './services/system/Calendar_Service';
import { Date_Service } from './services/system/Date_Service';
import { Service_Cryptography } from './services/system/Cryptography_Service';
import { Service_Security } from './services/system/Security_Service';
import { Guid_Service } from './services/system/Guid_Service';
import { OAuth_Service } from './services/system/OAuth_Service';
import { Permissions_Service } from './services/Permissions_Service';
import { Broadcast_Service } from './services/Broadcast_Service';
import { LocalStorage_Service } from './services/system/LocalStorage_Service'

import { NgbScrollSpy, NgbScrollTarget } from "./components/scrollspy/scrollspy";

import { Auth_Guard } from './guards/Auth_Guard';
import { oAuth_Internal_Identity_Guard } from './guards/oAuth_Internal_Identity_Guard';

@NgModule({
  imports: [CommonModule, FormsModule, HttpClientModule, Material_Module],

  declarations: [Call_Audit_Component, Page_Not_Found_Component, Pagination_Component, NgbScrollSpy, NgbScrollTarget],

  exports: [Call_Audit_Component, Page_Not_Found_Component, Pagination_Component, NgbScrollSpy, NgbScrollTarget, Material_Module]
})
export class Module_System {
  static forRoot(): ModuleWithProviders<Module_System> {
    return {
      ngModule: Module_System,
      providers: [Call_Info_Service, Environment_Service, Response_Service,
        Calendar_Service, Date_Service, Guid_Service, OAuth_Service, Pagination_Service,
        Service_Cryptography, Service_Security, Permissions_Service, Auth_Guard, oAuth_Internal_Identity_Guard,
        Broadcast_Service, LocalStorage_Service]
    };
  }
}

