<div class="sync-list mat-elevation-z8">
    <mat-toolbar>
        <mat-toolbar-row>
            <span>External Server Data Sync</span>
            <span class="pull-right"></span>
            <button mat-icon-button matTooltip="Refresh">
                <mat-icon (click)="reload()">refresh</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <!-- Filter Database results -->
    <div fxLayout="row">
        <div>
            <mat-input-debounce placeholder="Cache Server Search..." (value)="onClick_Cache_Server_Search($event)"
                style="padding:20px">
            </mat-input-debounce>
        </div>
    </div>

    <table class="amd-table" mat-table [dataSource]="cache_server_list">

        <!-- App_id Column -->
        <ng-container matColumnDef="app_id">
            <th mat-header-cell *matHeaderCellDef> App ID </th>
            <td mat-cell *matCellDef="let element"> {{element.app_id | uppercase}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Last Product Sync Column -->
        <ng-container matColumnDef="last_product_sync">
            <th mat-header-cell *matHeaderCellDef> Last Product Sync </th>
            <td mat-cell *matCellDef="let element"> {{element.last_product_sync | date:'dd/MM/yyyy HH:mm:ss'}} </td>
        </ng-container>

        <!-- Last Capacity Sync Column -->
        <ng-container matColumnDef="last_capacity_sync">
            <th mat-header-cell *matHeaderCellDef> Last Capacity Sync </th>
            <td mat-cell *matCellDef="let element">{{element.last_capacity_sync | date:'dd/MM/yyyy HH:mm:ss'}} </td>
        </ng-container>

        <!-- Last Capacity Sync Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Sync Status </th>
            <td mat-cell *matCellDef="let element">
                <!-- Product Sync Status -->
                <mat-icon *ngIf="element.productStatus == 'progress'" color="accent" class="spin" matTooltip="Product Sync Status">sync</mat-icon>
                <mat-icon *ngIf="element.productStatus == 'done'" style="color:green" matTooltip="Product Sync Status">check_circle</mat-icon>
                <mat-icon *ngIf="element.productStatus == 'error'" style="color:red" matTooltip="Product Sync Status">cancel</mat-icon>
                <!-- Capacity Sync Status -->
                <mat-icon *ngIf="element.capacityStatus == 'progress'" color="accent" class="spin" matTooltip="Capacity Sync Status">sync</mat-icon>
                <mat-icon *ngIf="element.capacityStatus == 'done'" style="color:green" matTooltip="Capacity Sync Status">check_circle</mat-icon>
                <mat-icon *ngIf="element.capacityStatus == 'error'" style="color:red" matTooltip="Capacity Sync Status">cancel</mat-icon>
            </td>
        </ng-container>

        <!-- Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Sync Actions </th>
            <td mat-cell *matCellDef="let element">
                <div fxLayout="row" fxLayoutGap="10px">
                    <button color="accent" mat-mini-fab (click)="onRefreshLogin(element)">
                        <mat-icon matTooltip="Refresh API server logins">people</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Pagination -->
    <mat-paginator [length]="pagination_cache_server.total_count" [pageSize]="5" [pageSizeOptions]="[5, 10]" (page)="onClick_Cache_Server_Pagination($event)">
    </mat-paginator>
</div>