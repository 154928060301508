import { Injectable } from '@angular/core';

import { pagination } from "../../system/objects/pagination";

@Injectable()
export class Pagination_Service {

    constructor() { }

    public Array_Paginator(items, page?: string, page_size?: number) {

        var paging = new pagination;

        paging.current_page = Number(page) || 1;
        paging.page_size = page_size || 10;
        paging.total_count = items.length;

        var offset = (paging.current_page - 1) * paging.page_size;
        var paginatedItems = items.slice(offset).slice(0, paging.page_size);
        var total_pages = Math.ceil(items.length / paging.page_size);
        var next_page_link = (total_pages > paging.current_page) ? paging.current_page + 1 : null;
        var previous_page_link = paging.current_page - 1 ? paging.current_page - 1 : null;

        paging.total_pages = total_pages;

        if (next_page_link != null) {
            paging.next_page_link = String(next_page_link);
        }
        if (previous_page_link != null) {
            paging.previous_page_link = String(previous_page_link);
        }
        return {
            pagination: paging,
            data: paginatedItems
        };
    }

}