import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

import { active_call } from "../objects/active_call";
import { pagination } from "../objects/pagination";

import { Call_Info_Service } from "./Call_Info_Service";
import { share } from 'rxjs/operators';

@Injectable()
export class Response_Service {

  constructor(private http: HttpClient, private oAuthService: OAuthService, private Call_Info_Service: Call_Info_Service) {
  }

  private Add_Call_Info(URL: string) {
    var active_call_info = new active_call();
    active_call_info.call_url = URL;

    return this.Call_Info_Service.Add_Active_Call(active_call_info);
  }

  public getResponseInfo<T>(URL: string, Bearer_Token: string): Observable<T> {

    var call_info = this.Add_Call_Info(URL);

    var Headers = new HttpHeaders().set("Authorization", "Bearer " + Bearer_Token);

    var Output = this.http.get<T>(URL, { headers: Headers }).pipe(share());
    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid)
    );

    return Output;
  }

  private bearer_header() {
    var Headers = new HttpHeaders().set("Authorization", "Bearer " + this.oAuthService.getAccessToken());

    return Headers;
  }

  private soap_header() {
    var Headers = new HttpHeaders().set("Content-Type", "application/soap+xml");

    return Headers;
  }

  private getResponse_Paged_Info(URL: string, Bearer_Token: string) {

    var call_info = this.Add_Call_Info(URL);

//    var Output = this.http.get(URL, this.bearer_header());
    var Output = this.http.get(URL, { observe: 'response' }).pipe(share());
    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid));

//    return Output.catch(this.handleError);
    return Output;
  }

  private getResponse_Paged_Info_With_Header(URL: string, header: Map<string, string>) {

    var call_info = this.Add_Call_Info(URL);
    //https://stackoverflow.com/questions/42352854/how-i-add-headers-to-http-get-or-http-post-in-typescript-and-angular-2

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'x-request-tms-api-id': header.get('x-request-tms-api-id'),
      'x-request-tms-call-authentication': header.get('x-request-tms-call-authentication')
    }

    const request_options = {
      //observe: ,
      headers: new HttpHeaders(headerDict),
      responseType : 'json'
    };

    var Output = this.http.get(URL, { observe: 'response', headers: new HttpHeaders(headerDict)}).pipe(share());
    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid));

//    return Output.catch(this.handleError);
    return Output;
  }


  public get_Data_Secure<T>(URL: string, password: string) {
    var call_info = this.Add_Call_Info(URL);

    var Output = this.http.get<T>(URL, { headers: new HttpHeaders().set("x-request-tms-call-authentication", password), observe: "response" }).pipe(share());
    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid)
    );

    return Output;
  }

  public post_Data_Secure<T>(url: string, input_data: any, password: string): Observable<HttpResponse<T>> {
    var call_info = this.Add_Call_Info(url);

    var Output = this.http.post<T>(url, input_data, { headers: new HttpHeaders().set("x-request-tms-call-authentication", password), observe: "response" }).pipe(share());

    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid));

    return Output;
  }

  public post_Data<T>(url: string, input_data: any) {
    var call_info = this.Add_Call_Info(url);

    var Output = this.http.post<T>(url, input_data, { headers: this.bearer_header() , observe: "response" } ).pipe(share());

    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid));

    return Output;
  }

  public post_Data_URL_Encoded(url: string, Post_Body: HttpParams) {
      return this.http.post(url, Post_Body.toString(), { headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")}).pipe(share());
  }

  public put_Data<T>(url: string, input_data: any) {
    var call_info = this.Add_Call_Info(url);

    var Output = this.http.put<T>(url, input_data, { headers: this.bearer_header(), observe: "response" }).pipe(share());

    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid));

    return Output;
  }

  public delete_Data(url: string) {
    var call_info = this.Add_Call_Info(url);

    var Output = this.http.delete(url, { headers: this.bearer_header(), observe: "response" }).pipe(share());
    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid));

    return Output;
  }

  public getPagination_Info(Data_Info: any) : pagination {
    if (Data_Info == null) {
      return new pagination;
    }

    if (Data_Info.headers == null) {
      return new pagination;
    }

    return JSON.parse(Data_Info.headers.get('X-Pagination'));
  }

  public getResponse_Paged(URL: string) {
    return this.getResponse_Paged_Info(URL, this.oAuthService.getAccessToken());
  }

  public getResponse_Paged_With_Header(URL: string, header: Map<string,string>) {
    return this.getResponse_Paged_Info_With_Header(URL, header);
  }

  public getResponse<T>(URL: string): Observable<T> {
    return this.getResponseInfo<T>(URL, this.oAuthService.getAccessToken());
  }

  public getResponse_Anonymous<T>(URL: string) {
    var call_info = this.Add_Call_Info(URL);

    var Output = this.http.get(URL).pipe(share());
    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid)
    );

    return Output;

//    return Output.map(response => <T>response).catch(this.handleError);
  }


  public get_Soap_Data<T>(url: string, input_data: any) {
    var call_info = this.Add_Call_Info(url);

    var Output = this.http.post<T>(url, input_data, { headers: this.soap_header() , observe: "response" } ).pipe(share());

    Output.subscribe(null,
      error => this.Call_Info_Service.Error_Call(call_info.guid, error),
      () => this.Call_Info_Service.Remove_Active_Call(call_info.guid));

    return Output;
  }

  private handleError(error: Response) {
    console.error(error);
    return Observable.throw(error.json() || "Server Error");
  }
}
