import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';


import { list } from '../../../objects/Channel_Management/List_Management/list';
import { list_mode } from '../../../objects/Type/channel_management_list_mode';
import { list_product } from '../../../objects/Channel_Management/List_Management/list_product';
import { pagination } from '../../../../../system/objects/pagination';

import { List_Management_Service } from '../../../services/Channel_Management/List_Management/List';
import { Helper_Service } from '../../../../../material_ui/services/Core/Helper_Service';
import { Confirmation_Dialog_Compnent } from '../../../../../material_ui/components/UI_Dialogs/Confirmation_Dialog/Confirmation_Dialog';



@Component({
    selector: 'channel-management-list',
    templateUrl: './channel_management_list.html'
})
export class Component_Channel_Management_List {

    listForm: UntypedFormGroup;

    list_header: string = "";
    search_text: string = "";
    list_modes: list_mode[];
    channel_management_lists: list[];
    channel_management_list: list = new list;
    list_products: list_product[] = [];
    pagination_list: pagination = new pagination();
    selectedProductItems: any[] = [];
    displayedColumns: string[] = ['name', 'mode', 'edit'];

    // Flags
    updateButton: boolean = false;
    showSearchBlock: boolean = true;

    constructor(private list_management_service: List_Management_Service, private helper_service: Helper_Service, private dialog: MatDialog) { }

    async ngOnInit() {
        // Grid data
        await this.getBackendData(null, null, "");
        // Get list mode data
        this.list_modes = <list_mode[]>await this.list_management_service.getList_Modes(null).toPromise();
    }

    async getBackendData(page_number: number, page_size: number, search: string) {
        var response = await this.list_management_service.getChannel_Management_List(page_number, page_size, search, null).toPromise();
        this.channel_management_lists = response.body["list"];
        this.pagination_list = response.body['pagination'];
    }

    async onClick_List_Pagination(e: any) {
        await this.getBackendData(e.pageIndex + 1, e.pageSize, this.search_text);
    }

    async onList_Search(value: string) {
        this.search_text = value;
        await this.getBackendData(null, null, this.search_text);
    }

    async onAdd_List() {
        // Bind Variables 
        this.list_header = "Enter Details To Add a New Channel Managment List:"
        this.showSearchBlock = false;
        // Populate Form Control
        await this.setListForm(new list);
    }

    async onEdit_List(selected_list: list) {
        // Bind Variables 
        this.list_header = "Edit Details: " + selected_list.name;
        this.channel_management_list = selected_list;

        // Populate Product Control
        this.selectedProductItems = [];
        selected_list.products.forEach(obj => {
            this.selectedProductItems.push(Object({ id: obj.product.external_identifier, name: obj.product.company_finance_centre_product_group_product, selected: true, product_id: obj.product_id }));
            this.list_products.push(Object({ list_id: selected_list.id, product_id: obj.product_id }));
        });
        // Populate Form Control
        await this.setListForm(selected_list);

        // Update Flag
        this.updateButton = true;
        this.showSearchBlock = false;
    }

    async onDelete_List(selected_list: list) {

        const dialogRef = this.dialog.open(Confirmation_Dialog_Compnent, {
            data: { title: "Delete !", text: "Are you sure you want to delete " + selected_list.name + "?" }
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (result == true) {
                // call delete service
                await this.list_management_service.deleteChannel_Management_List(selected_list).toPromise()
                    .then(response => { this.helper_service.openSnackbarNotification(selected_list.name + ' has been deleted successfully', 'Close', 'success'); })
                    .catch((error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); }
                    );
                await this.rest_ListForm();
            }
        });
    }

    async setListForm(listModel: list) {
        this.listForm = new UntypedFormGroup({
            name: new UntypedFormControl(listModel.name, [Validators.required]),
            mode_id: new UntypedFormControl(listModel.mode_id, [Validators.required]),
        });
    }

    async onAdd_Update_List_Submit() {

        this.channel_management_list.name = this.listForm.get('name').value;
        this.channel_management_list.mode_id = this.listForm.get('mode_id').value;
        this.channel_management_list.mode = null;
        this.channel_management_list.products = this.list_products;

        // Check Form Is Valid
        if (this.listForm.valid) {
            await this.list_management_service.addChannel_Management_List(this.channel_management_list).toPromise()
                .then(response => { this.helper_service.openSnackbarNotification('You have successfully updated the channel management list', 'Close', 'success'); })
                .catch((error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); }
                );
            await this.rest_ListForm();
        }
        else {
            const controls = this.listForm.controls;
            Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
        }
    }

    async onAddProduct(item: any) {
        this.list_products.push(Object({ product_id: item.id }));
    }

    async onRemoveProduct(item: any) {
        this.list_products = this.list_products.filter(x => x.product_id != item.product_id);
    }

    async rest_ListForm() {
        this.showSearchBlock = true;
        this.updateButton = false;
        this.list_header = "";
        this.search_text = "";
        this.listForm.reset();
        this.list_products = [];
        this.channel_management_lists = [];
        this.channel_management_list = new list;
        this.selectedProductItems = [];
        await this.ngOnInit();
    }
}
