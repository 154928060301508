<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>money</mat-icon> Price Override
            </h5>
        </mat-card-title>
        <mat-card-title>
            <h6>{{price_override_header}}</h6>
        </mat-card-title>
        <mat-doc-component class="amd-card-help-button" [docLink]="'price-override'"></mat-doc-component>
    </mat-card-header>
    <!-- Main page content -->
    <mat-card-content class="amd-card-content" *ngIf="showSearchBlock">
        <!-- Add new record -->
        <div class="amd-corner-button" style="margin-top: 20px;">
            <button mat-fab color="accent" (click)="onAdd_Price_Override()">
                <mat-icon matTooltip="Add Price Override">add</mat-icon>
            </button>
        </div>
        <!-- Search Record -->
        <div class="mat-elevation-z8">
            <!-- Filter Database results -->
            <div fxLayout="row">
                <div>
                    <mat-input-debounce placeholder="Price Override Search..." (value)="onPrice_Override_Search($event)"
                        style="padding:20px"> </mat-input-debounce>
                </div>
            </div>
            <!-- Result table -->
            <table class="amd-table mat-elevation-z8" mat-table [dataSource]="price_override_list">
                <!-- Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Price Override Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <button color="accent" mat-mini-fab (click)="onEdit_Price_Override(element)">
                                <mat-icon matTooltip="Edit">edit</mat-icon>
                            </button>
                            <button color="warn" mat-mini-fab (click)="onDelete_Price_Override(element)">
                                <mat-icon matTooltip="Delete">delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <!-- Table Header -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!-- Pagination -->
            <mat-paginator [pageIndex]="pagination_price_override.current_page -1" [length]="pagination_price_override.total_count"
                [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" (page)="onClick_Price_Override_Pagination($event)">
            </mat-paginator>
        </div>
    </mat-card-content>
    <!-- Add or Update page content -->
    <mat-card-content class="amd-card-content" *ngIf="!showSearchBlock">
        <!-- Step Control -->
        <mat-horizontal-stepper linear id="stepper" (selectionChange)="stepClick($event)">
            <!-- Step Details -->
            <mat-step [stepControl]="detailFormGroup">
                <form [formGroup]="detailFormGroup">
                    <ng-template matStepLabel>Price Override Detials </ng-template>
                    <!-- Hidden Field -->
                    <input type="hidden" formControlName="id">
                    <div fxLayout="row" fxLayout.xs="column">
                        <div fxFlex=50>
                            <!-- Name Field -->
                            <mat-form-field fxFlex>
                                <input matInput placeholder="Name" formControlName="name">
                                <mat-icon matSuffix>mode_edit</mat-icon>
                                <mat-error *ngIf="detailFormGroup.get('name').invalid">You must enter a value</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column">
                        <div fxFlex=50>
                            <!-- Percentage Field -->
                            <mat-form-field fxFlex>
                                <input type="number" matInput placeholder="Percentage" formControlName="percentage">
                                <mat-icon matSuffix>mode_edit</mat-icon>
                                <mat-error *ngIf="detailFormGroup.get('percentage').invalid">You must enter a value</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </mat-step>
            <!-- Step Cache Servers  -->
            <mat-step [completed]="cacheStepCompleted">
                <form>
                    <ng-template matStepLabel>Cache Servers</ng-template>
                    <mat-dual_list fxFlex [availableItems]="availableCacheServerItems" [(selectededItems)]="selectedCacheServerItems"
                        [availableItemsPagination]="pagination_cache_servers" (availableItemsPage)="onClick_Cache_Servers_Pagination($event)"
                        (availableSearch)=onClick_Cache_Servers_Search($event) (add)="onAddCacheServer($event)"
                        (remove)="onRemoveCacheServer($event)">
                    </mat-dual_list>
                </form>
            </mat-step>
            <!-- Step Products -->
            <mat-step [completed]="productStepCompleted">
                <ng-template matStepLabel>Products</ng-template>
                <product-selection [(selectededItems)]="selectedProductItems" (addProductSelected)="onAddProduct($event)"
                    (removeProductSelected)="onRemoveProduct($event)" [enableSelectedRowAdd]="true" (productSelectedOptionAdd)="onProductSelectForDepartures($event)">
                </product-selection>
            </mat-step>
            <!-- Step Departure -->
            <mat-step>
                <ng-template matStepLabel>Product Departures</ng-template>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex=50 fxLayoutGap="30px">
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="departureDate" placeholder="Choose a date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <button mat-raised-button color="accent" (click)="onAdd_Product_Departure()">
                            <mat-icon>add</mat-icon>
                            Add Departure
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex=50>
                        <mat-table [dataSource]="ds_departures" class="mat-elevation-z8">
                            <!-- Departure Column -->
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef> Departure </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.departure | date: "yyyy-MM-dd"}}
                                </mat-cell>
                            </ng-container>
                            <!-- Action Column -->
                            <ng-container matColumnDef="edit">
                                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <button color="accent" mat-mini-fab (click)="onDelete_Product_Departure(element)">
                                        <mat-icon matTooltip="Delete">delete</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                    </div>
                </div>
            </mat-step>
            <!-- Step Currencies -->
            <mat-step>
                <ng-template matStepLabel>Product Currencies</ng-template>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="50px">
                    <div fxFlex>
                        <div fxLayout="row" fxLayout.xs="column">
                            <div fxFlex fxLayoutGap="30px">
                                <mat-form-field>
                                    <mat-select placeholder="Currency" [(ngModel)]="isoCurrencyCode">
                                        <mat-option *ngFor="let currency of currencies" [value]="currency.iso">
                                            {{currency.currency}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <button mat-raised-button color="accent" (click)="onAdd_Product_Currency()">
                                    <mat-icon>add</mat-icon>
                                    Add Currency
                                </button>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column">
                            <div fxFlex>
                                <mat-table [dataSource]="ds_currencies" class="mat-elevation-z8">
                                    <!-- ISO Currency Code Column -->
                                    <ng-container matColumnDef="name">
                                        <mat-header-cell *matHeaderCellDef> ISO Currency Code </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.iso_currency_code}} </mat-cell>
                                    </ng-container>
                                    <!-- Action Column -->
                                    <ng-container matColumnDef="edit">
                                        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div fxLayout="row" fxLayoutGap="10px">
                                                <button color="accent" mat-mini-fab (click)="onDelete_Product_Currency(element)">
                                                    <mat-icon matTooltip="Delete">delete</mat-icon>
                                                </button>
                                                <button color="accent" mat-mini-fab (click)="onAdd_Product_Currency_Amount(element)">
                                                    <mat-icon matTooltip="Add Amount">add</mat-icon>
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                </mat-table>
                            </div>
                        </div>
                    </div>
                    <div fxFlex>
                        <div *ngIf="product_currency != null">
                            <div fxLayout="row" fxLayout.xs="column">
                                <div fxFlex fxLayoutGap="30px">
                                    <mat-form-field>
                                        <span matPrefix>{{product_currency?.iso_currency_code}} : &nbsp;</span>
                                        <input type="number" matInput placeholder="Amount" value="00.00" [(ngModel)]="currencyAmount">
                                    </mat-form-field>

                                    <button mat-raised-button color="accent" (click)="onAdd_Currency_Amount()">
                                        <mat-icon>add</mat-icon>
                                        Add Amount
                                    </button>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <div fxFlex>
                                    <mat-table [dataSource]="ds_amounts" class="mat-elevation-z8">
                                        <!-- ISO Currency Code Column -->
                                        <ng-container matColumnDef="name">
                                            <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.amount}} </mat-cell>
                                        </ng-container>
                                        <!-- Action Column -->
                                        <ng-container matColumnDef="edit">
                                            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                <button color="accent" mat-mini-fab (click)="onDelete_Currency_Amount(element)">
                                                    <mat-icon matTooltip="Delete">delete</mat-icon>
                                                </button>
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                    </mat-table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>

    </mat-card-content>
    <!--Actions Control  -->
    <mat-card-actions align="center" *ngIf="!showSearchBlock">
        <div class="amd-card-content">
            <button *ngIf="matBackStep" mat-raised-button color="accent" (click)="onStepPrevious()">
                <mat-icon>navigate_before</mat-icon>
                Back
            </button>
            <button *ngIf="matNextStep" mat-raised-button color="accent" (click)="onStepNext()">
                <mat-icon>navigate_next</mat-icon>
                Next
            </button>
            <button mat-raised-button color="accent" (click)="onAdd_Update_Price_Override_Submit()">
                <mat-icon>{{updateButton ? "edit" : "add"}}</mat-icon> {{updateButton ? "Update Price Override" : "Add
                Price
                Override"}}
            </button>
            <button mat-raised-button color="accent" (click)="rest_PriceOverrideForm()">
                <mat-icon>cancel</mat-icon> Cancel
            </button>
        </div>
    </mat-card-actions>

</mat-card>