import { Component, Output, EventEmitter, Input, TemplateRef } from '@angular/core';

import { pagination } from '../../../../../system/objects/pagination';
import { price_override_product } from '../../../objects/Channel_Management/Price_Override/price_override_product';
import { connector } from '../../../objects/Type/connector';
import { product } from '../../../../booking_engine/objects/v1_2/Web_Engine/Product/Product';
import { product_info } from '../../../objects/External/product_info';
import { product_selected } from '../../../objects/External/product_selected';
import { product_selection_filter } from '../../../objects/Common/product_selection_filter';


import { Pagination_Service } from '../../../../../system/services/Pagination_Service';
import { Product_Selection_Service } from '../../../services/Comman/Product_Selection/Product_Selection';


@Component({
    selector: 'product-selection',
    templateUrl: './product_selection.html'
})
export class Component_Product_Selection {

    @Input() selectededItems: any[] = [];
    @Input() enableSelectedRowAdd: boolean = false;
    @Input() selectionFilter: product_selection_filter = null;
    @Input() enableSelectedRowSettings: boolean = false;
    @Input() settingsTemplateRef: TemplateRef<any>;

    @Output() addProductSelected: EventEmitter<any> = new EventEmitter();
    @Output() removeProductSelected: EventEmitter<any> = new EventEmitter();
    @Output() productSelectedOptionAdd: EventEmitter<any> = new EventEmitter();
    @Output() productSelectedOptionSettings: EventEmitter<any> = new EventEmitter();

    productSelected: product_selected;

    availableProductItems: any[] = [];
    pagination_products: pagination = new pagination();
    price_override_products: price_override_product[] = [];

    productSearch: string = "";
    pageNumber: number = 0;
    pageSize: number = 0;
    conector_type_list: connector[] = [];

    companies: any[] = [];
    finance_centres: any[] = [];
    product_groups: any[] = [];
    productItems: any[] = [];

    product_info: string = "";
    selected_connector = null;
    selected_company = null;
    selected_finance_centre = null;
    selected_product_group = null;

    constructor(private Product_Selection_Service: Product_Selection_Service, private Pagination_Service: Pagination_Service) {

    }
    ngOnInit() {
        // Get the list of connector type from backend
        this.Product_Selection_Service.getConnector_Type_List(null).subscribe((res: connector[]) => {
            this.conector_type_list = res;
            if (this.selectionFilter != null) {
                this.setSearchControls(this.selectionFilter);
            }
        });
    }
    onClick_Products_Pagination(e: any) {
        this.pageNumber = e.pageIndex + 1;
        this.pageSize = e.pageSize;
        this.getBackend_Data_Products_Available(this.pageNumber, this.pageSize, this.productSearch);
    }
    onClick_Product_Search(value: string) {
        this.productSearch = value;
        this.pageNumber = 0;
        this.pageSize = 0;
        this.getBackend_Data_Products_Available(this.pageNumber, this.pageSize, this.productSearch);
    }
    getBackend_Data_Products_Available(page: number, pageSize: number, search?: string) {

        var output = this.Pagination_Service.Array_Paginator(this.productItems.filter(x => x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())), page.toString(), pageSize);
        this.pagination_products = output.pagination;
        this.availableProductItems = [];
        this.availableProductItems = output.data;
    }

    setSearchControls(productSelectionFilter: product_selection_filter) {

        if (productSelectionFilter.excluded_connector_id != null) {
            this.conector_type_list = this.conector_type_list.filter(x => x.id != this.selectionFilter.excluded_connector_id);
        }

        if (productSelectionFilter.selected_connector_id != null) {
            this.Product_Selection_Service.getConnector_Product_List(null, productSelectionFilter.selected_connector_id).subscribe((res: product) => {
                this.product_info = res[0].product_info;
                var productInfo = JSON.parse(this.product_info);

                // Filter on connector
                this.selected_connector = productSelectionFilter.selected_connector_id;

                // Filter on company
                productInfo.companies = productInfo.companies.filter(company =>
                    company.name == productSelectionFilter.selected_company
                );
                this.selected_company = productInfo.companies[0].company_identifier;

                // Filter on finance centre
                productInfo.companies.forEach(c => {
                    c.finance_centres = c.finance_centres.filter(fc => fc.name == productSelectionFilter.selected_finance_centre);
                });
                this.selected_finance_centre = productInfo.companies[0].finance_centres[0].finance_centre_identifier;

                // Filter on product group
                productInfo.companies.forEach(c => {
                    c.finance_centres.forEach(fc => {
                        fc.product_groups = fc.product_groups.filter(pg => pg.name == productSelectionFilter.selected_product_group);
                    });
                });
                this.selected_product_group = productInfo.companies[0].finance_centres[0].product_groups[0].product_group_identifier;

                this.selectionFilter = null;
                this.fill_Connector_Products(productInfo);
            });
        };
    }


    onConnectorFilter(item: any) {
        // Get Contector Product Info from Backend
        this.Product_Selection_Service.getConnector_Product_List(null, this.selected_connector).subscribe((res: product) => {
            this.product_info = res[0].product_info;
            this.fill_Connector_Products(JSON.parse(this.product_info));
            this.selected_company = null;
            this.selected_finance_centre = null;
            this.selected_product_group = null;
        });
    }
    onCompanyFilter() {

        var producInfo = JSON.parse(this.product_info);

        // Filter on company
        if (this.selected_company != null) {
            producInfo.companies = producInfo.companies.filter(company => company.company_identifier == this.selected_company);
        }

        //Rest selected filters
        this.selected_finance_centre = null;
        this.selected_product_group = null;
        this.fill_Connector_Products(producInfo);
    }
    onFinanceCentreFilter() {

        var producInfo = JSON.parse(this.product_info);
        // Filter on company
        if (this.selected_company != null) {
            producInfo.companies = producInfo.companies.filter(company => company.company_identifier == this.selected_company);
        }
        // Filter on finance centre
        if (this.selected_finance_centre != null) {
            producInfo.companies.forEach(c => {
                c.finance_centres = c.finance_centres.filter(fc => fc.finance_centre_identifier == this.selected_finance_centre);
            })
        }
        //Rest selected filters
        this.selected_product_group = null

        this.fill_Connector_Products(producInfo);
    }
    onProductGroupFilter() {

        var producInfo = JSON.parse(this.product_info);

        // Filter on company
        if (this.selected_company != null) {
            producInfo.companies = producInfo.companies.filter(company => company.company_identifier == this.selected_company);
        }
        // Filter on finance centre
        if (this.selected_finance_centre != null) {
            producInfo.companies.forEach(c => {
                c.finance_centres = c.finance_centres.filter(fc => fc.finance_centre_identifier == this.selected_finance_centre);
            })
        }
        // Filter on product group
        if (this.selected_product_group != null) {
            producInfo.companies.forEach(c => {
                c.finance_centres.forEach(fc => {
                    fc.product_groups = fc.product_groups.filter(pg => pg.product_group_identifier == this.selected_product_group);
                });
            })
        }

        this.fill_Connector_Products(producInfo);
    }
    fill_Connector_Products(connector_product: product_info) {
        // Reset Variables before populating
        this.companies = [];
        this.finance_centres = [];
        this.product_groups = [];
        this.productItems = [];

        var producInfo: product_info = JSON.parse(this.product_info);
        producInfo.companies.forEach(c => {
            if (c.company_identifier == this.selected_company) {
                c.finance_centres.forEach(fc => {
                    if (fc.finance_centre_identifier == this.selected_finance_centre) {
                        fc.product_groups.forEach(pg => {
                            //Populate data for Product Group Filter DropDown
                            this.product_groups.push({ id: pg.product_group_identifier, name: pg.name });
                        });
                    }
                    //Populate data for finiance centre Filter DropDown
                    this.finance_centres.push({ id: fc.finance_centre_identifier, name: fc.name });
                });
            }
            // Populate data for Company Filter DropDown
            this.companies.push({ id: c.company_identifier, name: c.name });
        });

        connector_product.companies.forEach(company => {
            company.finance_centres.forEach(fc => {
                fc.product_groups.forEach(pg => {
                    pg.products.forEach(prod => {
                            // Get Data for avaliable list
                            this.productItems.push({
                                id: prod.product_identifier,
                                name: prod.name,
                                selected: false,
                                dataType: {
                                    id: null,
                                    connector_id: this.selected_connector,
                                    connector: null,
                                    external_identifier: prod.product_identifier,
                                    company: company.name,
                                    company_finance_centre: fc.name,
                                    company_finance_centre_product_group: pg.name,
                                    company_finance_centre_product_group_product: prod.name,
                                    disabled: false,
                                    disabled_date: null,
                                    external_company_identifier: company.company_identifier
                                }
                            });
                    });
                });
            });
        });

        this.productItems.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() === b.name.toLowerCase() ? 0 : -1);

        this.product_groups.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() === b.name.toLowerCase() ? 0 : -1);

        this.getBackend_Data_Products_Available(this.pageNumber, this.pageSize, this.productSearch);
    }
    onAddProductSelected(item: any) {
        this.Product_Selection_Service.addProduct_Selected(item.dataType).subscribe(res => {
            this.productSelected = <product_selected>res.body
            this.selectededItems.forEach(obj => {
                if (obj.id == item.id) {
                    obj.product_id = this.productSelected.id
                }
            })
            this.addProductSelected.emit(this.productSelected);
        });
    }
    onRemoveProductSelected(item: any) {
        this.removeProductSelected.emit(item);
    }
    onProductSelectedAddOption(item: any) {
        this.productSelectedOptionAdd.emit(item);
    }
    onProductSelectedSettingsOption(item: any) {
        this.productSelectedOptionSettings.emit(item);
    }
}
