import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    text: string;
  }

@Component({
    selector: 'confirmation_dialog',
    templateUrl: 'Confirmation_Dialog.html',
  })
  export class Confirmation_Dialog_Compnent {

    constructor(
      public dialogRef: MatDialogRef<Confirmation_Dialog_Compnent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }