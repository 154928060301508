import { Component, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'mat-input-debounce',
    templateUrl: './Input_Debounce.html'
})
export class MatInputDebounce {
    @Input() placeholder: string;
    @Input() delay: number = 800;
    @Output() value: EventEmitter<string> = new EventEmitter<string>();

    @Input() inputValue: string;

  constructor(private elementRef: ElementRef) {
    const eventStream = fromEvent(elementRef.nativeElement, 'keyup').pipe(debounceTime(this.delay)).subscribe(input => this.value.emit(this.inputValue));
    }

    Reset_Value() {
        this.inputValue = "";
    }
}
