import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { GatewayComponent } from "./main/gateway";
import { Component_Home } from "../../modules/tms/gateway/components/home/home";
import { Component_Web_Engine } from "../../modules/tms/gateway/components/web_search/web_engine/web_engine";
import { Component_Web_Search_Filter } from '../../modules/tms/gateway/components/web_search/web_search_filter/web_search_filter';
import { Component_Web_Search_Sales_Group } from '../../modules/tms/gateway/components/web_search/web_search_sales_group/web_search_sales_group';
import { Component_Affiliate } from '../../modules/tms/gateway/components/affiliate/affiliate/affiliate';
import { Component_Affiliate_Hold_Time } from '../../modules/tms/gateway/components/affiliate/hold_time/hold_time';
import { Component_Audit_Booking } from '../../modules/tms/gateway/components/audit/audit_booking';
import { Component_Price_Override } from '../../modules/tms/gateway/components/channel_management/price_override/price_override';
import { Page_Not_Found_Component } from "../../modules/system/components/Page_Not_Found";
import { Component_User_Profile } from "../../modules/tms/identity/internal/components/User_Profile/User_Profile";
import { Component_Product_List } from "../../modules/tms/gateway/components/channel_management/product/product_list/product_list";
import { Component_Product_Details } from "../../modules/tms/gateway/components/channel_management/product/product_details/product_details";
import { Component_Mapping } from "../../modules/tms/gateway/components/channel_management/mapping/mapping";
import { Component_Channel_Management_List } from '../../modules/tms/gateway/components/channel_management/list/channel_management_list';

import { Auth_Guard } from "../../modules/system/guards/Auth_Guard";
import { Gateway_Maintenance_Guard } from "./guards/Gateway_Maintenance_Guard";



const appRoutes = [
  {
    path: "",
    component: GatewayComponent,
    canActivate: [Auth_Guard],
    children: [
      {
        path: "",
        component: Component_Home,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "web_engine",
        component: Component_Web_Engine,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "web_search_filter",
        component: Component_Web_Search_Filter,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "web_search_sales_group",
        component: Component_Web_Search_Sales_Group,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "affiliate",
        component: Component_Affiliate,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "affiliate_hold_time",
        component: Component_Affiliate_Hold_Time,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "audit_booking",
        component: Component_Audit_Booking,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "price_override",
        component: Component_Price_Override,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "product_list",
        component: Component_Product_List,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "product_details",
        component: Component_Product_Details,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "mapping",
        component: Component_Mapping,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "channel_management_list",
        component: Component_Channel_Management_List,
        canActivate: [Gateway_Maintenance_Guard]
      },
      {
        path: "user_profile",
        component: Component_User_Profile
      }
    ]
  },
  {
    path: "**",
    component: Page_Not_Found_Component
  }
];

export const AppRoutingProviders: any[] = [];
export const AppRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
