import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Permissions_Service } from '../../../modules/system/services/Permissions_Service';



@Injectable()
export class Gateway_Maintenance_Guard implements CanActivate {

  constructor(private Permissions_Service: Permissions_Service) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.Permissions_Service.checkAuthenticationClaims("GATEWAY_MAINTENANCE");
  }
}