import { Component, OnInit } from '@angular/core';

import { Helper_Service } from '../../../services/Core/Helper_Service';

export interface Theme {
    theme_name: string;
    color: string;
    isDark?: boolean;
    isDefault?: boolean;
    isActive?: boolean;
}

@Component({
    selector: 'mat-theme-picker',
    templateUrl: './Theme_Picker.html',
    styleUrls: ['./Theme_Picker.scss']
})
export class Theme_Picker_Component implements OnInit {
    storageKey = 'current_stored_theme';

    themes: Theme[] = [
        {
            theme_name: "indigo",
            color: "#3f51b5",
            isDark: false,
            isDefault: true,
            isActive: false
        },
        {
            theme_name: "orange",
            color: "#ff9800",
            isDark: false,
            isDefault: false,
            isActive: false
        },
        {
            theme_name: "green",
            color: "#43A047",
            isDark: false,
            isDefault: false,
            isActive: false
        },
        {
            theme_name: "brown",
            color: "#795548",
            isDark: false,
            isDefault: false,
            isActive: false
        },
        {
            theme_name: "red",
            color: "#d50000",
            isDark: false,
            isDefault: false,
            isActive: false
        },
        {
            theme_name: "pink",
            color: "#c51162",
            isDark: false,
            isDefault: false,
            isActive: false
        },
        {
            theme_name: "grey",
            color: "#000000",
            isDark: false,
            isDefault: false,
            isActive: false
        }
    ];

    constructor(private helper_service: Helper_Service) { }

    ngOnInit() {
        const storeTheme = this.getStoredTheme();
        if (storeTheme == null) {
            //Inject Default Theme
            this.themes.forEach(theme => {
                if (theme.isDefault == true) {
                    this.applyTheme(theme);
                }
            });
        }
        else {
            this.applyTheme(storeTheme);
        }
    }

    onChangeTheme(theme: Theme) {
        var oldThemeName = this.getStoredTheme();
        // Close SideNav
        this.helper_service.toggleRighSideNav();
        // Remove Old Theme and Add new
        this.removeTheme(oldThemeName);
        this.applyTheme(theme);
        //Store current theme in local storage
        this.storeTheme(theme);
    }

    applyTheme(theme: Theme) {
        try {
            // mark the theme as active
            this.themes.forEach(obj => {
                if (obj.theme_name == theme.theme_name) {
                    obj.isActive = true;
                }
                else {
                    obj.isActive = false;
                }
            });
            // appy theme
            document.body.classList.add(theme.theme_name);

        } catch (e) { }
    }

    removeTheme(theme: Theme) {
        try {
            document.body.classList.remove(theme.theme_name);
        } catch (e) { }
    }

    storeTheme(theme: Theme) {
        try {
            window.localStorage[this.storageKey] = JSON.stringify(theme);
        } catch (e) { }
    }

    getStoredTheme(): Theme {
        try {
            return JSON.parse(window.localStorage[this.storageKey] || null);
        } catch (e) {
            return null;
        }
    }
    clearStorageTheme() {
        try {
            window.localStorage.removeItem(this.storageKey);
        } catch (e) { }
    }

}
