import { Injectable } from '@angular/core';

import * as crypto from "crypto-js";

@Injectable()
export class Service_Cryptography {
  constructor() {
  }

  generate_hash(crypto_key: string, secret: string): string {
    return crypto.HmacSHA256(secret, crypto_key).toString(crypto.enc.Base64);
  }
}
