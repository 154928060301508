<mat-card class="amd-card">
    <mat-card-header class="amd-card-header">
        <mat-card-title>
            <h5>
                <mat-icon>list_alt</mat-icon> List
            </h5>
        </mat-card-title>
        <mat-card-title>
            <h6>{{list_header}}</h6>
        </mat-card-title>
        <mat-doc-component class="amd-card-help-button" [docLink]="'channel_management_list'"></mat-doc-component>
    </mat-card-header>
    <!-- Main page content -->
    <mat-card-content class="amd-card-content" *ngIf="showSearchBlock">
        <!-- Add new record -->
        <div class="amd-corner-button" style="margin-top: 20px;">
            <button mat-fab color="accent" (click)="onAdd_List()">
                <mat-icon matTooltip="Add Item">add</mat-icon>
            </button>
        </div>
        <!-- Search Record -->
        <div class="mat-elevation-z8">
            <!-- Filter Database results -->
            <div fxLayout="row">
                <div>
                    <mat-input-debounce placeholder="List Search..." (value)="onList_Search($event)"
                        style="padding:20px">
                    </mat-input-debounce>
                </div>
            </div>
            <!-- Result table -->
            <table class="amd-table mat-elevation-z8" mat-table [dataSource]="channel_management_lists">
                <!-- Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> List Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="mode">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Mode </th>
                    <td mat-cell *matCellDef="let element"> {{element.mode.name}} </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="amd-thick-font"> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <button color="accent" mat-mini-fab (click)="onEdit_List(element)">
                                <mat-icon matTooltip="Edit">edit</mat-icon>
                            </button>
                            <button color="warn" mat-mini-fab (click)="onDelete_List(element)">
                                <mat-icon matTooltip="Delete">delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <!-- Table Header -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!-- Pagination -->
            <mat-paginator [length]="pagination_list.total_count" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"
                (page)="onClick_List_Pagination($event)">
            </mat-paginator>
        </div>
    </mat-card-content>
    <!-- Add or Update page content -->
    <mat-card-content class="amd-card-content" *ngIf="!showSearchBlock">
        <!-- Form Fields -->
        <form fxLayout="column" [formGroup]="listForm">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex>
                    <input matInput placeholder="Name" formControlName="name">
                    <mat-error *ngIf="listForm.get('name').invalid">You must enter a value</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-select placeholder="Mode" formControlName="mode_id">
                        <mat-option *ngFor="let list_mode of list_modes" [value]="list_mode.id">{{list_mode.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="listForm.get('mode_id').invalid">You must select a value</mat-error>
                </mat-form-field>
            </div>
        </form>
        <!-- Header -->
        <h3 style="text-align: center">List Products</h3>
        <!-- Data List -->
        <div style="margin-bottom: 25px;">
            <!-- Call product selection component -->
            <product-selection [(selectededItems)]="selectedProductItems" (addProductSelected)="onAddProduct($event)"
                (removeProductSelected)="onRemoveProduct($event)"></product-selection>
        </div>
    </mat-card-content>
    <!--Actions Control  -->
    <mat-card-actions align="center" *ngIf="!showSearchBlock">
        <div class="amd-card-content">
            <button mat-raised-button color="accent" (click)="onAdd_Update_List_Submit()">
                <mat-icon>{{updateButton ? "edit" : "add"}}</mat-icon> {{updateButton ? "Update List" : "Add
                List"}}
            </button>
            <button mat-raised-button color="accent" (click)="rest_ListForm()">
                <mat-icon>cancel</mat-icon> Cancel
            </button>
        </div>
    </mat-card-actions>

</mat-card>