import { Component, Input } from '@angular/core';
import { Environment_Service } from '../../../../system/services/Environment_Service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'mat-doc-component',
  templateUrl: './Doc_Component.html'
})
export class Doc_Component {

  @Input() docLink: string = "";
  docURL: string;

  constructor(private Environment_Service: Environment_Service, private Title: Title) {

    switch (this.Title.getTitle()) {

      case "Gateway":
        this.docURL = this.Environment_Service.environment.api_internal_gateway + "/docs/Gateway.html#"
        break;

    }
  }

  openDoc() {
    window.open(this.docURL + this.docLink)
  }

}
