import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Request_User_Login } from "../objects/Login/Request_User_Login";
import { Response_User_Login } from "../objects/Login/Response_User_Login";
import { Response_User_Logout } from "../objects/Login/Response_User_Logout";

import { Environment_Service } from "../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../system/services/Response_Service";

import { error_message } from "../../../../system/objects/identity/error_message";

import { OAuth_Service } from "../../../../system/services/system/OAuth_Service";

@Injectable()
export class Service_Login {

  response_user_login: Response_User_Login = new Response_User_Login();
  response_user_logout: Response_User_Logout = new Response_User_Logout();

  error_message: error_message = new error_message();

  constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service, private OAuth_Service: OAuth_Service) {
  }

  getError_Message(errorId: string) {
    return this.OAuth_Service.getError_Message(errorId).subscribe(error => this.error_message = error);
  }

  getFederated_Service_Login(return_url: string) {
    if (this.Environment_Service.environment.identity_realm + '' != '') {
      window.location.href = this.Environment_Service.environment.identity_server + "/api/v1/identity/login/" + this.Environment_Service.environment.identity_realm + "?return_url=" + return_url;
    };
  }

  getLogin(User_Name: string, Password: string, finance_centre_id: number, return_url: string) {
    var User_Login: Request_User_Login = new Request_User_Login();
    User_Login.user_name = User_Name;
    User_Login.password = Password;
    User_Login.finance_centre_id = finance_centre_id;
    User_Login.return_url = return_url;

    return this.Response_Service.post_Data<Response_User_Login>(this.Environment_Service.environment.identity_server + "/api/v1/identity/login", User_Login).subscribe((f) => {
      this.response_user_login = f.body;
      if (this.response_user_login.valid_login == true) {
        window.location.href = this.response_user_login.return_url;
      }
    });
  }

  getLogout(logout_id: string) {
    return this.Response_Service.post_Data<Response_User_Logout>(this.Environment_Service.environment.identity_server + "/api/v1/identity/logout?logoutId=" + logout_id, null).subscribe((f) => {
      this.response_user_logout = f.body;
      if (this.response_user_logout.return_url != null) {
        window.location.href = this.response_user_logout.return_url;
      }
    });
  }
}
