import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';

import { menu_list_item } from '../../../modules/material_ui/objects/Menu_List/menu_list_item';

import { Call_Info_Service } from "../../../modules/system/services/Call_Info_Service";
import { OAuth_Service } from '../../../modules/system/services/system/OAuth_Service';
import { Helper_Service } from '../../../modules/material_ui/services/Core/Helper_Service';
import { Environment_Service } from '../../../modules/system/services/Environment_Service';


@Component({
  selector: 'app-main',
  templateUrl: './gateway.html',
  styleUrls: ['./gateway.sass']
})

export class GatewayComponent implements OnInit {

  @ViewChild('leftSideNav', { static: true }) leftSideNav: ElementRef;
  @ViewChild('rightSideNav', { static: true }) rightSideNav: MatSidenav;

  navItems: menu_list_item[];

  // Detect Screen Size
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  LegacyMode: boolean;
  showProgress: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, public oauth_Service: OAuth_Service, private call_info_service: Call_Info_Service,
    private router: Router, public title: Title, private breakpointObserver: BreakpointObserver,
    private helper_service: Helper_Service, private environment_service: Environment_Service) {

    this.oauth_Service.initialise_OAuth();
    environment_service.environment.environment_name.includes("staging") ? this.title.setTitle("Staging - Gateway") : this.title.setTitle("Gateway");
  }

  ngOnInit() {
    // Get Side Nav Menus
    this.helper_service.getDataFromLocalJsonFile("../assets/app-menus.json").subscribe((res: menu_list_item[]) => {
      this.navItems = res;
    });
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let legacyModeParam = params['legacymode'];
      if (legacyModeParam === 'true') {
        this.LegacyMode = true;
      }
    });

    this.helper_service.rightSideNav = this.rightSideNav;
    this.helper_service.leftSideNav = this.leftSideNav;
  }

  ngDoCheck() {
    // Show the progress bar and wait cursor if any call is in queue
    document.body.style.cursor = 'default';
    if (this.call_info_service.active_calls.length > 0) {
      this.showProgress = true;
      document.body.style.cursor = 'wait';
    }
    else {
      this.showProgress = false;
    }
  }

  isActive(data: any[]): boolean {
    return this.router.isActive(this.router.createUrlTree(data), true);
  }

}
