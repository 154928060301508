//import { Injectable, SystemJsNgModuleLoader } from '@angular/core';
import { Injectable } from '@angular/core';

@Injectable()
export class Date_Service {

  month_names: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  day_names: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  constructor() {
  }

  is_today(test_date: Date): boolean {
    return test_date.getDate() == new Date(Date.now()).getDate() && test_date.getMonth() == new Date(Date.now()).getMonth() && test_date.getFullYear() == new Date(Date.now()).getFullYear();
  }

  string_date(test_date: Date): string {
    return test_date.getFullYear() + "-" + (test_date.getMonth() + 1) + "-" + (test_date.getDate());
  }

  string_date_output(test_date: Date): string {
    return test_date.getFullYear() + "-" + this.padded_date(test_date.getMonth()) + "-" + this.padded_date(test_date.getDate());
  }

  compare_date(date: string): string {
    var input_date: Date = new Date(date);
  
    return input_date.getFullYear() + "-" + this.padded_date(input_date.getMonth()) + "-" + this.padded_date(input_date.getDate());
  }

  output_date(date: string): string {
    var input_date: Date = new Date(date);
  
    return input_date.getFullYear() + "-" + this.padded_date(input_date.getMonth() + 1) + "-" + this.padded_date(input_date.getDate());
  }

  padded_date(input_info: number): string {
    return ("0" + input_info).substr(input_info.toString().length - 1);
  }

  full_date(date: string): string {
    if (date == undefined) { return ""; }

    var date_string = new Date(date);

    return this.day_names[date_string.getDay()] + " " + date_string.getDate() + " " + this.month_names[date_string.getMonth()] + " " + date_string.getFullYear();
  }

  remove_time(date: Date) {
    return date.setHours(0, 0, 0, 0);
  }

  previous_monday(from_date: Date): Date {

    var mDifference = from_date.getDay() - 1;

    if (mDifference < 0) {
      mDifference += 7;
    }

    from_date.setDate(from_date.getDate() + (mDifference * -1));

    return from_date;
  }

  next_month(year: number, month: number): Date {
    var Current_Month = new Date(year, month, 1);

    return Current_Month;
  }

  month_name_month(month: number): string {
    return this.month_names[month - 1];
  }

  month_name_date(date: Date): string {
    return this.month_names[date.getMonth()];
  }

  utc_time_from_1970(): number {
    return new Date(Date.now()).getTime() / 1000 | 0;
  }

  getReturnDate(startDate: string, duration: number) : string {
    if (startDate == undefined || startDate == "") return "";

    var retrunDate = new Date(startDate);
    retrunDate.setDate(retrunDate.getDate() + (duration - 1));
    
    return this.string_date(retrunDate);
  }

  getReturnDateAsLongString(startDate: string, duration: number) : string {
    var returnDate : string = this.getReturnDate(startDate, duration);
    return this.full_date(returnDate);
  }


}
