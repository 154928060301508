import { Injectable } from '@angular/core';

@Injectable()
export class Guid_Service {
  constructor() {
  }

  public MakeNew(): string {
    var result: string;
    var i: string;
    var j: number;

    result = "";
    for (j = 0; j < 32; j++) {
      if (j == 8 || j == 12 || j == 16 || j == 20)
        result = result + '-';
      i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

  public MakeNew_Plain(): string {
    var result: string;
    var i: string;
    var j: number;

    result = "";
    for (j = 0; j < 32; j++) {
      i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

}
