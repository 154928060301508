import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators, FormGroup } from '@angular/forms';

import { pagination } from '../../../../../../system/objects/pagination';
import { connector } from '../../../../objects/Type/connector';
import { product } from '../../../../../booking_engine/objects/v1_2/Web_Engine/Product/Product';
import { product_info } from '../../../../objects/External/product_info';
import { product_selected } from '../../../../objects/External/product_selected';
import { supplier_product } from '../../../../objects/Product/supplier_product';
import { web_search_sales_group_product } from '../../../../objects/Web_Search/web_search_sales_group_product';
import { product_selection_filter } from '../../../../objects/Common/product_selection_filter';

import { Pagination_Service } from '../../../../../../system/services/Pagination_Service';
import { Product_Selection_Service } from '../../../../services/Comman/Product_Selection/Product_Selection';
import { Helper_Service } from '../../../../../../material_ui/services/Core/Helper_Service';



@Component({
    selector: 'product_details',
    templateUrl: './product_details.html'
})

export class Component_Product_Details implements OnInit {
    private sub: any;

    product_header: string = "";
    productSelected: product_selected;
    supplierProduct: supplier_product;

    // Form & Controls Variables
    company = new UntypedFormControl({ value: '', disabled: true });
    company_finance_centre = new UntypedFormControl({ value: '', disabled: true });
    company_finance_centre_product_group = new UntypedFormControl({ value: '', disabled: true });
    external_identifier = new UntypedFormControl({ value: '', disabled: true });
    external_company_identifier = new UntypedFormControl({ value: '', disabled: true });
    connector_name = new UntypedFormControl({ value: '', disabled: true });

    // Linked Product Selection
    selectedProductItems: any[] = [];
    linkedProducts: any[] = [];
    productSelectionFilter: product_selection_filter = new product_selection_filter();

    constructor(private Product_Selection_Service: Product_Selection_Service, private router: Router, private helper_service: Helper_Service) {
    }

    ngOnInit() {
        var data = this.Product_Selection_Service.getProductData();
        this.supplierProduct = this.Product_Selection_Service.getSupplierProductData();
        this.setFormFields(data);
        this.selectedProductItems = [];
        if (this.supplierProduct != null && this.supplierProduct.product_supplier_id != null) {
            this.selectedProductItems.push({
                id: this.supplierProduct.supplier_product_code,
                product_id: this.supplierProduct.product_supplier_id,
                name: this.supplierProduct.supplier_product_name,
                selected: true
            });
            this.linkedProducts.push({ product_id: this.supplierProduct.product_supplier_id });
            this.productSelectionFilter.selected_connector_id = this.supplierProduct.supplier_product_connector_id;
            this.productSelectionFilter.selected_company = this.supplierProduct.supplier_product_company;
            this.productSelectionFilter.selected_finance_centre = this.supplierProduct.supplier_product_finance_centre;
            this.productSelectionFilter.selected_product_group = this.supplierProduct.supplier_product_product_group;
        }
    }

    setFormFields(data: any) {
        this.product_header = "Edit Linked Product: " + data.company_finance_centre_product_group_product;
        this.productSelectionFilter.excluded_connector_id = data.connector_id;
        this.connector_name.setValue(data.connector);
        this.company.setValue(data.company);
        this.company_finance_centre.setValue(data.company_finance_centre);
        this.company_finance_centre_product_group.setValue(data.company_finance_centre_product_group);
        this.external_identifier.setValue(data.external_identifier);
        this.external_company_identifier.setValue(data.external_company_identifier);
    }

    onCancel() {
        this.router.navigate(['/product_list']);
    }

    onAdd_Update() {
        if (this.linkedProducts.length > 1) {
            this.helper_service.openSnackbarNotification('Only one supplier product can be selected!', 'Close', 'error');
            return;
        } else if (this.linkedProducts.length == 1) {
            this.supplierProduct.product_supplier_id = this.linkedProducts[0].product_id;
        } else {
            this.supplierProduct.product_supplier_id = null;
        }
        this.Product_Selection_Service.addSupplierProduct(this.supplierProduct).subscribe(
            (response) => {
                this.helper_service.openSnackbarNotification('You have successfully updated the linked product', 'Close', 'success');
                this.router.navigate(['/product_list']);
            },
            (error) => { this.helper_service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); }
        );
    }

    onAddProduct(item: any) {
        this.linkedProducts.push({ product_id: item.id });
        if (this.linkedProducts.length > 1) {
            this.helper_service.openSnackbarNotification('Only one supplier product can be selected!', 'Close', 'error');
        }

    }

    onRemoveProduct(item: any) {
        this.linkedProducts = this.linkedProducts.filter(x => x.product_id != item.product_id);
    }

}
