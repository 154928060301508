<div class="toolbar-app-switch-container">
  <button mat-icon-button (click)="isOpen = !isOpen" class="toolbar-user-btn" [class.open]="isOpen">
    <span fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>apps</mat-icon>
    </span>
  </button>

  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content">
      <div class="applications" perfectscrollbar="">
        <h5>Applications</h5>
        <mat-divider></mat-divider>
        <perfect-scrollbar style="max-height: 215px;">
          <mat-grid-list cols="3" rowHeight="1:1">
            <mat-grid-tile *ngFor="let app of apps">
              <div class="item">
                <div class="group">
                  <a [href]="app.app_link">
                    <img [src]="app.app_icon" alt="" />
                    <p>{{app.app_name}}</p>
                  </a>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>
