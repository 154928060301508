import { Injectable } from '@angular/core';
//import { Response } from '@angular/common/http';

import { active_call } from "../objects/active_call";

import { Guid_Service } from "./system/Guid_Service";

@Injectable()
export class Call_Info_Service {

  active_calls: active_call[];
  error_calls: active_call[];
  audit_calls: active_call[];
  logged_in: boolean = true;
  showProgress : boolean = false;

  constructor(private guid: Guid_Service) {
    this.active_calls = [];
    this.error_calls = [];
    this.audit_calls = [];
  }

  Add_Active_Call(active_call: active_call) {
    active_call.guid = this.guid.MakeNew();
    active_call.start_time = Date.now();

    this.active_calls.unshift(active_call);

    return active_call;
  }

  Error_Call(guid: string, error_info: any) {
    var current_call = this.active_calls.find(f => f.guid == guid);
    current_call.error_message = error_info.toString();
    current_call.status_code = error_info.status;
    current_call.end_time = Date.now();

    this.error_calls.unshift(current_call);
    this.Remove_Active_Call(guid);

    if (error_info.status == 401) {
      this.logged_in = false;
    }
  }

  Remove_Active_Call(guid: string) {
    var Index = this.active_calls.findIndex(f => f.guid == guid);
    if (Index > -1) {
      var current_call = this.active_calls.find(f => f.guid == guid);
      current_call.end_time = Date.now();
      current_call.processing_milliseconds = current_call.end_time - current_call.start_time;

      this.audit_calls.unshift(current_call);

      this.active_calls.splice(Index, 1);
    }
  }

  Currently_Logged_In(): boolean {
    return this.logged_in;
  }
}
