import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeDifference' })

export class Time_Difference_Pipe implements PipeTransform {
    transform(firstDate: string, secondDate: string): string {
        var startDate = new Date(firstDate);
        var endDate   = new Date(secondDate);
        var seconds = Math.round((endDate.getTime() - startDate.getTime())/1000);
        return seconds > 0 ? seconds.toString() : "";
    }
}