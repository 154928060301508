import { Injectable } from '@angular/core';

import { price_override } from '../../../objects/Channel_Management/Price_Override/price_override';

import { Environment_Service } from "../../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../../system/services/Response_Service";




@Injectable()
export class Price_Override_Service {

    constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

    getPrice_Override_List(page_number: number, page_size: number) {

        if (page_number != null && page_size != null) {
            return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/price_override?page_number=" + page_number + "&page_size=" + page_size);
        }
        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/price_override");
    }
    getWPrice_Override_Search(paged_url: string, Search: string) {
        if (paged_url != null) {
            return this.Response_Service.getResponse_Paged(paged_url);
        }

        var search_options: string[] = [];

        if (Search != "") {
            search_options.push("search=" + Search);
        }


        var search_option: string = "";

        for (var index = 0; index < search_options.length; index++) {
            search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
        }
        return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/price_override" + search_option);
    }
    addPriceOverride(new_price_override: price_override) {
        return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/price_override", new_price_override);
    }
    deletePriceOverride(price_override: price_override) {
        return this.Response_Service.delete_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/channel_management/price_override/" + price_override.id);
    }

}
