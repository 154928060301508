import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { pagination } from '../../../../../../system/objects/pagination';
import { connector } from '../../../../objects/Type/connector';
import { product } from '../../../../../booking_engine/objects/v1_2/Web_Engine/Product/Product';
import { product_info } from '../../../../objects/External/product_info';
import { cache_server } from '../../../../objects/Type/cache_server';
import { password_hash_response } from "../../../../../../system/objects/password_hash_response";

import { Pagination_Service } from '../../../../../../system/services/Pagination_Service';
import { Product_Selection_Service } from '../../../../services/Comman/Product_Selection/Product_Selection';
import { Cache_Server_Service } from '../../../../services/Type_Cache_Server/Cache_Server';
import { Helper_Service } from '../../../../../../material_ui/services/Core/Helper_Service';

import { product_selected } from '../../../../objects/External/product_selected';
import { supplier_product } from '../../../../objects/Product/supplier_product';


@Component({
    selector: 'product_list',
    templateUrl: './product_list.html'
})

export class Component_Product_List implements OnInit {

    product_info: string = "";
    selected_connector = null;
    selected_connector_name: string = "";
    selected_company = null;
    selected_finance_centre = null;
    selected_product_group = null;

    productSearch: string = "";
    pageNumber: number = 0;
    pageSize: number = 0;
    conector_type_list: connector[] = [];

    companies: any[] = [];
    finance_centres: any[] = [];
    product_groups: any[] = [];
    productItems: any[] = [];

    displayedColumns: string[] = ['name', 'actions'];
    pagination_product: pagination = new pagination();
    pagedProductItems: any[] = [];

    cache_server_list: cache_server[] = [];

    product_header: string = "";

    constructor(private Product_Selection_Service: Product_Selection_Service, private Pagination_Service: Pagination_Service, private route: ActivatedRoute, private router: Router, private Cache_Server_Service: Cache_Server_Service, private Helper_Service: Helper_Service) {
    }

    ngOnInit() {
        // Get the list of connector type from backend
        this.Product_Selection_Service.getConnector_Type_List(null).subscribe((res: connector[]) => {
            this.conector_type_list = res;
            var productData = this.Product_Selection_Service.getProductData();
            if (productData != null) {
                this.setSearchControls(productData);
            }
        });
    }

    onProduct_Search(value: string) {
        this.productSearch = value;
        this.pageNumber = 0;
        this.pageSize = 0;
        this.getBackend_Data_Products_Available(this.pageNumber, this.pageSize, this.productSearch);

    }

    setSearchControls(productData: any) {
        this.Product_Selection_Service.setProductData(null);

        this.Product_Selection_Service.getConnector_Product_List(null, productData.connector_id).subscribe((res: product) => {
            this.product_info = res[0].product_info;
            var producInfo = JSON.parse(this.product_info);

            // Filter on connector
            this.selected_connector = productData.connector_id;
            this.selected_connector_name = productData.connector;

            // Filter on company
            this.selected_company = productData.company_id;
            producInfo.companies = producInfo.companies.filter(company => company.company_identifier == this.selected_company);

            // Filter on finance centre
            this.selected_finance_centre = productData.company_finance_centre_id;
            producInfo.companies.forEach(c => {
                c.finance_centres = c.finance_centres.filter(fc => fc.finance_centre_identifier == this.selected_finance_centre);
            });
            // Filter on product group
            this.selected_product_group = productData.company_finance_centre_product_group_id;
            producInfo.companies.forEach(c => {
                c.finance_centres.forEach(fc => {
                    fc.product_groups = fc.product_groups.filter(pg => pg.product_group_identifier == this.selected_product_group);
                });
            });

            this.fill_Connector_Products(producInfo);
        });


    }

    onConnectorFilter() {
        // Get Contector Product Info from Backend
        this.selected_connector_name = this.conector_type_list.find(conn => conn.id == this.selected_connector).name;
        this.Product_Selection_Service.getConnector_Product_List(null, this.selected_connector).subscribe((res: product) => {
            this.product_info = res[0].product_info;
            this.fill_Connector_Products(JSON.parse(this.product_info));
            this.selected_company = null;
            this.selected_finance_centre = null;
            this.selected_product_group = null;
        });
    }

    onCompanyFilter() {

        var producInfo = JSON.parse(this.product_info);

        // Filter on company
        if (this.selected_company != null) {
            producInfo.companies = producInfo.companies.filter(company => company.company_identifier == this.selected_company);
        }

        //Rest selected filters
        this.selected_finance_centre = null;
        this.selected_product_group = null;
        this.fill_Connector_Products(producInfo);
    }

    onFinanceCentreFilter() {

        var producInfo = JSON.parse(this.product_info);
        // Filter on company
        if (this.selected_company != null) {
            producInfo.companies = producInfo.companies.filter(company => company.company_identifier == this.selected_company);
        }
        // Filter on finance centre
        if (this.selected_finance_centre != null) {
            producInfo.companies.forEach(c => {
                c.finance_centres = c.finance_centres.filter(fc => fc.finance_centre_identifier == this.selected_finance_centre);
            })
        }
        //Rest selected filters
        this.selected_product_group = null

        this.fill_Connector_Products(producInfo);
    }

    onProductGroupFilter() {

        var producInfo = JSON.parse(this.product_info);

        // Filter on company
        if (this.selected_company != null) {
            producInfo.companies = producInfo.companies.filter(company => company.company_identifier == this.selected_company);
        }
        // Filter on finance centre
        if (this.selected_finance_centre != null) {
            producInfo.companies.forEach(c => {
                c.finance_centres = c.finance_centres.filter(fc => fc.finance_centre_identifier == this.selected_finance_centre);
            })
        }
        // Filter on product group
        if (this.selected_product_group != null) {
            producInfo.companies.forEach(c => {
                c.finance_centres.forEach(fc => {
                    fc.product_groups = fc.product_groups.filter(pg => pg.product_group_identifier == this.selected_product_group);
                });
            })
        }

        this.fill_Connector_Products(producInfo);
    }

    fill_Connector_Products(connector_product: product_info) {
        // Reset Variables before populating
        this.companies = [];
        this.finance_centres = [];
        this.product_groups = [];
        this.productItems = [];

        var producInfo: product_info = JSON.parse(this.product_info);
        producInfo.companies.forEach(c => {
            if (c.company_identifier == this.selected_company) {
                c.finance_centres.forEach(fc => {
                    if (fc.finance_centre_identifier == this.selected_finance_centre) {
                        fc.product_groups.forEach(pg => {
                            //Populate data for Product Group Filter DropDown
                            if (!(pg.name.toLowerCase().startsWith('x'))) {
                                this.product_groups.push({ id: pg.product_group_identifier, name: pg.name });
                            }
                        });
                    }
                    //Populate data for finiance centre Filter DropDown
                    this.finance_centres.push({ id: fc.finance_centre_identifier, name: fc.name });
                });
            }
            // Populate data for Company Filter DropDown
            this.companies.push({ id: c.company_identifier, name: c.name });
        });

        connector_product.companies.forEach(company => {
            company.finance_centres.forEach(fc => {
                fc.product_groups.forEach(pg => {
                    pg.products.forEach(prod => {
                        if (!(prod.name.toLowerCase().startsWith('x'))) {
                            // Get Data for avaliable list
                            this.productItems.push({
                                id: prod.product_identifier,
                                name: prod.name,
                                selected: false,
                                dataType: {
                                    id: null,
                                    connector_id: this.selected_connector,
                                    connector: this.selected_connector_name,
                                    external_identifier: prod.product_identifier,
                                    company_id: company.company_identifier,
                                    company: company.name,
                                    company_finance_centre_id: fc.finance_centre_identifier,
                                    company_finance_centre: fc.name,
                                    company_finance_centre_product_group_id: pg.product_group_identifier,
                                    company_finance_centre_product_group: pg.name,
                                    company_finance_centre_product_group_product: prod.name,
                                    disabled: false,
                                    disabled_date: null,
                                    external_company_identifier: company.company_identifier
                                }
                            });
                        }
                    });
                });
            });
        });

        this.productItems.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() === b.name.toLowerCase() ? 0 : -1);
        this.product_groups.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() === b.name.toLowerCase() ? 0 : -1);

        this.getBackend_Data_Products_Available(this.pageNumber, this.pageSize, this.productSearch);
    }

    getBackend_Data_Products_Available(page: number, pageSize: number, search?: string) {

        var output = this.Pagination_Service.Array_Paginator(this.productItems.filter(x => x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())), page.toString(), pageSize);
        this.pagination_product = output.pagination;
        this.pagedProductItems = [];
        this.pagedProductItems = output.data;
    }

    onClick_Product_Pagination(e: any) {
        this.pageNumber = e.pageIndex + 1;
        this.pageSize = e.pageSize;
        this.getBackend_Data_Products_Available(this.pageNumber, this.pageSize, this.productSearch);
    }

    onEdit_Product(item: any) {
        var savedConnector: string = item.dataType.connector;
        item.dataType.connector = null;
        this.Product_Selection_Service.setSupplierProductData(null);
        this.Product_Selection_Service.addProduct_Selected(item.dataType).subscribe(res => {
            var productSelected = <product_selected>res.body;
            item.dataType.connector = savedConnector;
            this.Product_Selection_Service.getSupplierProduct(productSelected.id).subscribe(res => {
                var supplierProduct = <supplier_product>res;
                if (supplierProduct != null) {
                    this.Product_Selection_Service.setSupplierProductData(supplierProduct);
                }
                this.Product_Selection_Service.setProductData(item.dataType);
                this.router.navigate(['/product_details']);
            });
        });
    }

    onRefreshCapacity(item: any) {
        this.Cache_Server_Service.getCache_Server_List(1, 99).subscribe((res) => {
            this.cache_server_list = res.body['cache_server'];
            item.dataType.connector = null;
            this.Product_Selection_Service.addProduct_Selected(item.dataType).subscribe(res => {
                var productSelected = <product_selected>res.body;
                this.cache_server_list.forEach(cache_server => {
                    this.Cache_Server_Service.getPasswordHash().subscribe(res => {
                        var passwordHashResponse = <password_hash_response>res.body;
                        this.Cache_Server_Service.singleCapacitySync(cache_server.app_id, productSelected.id, passwordHashResponse.password_hash).subscribe(
                            (response) => {
                                this.Helper_Service.openSnackbarNotification('Product capacity refresh for ' + productSelected.company_finance_centre_product_group_product + ' queued', 'Close', 'success');
                            },
                            (error) => { this.Helper_Service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); }
                        );
                    });
                    this.Cache_Server_Service.getPasswordHash().subscribe(res => {
                        var passwordHashResponse = <password_hash_response>res.body;
                        this.Cache_Server_Service.singleCapacitySyncV13(cache_server.app_id, productSelected.id, passwordHashResponse.password_hash).subscribe(
                            (response) => {
                                this.Helper_Service.openSnackbarNotification('Product capacity refresh for ' + productSelected.company_finance_centre_product_group_product + ' queued', 'Close', 'success');
                            },
                            (error) => { this.Helper_Service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); }
                        );
                    });
                });
            });
        });
    }

    onRefreshDescription(item: any) {
        this.Cache_Server_Service.getCache_Server_List(1, 99).subscribe((res) => {
            this.cache_server_list = res.body['cache_server'];
            item.dataType.connector = null;
            this.Product_Selection_Service.addProduct_Selected(item.dataType).subscribe(res => {
                var productSelected = <product_selected>res.body;
                this.cache_server_list.forEach(cache_server => {
                    this.Cache_Server_Service.getPasswordHash().subscribe(res => {
                        var passwordHashResponse = <password_hash_response>res.body;
                        this.Cache_Server_Service.singleProductSync(cache_server.app_id, productSelected.id, passwordHashResponse.password_hash).subscribe(
                            (response) => {
                                this.Helper_Service.openSnackbarNotification('Product description refresh for ' + productSelected.company_finance_centre_product_group_product + ' queued', 'Close', 'success');
                            },
                            (error) => { this.Helper_Service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); }
                        );
                    });
                    this.Cache_Server_Service.getPasswordHash().subscribe(res => {
                        var passwordHashResponse = <password_hash_response>res.body;
                        this.Cache_Server_Service.singleProductSyncV13(cache_server.app_id, productSelected.id, passwordHashResponse.password_hash).subscribe(
                            (response) => {
                                this.Helper_Service.openSnackbarNotification('Product description refresh for ' + productSelected.company_finance_centre_product_group_product + ' queued', 'Close', 'success');
                            },
                            (error) => { this.Helper_Service.openSnackbarNotification('Something has gone wrong!', 'Close', 'error'); }
                        );
                    });
                });
            });
        });
    }
}
