import { Injectable } from '@angular/core';

import { group } from "../../../objects/maintenance/Group/Group";

import { Environment_Service } from "../../../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../../../system/services/Response_Service";

@Injectable()
export class Group_Service {

  constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

  getGroup_List(paged_url: string) {

    if (paged_url != null) {
      return this.Response_Service.getResponse_Paged(paged_url);
    }

    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/group");
  }
  getGroups(page_number:number,page_size:number, Search: string) {
  
    if (page_number != null && page_size != null) {
      return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/group?page_number="+ page_number +"&page_size=" + page_size);
    }

    var search_options: string[] = [];

    if (Search != "") {
      search_options.push("search=" + Search);
    }
    

    var search_option: string = "";

    for (var index = 0; index < search_options.length; index++) {
      search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/group" + search_option);
  }
  getOperators(paged_url: string) {

    if (paged_url != null) {
      return this.Response_Service.getResponse(paged_url);
    }
    return this.Response_Service.getResponse(this.Environment_Service.environment.identity_server + "/api/v1/company");
  }
  getCurrencies(paged_url: string) {

    if (paged_url != null) {
      return this.Response_Service.getResponse(paged_url);
    }
    return this.Response_Service.getResponse(this.Environment_Service.environment.identity_server + "/api/v1/currency");
  }
  getSecurityClaim(paged_url: string) {

    if (paged_url != null) {
      return this.Response_Service.getResponse(paged_url);
    }
    return this.Response_Service.getResponse(this.Environment_Service.environment.identity_server + "/api/v1/security-claim");
  }
  updateGroup(groupId: number, new_group: group) {
    return this.Response_Service.put_Data(this.Environment_Service.environment.identity_server + "/api/v1/group/" + groupId, new_group);
  }
  addGroup(new_group: group) {
    return this.Response_Service.post_Data(this.Environment_Service.environment.identity_server + "/api/v1/group/", new_group);
  }
  deleteGroup(group: group) {
    return this.Response_Service.delete_Data(this.Environment_Service.environment.identity_server + "/api/v1/group/" + group.id);
  }
  getGroup_All(paged_url: string) {

    if (paged_url != null) {
      return this.Response_Service.getResponse(paged_url);
    }
    return this.Response_Service.getResponse(this.Environment_Service.environment.identity_server + "/api/v1/group/all");
  }
  getGroupByID(paged_url: string, group_id: number) {
    if (paged_url != null) {
      return this.Response_Service.getResponse_Paged(paged_url);
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.identity_server + "/api/v1/group/" + group_id);
  }
}
