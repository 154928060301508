export class environment {
  public production: boolean = false;
  public environment_name: string;
  public identity_scope: string;
  public identity_server: string;
  public identity_server_app_id: string;
  public identity_server_api_key: string;
  public identity_server_login_redirect: string;
  public identity_client_id: string;
  public identity_client_secret: string;
  public identity_realm: string;

  public agent_identity_server: string;
  
  public app_id: string;
  public api_key: string;

  public api_internal_sales: string;
  public api_internal_cache: string;
  public api_internal_maintenance: string;
  public tms_orig_baseurl: string;
  public api_internal_gateway: string;
  public microservice_app_id: string;
  public microservice_api_key: string;

  public app_build_version : string;
  public app_build_date: string;
  public app_build_description: string;
}
