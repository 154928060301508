import { Injectable } from '@angular/core';

import { Environment_Service } from "../../../../system/services/Environment_Service";
import { Response_Service } from "../../../../system/services/Response_Service";

import { password_hash_request } from "../../../../system/objects/password_hash_request";

@Injectable()
export class Cache_Server_Service {

  constructor(private Response_Service: Response_Service, private Environment_Service: Environment_Service) { }

  getCache_Server_List(page_number: number, page_size: number) {

    if (page_number != null && page_size != null) {
      return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/cache_server?page_number=" + page_number + "&page_size=" + page_size);
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/cache_server");
  }

  getCache_Servers(paged_url: string, Search: string) {
    if (paged_url != null) {
      return this.Response_Service.getResponse_Paged(paged_url);
    }

    var search_options: string[] = [];

    if (Search != "") {
      search_options.push("search=" + Search);
    }


    var search_option: string = "";

    for (var index = 0; index < search_options.length; index++) {
      search_option = search_option + ((index == 0) ? "?" : "&") + search_options[index];
    }
    return this.Response_Service.getResponse_Paged(this.Environment_Service.environment.api_internal_gateway + "/api/v1/web_search/cache_server" + search_option);
  }

  productSync(app_id: string) {
    return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/" + app_id + "/products", "");
  }

  capacitySync(app_id: string) {
    return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1/" + app_id + "/products/capacity", "");
  }

  getPasswordHash(){
    var hash_request: password_hash_request = new password_hash_request();
    hash_request.api_key = this.Environment_Service.environment.microservice_api_key;
    return this.Response_Service.post_Data(this.Environment_Service.environment.api_internal_gateway + "/api/v1.2/" + this.Environment_Service.environment.microservice_app_id + "/security/hmac/authentication_hash", hash_request);
  }

  singleProductSync(cache_server_app_id: string, product_id: string, pasword: string) {
      return this.Response_Service.post_Data_Secure(this.Environment_Service.environment.api_internal_gateway + "/api/v1.2/" + this.Environment_Service.environment.microservice_app_id + "/products/" + cache_server_app_id + "/" + product_id, "", pasword);
  }

  singleProductSyncV13(cache_server_app_id: string, product_id: string, pasword: string) {
    return this.Response_Service.post_Data_Secure(this.Environment_Service.environment.api_internal_gateway + "/api/v1.3/" + this.Environment_Service.environment.microservice_app_id + "/products/" + cache_server_app_id + "/" + product_id, "", pasword);
  }

  singleCapacitySync(cache_server_app_id: string, product_id: string, pasword: string) {
    return this.Response_Service.post_Data_Secure(this.Environment_Service.environment.api_internal_gateway + "/api/v1.2/" + this.Environment_Service.environment.microservice_app_id + "/products/" + cache_server_app_id + "/" + product_id + "/capacity", "", pasword);
  }

  singleCapacitySyncV13(cache_server_app_id: string, product_id: string, pasword: string) {
    return this.Response_Service.post_Data_Secure(this.Environment_Service.environment.api_internal_gateway + "/api/v1.3/" + this.Environment_Service.environment.microservice_app_id + "/products/" + cache_server_app_id + "/" + product_id + "/capacity", "", pasword);
  }

  singleAffiliateSync(cache_server_app_id: string, pasword: string) {
    return this.Response_Service.post_Data_Secure(this.Environment_Service.environment.api_internal_gateway + "/api/v1.2/" + this.Environment_Service.environment.microservice_app_id + "/affiliate/external_api_login_sync/" + cache_server_app_id , "", pasword);
  }

  singleAffiliateSyncV13(cache_server_app_id: string, pasword: string) {
    return this.Response_Service.post_Data_Secure(this.Environment_Service.environment.api_internal_gateway + "/api/v1.3/" + this.Environment_Service.environment.microservice_app_id + "/affiliate/external_api_login_sync/" + cache_server_app_id , "", pasword);
  }

}
