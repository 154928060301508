import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as _ from 'lodash';

import { pagination } from '../../../../../../system/objects/pagination';

import { Product_Selection_Service } from '../../../../services/Comman/Product_Selection/Product_Selection';
import { Pagination_Service } from '../../../../../../system/services/Pagination_Service';

export interface DialogData {
    title: string;
    text: string;
}

@Component({
    selector: 'select_product',
    templateUrl: 'select_product.html',
})
export class Select_Product_Dialog_Compnent {


    selected_product: any[] = [];

    filter: any[];
    conector_type_list: any[] = [];
    companies: any[] = [];
    finance_centres: any[] = [];
    product_groups: any[] = [];

    productSearch: string = "";
    displayedColumns: string[] = ['name', 'edit'];
    pagination_product: pagination = new pagination();
    pagedProductItems: any[] = [];

    page: number;
    pageSize: number;
    selected_connector: number = 0;
    selected_company: string;
    selected_finance_centre: string;
    selected_product_group: string;


    constructor(
        public dialogRef: MatDialogRef<Select_Product_Dialog_Compnent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private Product_Selection_Service: Product_Selection_Service) { }


    ngOnInit() {
        // Get the list of connector type from backend
        this.Product_Selection_Service.getConnector_Type_List(null).subscribe((res: any[]) => {
            this.conector_type_list = res;
        });
        this.getProduct_Selected_Data();
    }

    onConnectorFilter(item: any) {
        // Filter Connector Data for Dropdown
        var connectorData = this.filter.filter(o => o.Connector_ID == this.selected_connector);
        this.companies = _.uniqBy(connectorData, 'Company').map(m => { return m.Company });
        // rest selection
        this.page = null;
        this.pageSize = null;
        this.selected_company = null;
        this.selected_finance_centre = null;
        this.selected_product_group = null;
        this.productSearch = ""
        // refresh data
        this.getProduct_Selected_Data();
    }
    onCompanyFilter() {
        // Filter Connector Data for Dropdown
        var companyData = (this.filter.filter(o => o.Connector_ID == this.selected_connector)).filter(f => f.Company == this.selected_company);
        this.finance_centres = _.uniqBy(companyData, 'Company_Finance_Centre').map(m => { return m.Company_Finance_Centre });
        //rest selection
        this.page = null;
        this.pageSize = null;
        this.selected_finance_centre = null;
        this.selected_product_group = null;
        this.productSearch = ""
        // refresh data
        this.getProduct_Selected_Data();

    }
    onFinanceCentreFilter() {
        // Filter Connector Data for Dropdown
        var financeCentreData = ((this.filter.filter(o => o.Connector_ID == this.selected_connector)).filter(f => f.Company == this.selected_company)).filter(p => p.Company_Finance_Centre == this.selected_finance_centre);
        this.product_groups = _.uniqBy(financeCentreData, 'Company_Finance_Centre_Product_Group').map(m => { return m.Company_Finance_Centre_Product_Group });
        //rest selection
        this.page = null;
        this.pageSize = null;
        this.selected_product_group = null;
        this.productSearch = ""
        // refresh data
        this.getProduct_Selected_Data();

    }
    onProductGroupFilter() {
        //rest selection
        this.page = null;
        this.pageSize = null;
        this.productSearch = ""
        // refresh data
        this.getProduct_Selected_Data();
    }
    onClick_Product_Pagination(e: any) {
        this.page = e.pageIndex + 1;
        this.pageSize = e.pageSize;
        this.getProduct_Selected_Data();
    }
    onProduct_Search(value: string) {
        this.productSearch = value;
        this.page = null;
        this.pageSize = null;
        // refresh data
        this.getProduct_Selected_Data();

    }
    getProduct_Selected_Data() {
        // Get Contector Product Info from Backend
        this.Product_Selection_Service.getProduct_Selected_List(
            this.page,
            this.pageSize,
            this.selected_connector,
            this.selected_company,
            this.selected_finance_centre,
            this.selected_product_group,
            this.productSearch).subscribe((res) => {
                this.pagedProductItems = res.body['product_selected'];
                this.pagination_product = res.body['pagination'];
                this.filter = JSON.parse(res.body['filter']);
            });
    }
    onClose(): void {
        this.dialogRef.close();
    }

    onSingleSelect(item: any) {
        this.selected_product = [];
        this.selected_product.push(item.value);
        this.dialogRef.close(this.selected_product);
    }

}